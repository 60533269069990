import './videoViewer.scss';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { Image } from 'semantic-ui-react';

import { IRootState } from 'app/shared/reducers';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { item } = props?.location;
  const [videoId, setVideoId] = useState(item?.videoUrl ? item?.videoUrl : 'http://www.youtube.com/watch?v=9bZkp7q19f0')
  const videoList = [
    {
      'id': '1',
      'thumbnail': 'https://img.freepik.com/premium-photo/beautiful-moraine-lake-banff-national-park-alberta-canada_131985-98.jpg?w=2000',
      'videoUrl': 'https://www.youtube.com/watch?v=xcJtL7QggTI'
    },
    {
      'id': '2',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmZp9o7fR_TpJId889vTiPQnoq3G05mCc2qsVxNR0n4svO042INPZgLPViQCn40p8qC80&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=qMr4Dak_Btg'
    },
    {
      'id': '3',
      'thumbnail': 'https://c4.wallpaperflare.com/wallpaper/231/580/895/spring-4k-drops-butterfly-wallpaper-preview.jpg',
      'videoUrl': 'https://www.youtube.com/watch?v=bON-KPiiNCk'
    },
    {
      'id': '4',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWnkziIZuf1ZIS--WdDWNIq6VgXoY2kALgisfNiwQTOZmAZw-WvYuuR44hOPDG0A3VVLQ&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=EJr3uAQwGek'
    }, {
      'id': '5',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGju-7Vnnc-YdFZBS1JASdVBMXbd0woeSofbijpsOJ0jNVvyjT5oDv4CoHfPHT-hztvXU&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=GqamCmIrTHY'
    },
    {
      'id': '6',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRETDyOs2SWhUreqVBn1DQTTC2dg8oC8MM7GdsZXTNXe-5cdqzeVWrkXAegzsf56VRC4Tg&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=dG0j_W_ppvE'
    },
    {
      'id': '7',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFQv5Lc02ev4zXKmF_hoUXPRNsA-jb-mefEaWP2rMDIQUUF8pEN1p1eGIRvaRQvfooi3s&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=jcbGXMzOZzE'
    },
    {
      'id': '8',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSjnuLk05JHawaaICcpddlOVWpXYVSBWZSNtvW0u7USm271ngU2rDVobCvGAH0s6B_g6U&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=3kuLPz65uUU'
    },
    {
      'id': '9',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRVrUYipZwcfi2yZF7anjV3Ampvamr1aTIRlgEitRdwHB6S-3MIj8KHdKaJKfLbguvZ3A&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=62uLFAExx-k'
    },
    {
      'id': '10',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWnkziIZuf1ZIS--WdDWNIq6VgXoY2kALgisfNiwQTOZmAZw-WvYuuR44hOPDG0A3VVLQ&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=EJr3uAQwGek'
    }, {
      'id': '11',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGju-7Vnnc-YdFZBS1JASdVBMXbd0woeSofbijpsOJ0jNVvyjT5oDv4CoHfPHT-hztvXU&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=GqamCmIrTHY'
    },
    {
      'id': '12',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRETDyOs2SWhUreqVBn1DQTTC2dg8oC8MM7GdsZXTNXe-5cdqzeVWrkXAegzsf56VRC4Tg&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=dG0j_W_ppvE'
    }, {
      'id': '5',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGju-7Vnnc-YdFZBS1JASdVBMXbd0woeSofbijpsOJ0jNVvyjT5oDv4CoHfPHT-hztvXU&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=GqamCmIrTHY'
    },
    {
      'id': '13',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRETDyOs2SWhUreqVBn1DQTTC2dg8oC8MM7GdsZXTNXe-5cdqzeVWrkXAegzsf56VRC4Tg&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=dG0j_W_ppvE'
    },
    {
      'id': '14',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFQv5Lc02ev4zXKmF_hoUXPRNsA-jb-mefEaWP2rMDIQUUF8pEN1p1eGIRvaRQvfooi3s&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=jcbGXMzOZzE'
    },
    {
      'id': '15',
      'thumbnail': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSjnuLk05JHawaaICcpddlOVWpXYVSBWZSNtvW0u7USm271ngU2rDVobCvGAH0s6B_g6U&usqp=CAU',
      'videoUrl': 'https://www.youtube.com/watch?v=3kuLPz65uUU'
    }]
  const opts = {
    height: '800',
    width: '1500',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const selectVideoFun = (item) => {
    setVideoId(item.videoUrl)

  }

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
  return (
    <Row>
      <Col>
        <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
          <div style={{ width: '85%' }}>
            <YouTube opts={opts} videoId={getYouTubeID(videoId)} onReady={_onReady} />
            <div className="details">
              <div className="author">
                <img src="https://img.youtube.com/vi/zUwB_imVjmg/maxresdefault.jpg" alt="" />
              </div>
              <div className="title">
                <h3>
                  Introverts & Content Creation | Sumudu Siriwardana
                </h3>
                <a href="">
                  Francesco Ciulla
                </a>
                <span> 2M Views • 3 Months Ago </span>
              </div>
            </div>
          </div>
          <div>
            {videoList.map((item) => (
              <div className="video" style={{ 'cursor': 'pointer' }} onClick={() => selectVideoFun(item)} >
                <div className="thumbnail">
                  <img src={item.thumbnail} alt="" />
                </div>
                <div className="details">
                  <div className="author">
                    <img src="https://img.youtube.com/vi/zUwB_imVjmg/maxresdefault.jpg" alt="" />
                  </div>
                  <div className="title">
                    <h3>
                      Introverts & Content Creation | Sumudu Siriwardana
                    </h3>
                    <a href="">
                      Francesco Ciulla
                    </a>
                    <span> 2M Views • 3 Months Ago </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
