import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, NavLink, Row, Table, Col } from 'reactstrap';

import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import {
  getPlayListVideo,
  addVideoToVideoPlaylist,
  getExistingVideoIds,

} from './admin-playlist.reducer';
import {
  getEntities,
  searchVideoList,

} from './../video-meta-data/video-meta-data.reducer';
import { JhiItemCount, JhiPagination, TextFormat, getSortState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { values } from 'lodash';
export interface IAdminPlaylistVideoItemUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ type:string,pSearch:string,title:string,id: string,pPage:string,pOrder:string,pSort:string }> { }


const AdminPlaylistVideoItemUpdate = (props: IAdminPlaylistVideoItemUpdateProps) => {
  const { match, loading, totalItems, videoList, template, existingVideoIds,updatingSuccess,updating } = props;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [selectedVideosByPage, setSelectedVideosByPage] = useState<number[]>([]);
  const [existingVideoByPage, setExistingVideoByPage] = useState<number[]>([]);
  const [removedFromExisting, setRemovedFromExisting] = useState<number[]>([]);
  const [modifyingSrNo, setModifyingSrNo] = useState<string>("")
  const [searchText, setSearchText] = useState(null)
  const [show,setShow]=useState<boolean>(false)
  const [currentTimeout, setCurrentTimeout] = useState(null)
  const [isNewSearch, setIsNewSearch] = useState(false);

  useEffect(() => {
    props.getExistingVideoIds(props.match.params.id);
  }, [])
  useEffect(() => {
    setExistingVideoByPage(existingVideoIds);
  }, [existingVideoIds]);
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p.trim(),
    });
  };
  const getAllEntities = () => {
    if (!searchText) {
      props.getEntities(
        paginationState.activePage - 1,
        paginationState.itemsPerPage,
        `${isPaginationCorrect(paginationState.sort)?paginationState.sort:'serialNumber'}`,`${paginationState.order}`
      );
    } else {
      const params = {
        searchValue: searchText
      }
      props.searchVideoList(
        paginationState.activePage - 1,
        20,
        paginationState.sort,
        paginationState.order
        params)
    }
  };
const isPaginationCorrect=(sortColumn):Boolean=>{
if(sortColumn ==="serialNumber"||sortColumn ==="videoUrl"||sortColumn ==="videoTitle"||sortColumn ==="language"||sortColumn ==="statuses.statusValue"){
  return true
}

return false
}

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${isPaginationCorrect(paginationState.sort)?paginationState.sort:'serialNumber'},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
    isNewSearch && setIsNewSearch(false);
  };

  useEffect(() => {
    
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout)
    setCurrentTimeout(null)
    const timeout = setTimeout(() => {
      setShow(true)
    }, 150)
    setCurrentTimeout(timeout)
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);



  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    const handleSearchButton = () => {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      setIsNewSearch(true);
    };
  
    const handleSearch = e => {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(e.target.value);
        !isNewSearch && handleSearchButton();
      }
    };
    useEffect(() => {
      isNewSearch && paginationState.activePage === 1 && sortEntities();
    }, [isNewSearch]);


  const isVideoSelected = videoId => {
    return selectedVideosByPage.includes(videoId) || existingVideoByPage.includes(videoId);
  };

  const removeFromExisting = videoId => {
    if (existingVideoByPage.includes(videoId)) {
      const updatedExistingVideoId = existingVideoByPage.filter(id => id !== videoId)
      setExistingVideoByPage(updatedExistingVideoId)
      setRemovedFromExisting(prev => { return [...prev, videoId] })

    } else if (selectedVideosByPage.includes(videoId)) {
      const updateSelectedByPages = selectedVideosByPage.filter(id => id !== videoId)
      setSelectedVideosByPage(updateSelectedByPages)
    } else {
      return;
    }

  }
  const capitalizeFirstLetter = (str) => {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  };

  const handleCheckboxChange = event => {
    const videoId = parseInt(event.target.dataset.videoId);
    const checked = event.target.checked;

    !isVideoSelected(videoId) ? setSelectedVideosByPage(prevSelectedVideos => {
      let updatedSelectedVideos = [...prevSelectedVideos];

      if (checked) {
        if (!updatedSelectedVideos.includes(videoId)) {
          updatedSelectedVideos.push(videoId);
        }
      } else {
        updatedSelectedVideos = updatedSelectedVideos.filter(id => id !== videoId);
      }

      return updatedSelectedVideos;
    }) : removeFromExisting(videoId)
  };

  const handleSave = () => {
    const data = { contentIds: selectedVideosByPage, systemPlayList: { id: parseInt(props?.match?.params?.id) }, unselectContentIds: removedFromExisting };
    props.addVideo(
      data,
      0,
      paginationState.itemsPerPage,
      `${"playlist_serial_number"},${paginationState.order}`,
      props.match.params.id
    );
   
  };
  const handleClose = () => {
    props.history.push(`/admin-playlist/${match.params.type}/${match.params.pSearch}/${match.params.pPage}/${match.params.pSort}/${match.params.pOrder}/${match.params.id}/${match.params?.title}/playlistAssociations`);
  };
  useEffect(() => {
    if (updatingSuccess) {
      handleClose();
    }
  }, [updatingSuccess])
  return (
    <div className="main-container">
      <div className="page-title-btn-container">
        <Row className="justify-content-center">
          <Col md="12">
            <div className="detail-page-heading">
              <div className="detail-back-btn">
                <button 
                onClick={handleClose}
              
                >
                  <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
                </button>
                <h2 style={{ color: 'rgb(114 108 108)' }}>Manage Playlist</h2>
              </div>
            </div>
          </Col>
        </Row>
        <div>
        <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={handleSearchButton}>Search</button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink onClick={handleSave} disabled={updating}


            >

              <span className="header-title">Save</span>
            </NavLink>
          
          </div>
        </div>
      </div>
      <div className="table-responsive">
 {videoList && show&&       <div className="table-responsive">
          { videoList.length > 0 ? (
            <Table responsive style={{ marginTop: 10 }} id="userTableId">
              <thead>
                <tr>
                  <th className="hand">
                    Select
                    <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                  </th>
                  <th className="hand" onClick={sort('serialNumber')}>
                    Sr No
                    <span style={{ float: 'right' }}>
                      {' '}
                      <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th className="hand" onClick={sort('videoUrl')}>
                    Video Url
                    <span style={{ float: 'right' }}>
                      {' '}
                      <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th className="hand" onClick={sort('videoTitle')}>
                    Video Title
                    <span style={{ float: 'right' }}>
                      {' '}
                      <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                
                  <th className="hand" onClick={sort('language')}>
                    Language
                    <span style={{ float: 'right' }}>
                      {' '}
                      <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>

                
                  
                  <th className="hand" onClick={sort('statuses.statusValue')}>
                    Status
                    <span style={{ float: 'right' }}>
                      {' '}
                      <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                
                </tr>
              </thead>
          
                    <tbody>
              {videoList?.map((videoMetaData, i) => (
                <tr key={`entity-${i}`}>
                      <td>
                      {' '}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                        <input
                          type="checkbox"
                          className="videoCheckbox"
                          data-video-id={videoMetaData.id}
                          checked={isVideoSelected(videoMetaData.id)}
                          onChange={handleCheckboxChange}
                          style={{ width: '20px', height: '20px' }}
                        />

                      </div>
                    </td>
                  <td>{videoMetaData.serialNumber ? videoMetaData.serialNumber : '--'}</td>
                  <td style={{ wordBreak: 'break-word' }}>
                    <p className="textwrapafter3line">{videoMetaData.videoUrl ? videoMetaData.videoUrl : '--'}</p>
                  
                  </td>
                  <td>
                    {videoMetaData?.videoTitle ? videoMetaData.videoTitle : '--'}
                  </td>
 
                  <td>{videoMetaData?.language ? capitalizeFirstLetter(videoMetaData.language) : '--'}</td>
                 
                  
                  <td>{videoMetaData.statuses?.statusValue ? videoMetaData.statuses?.statusValue : '--'}</td>
                 
                  
                </tr>
              ))}
            </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No Video Meta Data found</div>
          )}
        </div>}{' '}
        {props.totalItems &&show? (
          <div className={`asso-table ${videoList && videoList.length > 0 ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ adminPlaylist, videoMetaData }: IRootState) => ({
  videoList: videoMetaData.entities,
  loading: videoMetaData.loading,
  totalItems: videoMetaData.totalItems,
  template: videoMetaData.template,
  existingVideoIds: adminPlaylist.existingIds,
  video: adminPlaylist,
  updatingSuccess:adminPlaylist.updateSuccess,
  updating:adminPlaylist.updating,
  loadingFile: videoMetaData.loadingFile,
});

const mapDispatchToProps = {
  getPlayListVideo,
  searchVideoList,
  getEntities,
  addVideo: addVideoToVideoPlaylist,
  getExistingVideoIds,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylistVideoItemUpdate);
