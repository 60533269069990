import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import liveVideo from './live-video';
import LiveVideoUpdate from './live-video-update';
import  LiveVideoQuestions  from './live-video-questions';
import  LiveVideoAttachAssociation  from './attach-association';
import  LiveVideoDetails  from './live-video-details';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LiveVideoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LiveVideoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/details`} component={LiveVideoDetails} />

      <ErrorBoundaryRoute exact path={`${match.url}/:id/questions`} component={LiveVideoQuestions} />
      <ErrorBoundaryRoute exact path={`${match.url}/:liveVideoId/uploadAttachments`} component={LiveVideoAttachAssociation} />
      <ErrorBoundaryRoute exact path={`${match.url}/:liveVideoId/:id/uploadAttachments`} component={LiveVideoAttachAssociation} />


      <ErrorBoundaryRoute path={match.url} component={liveVideo} />
    </Switch>
  </>
);

export default Routes;
