import { Moment } from 'moment';
import { IStatuses } from 'app/shared/model/statuses.model';
import { IUser } from './user.model';
import { IVideoMetaData } from './video-meta-data.model';

export interface ILiveVideo {
  id?: number;
  liveUrl?: string;
  title?: string;
  createdOn?: Date;
  status?: IStatuses;
  activated?: boolean;
  question?: IQuestion;
  attachmentUrl?: string;
  thumbnails?: string;
  contentType?: string;
}
export interface IQuestion {
  id?: number;
  admin?: boolean;
  question?: string;
  user?: IUser;
  transporter?: string;
  questionId?: number;
  comment?: string;
  liveVideoQuestions?: any;
  videoId?: number;
  createdon?: string;
  childComment?: boolean;
}
export interface IPreviousQuestion {
  id: number;
  question: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  user: IUser;
  transporter: string;
}
export const defaultValue: Readonly<ILiveVideo> = {};
