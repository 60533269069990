import React, { useEffect, useState } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import OtpInput from 'react-otp-input';
export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: Function;
  handleClose: Function;
}
const clientId = "130831750859-lbibg8vm8qg1dld5f78ml036inedcgim.apps.googleusercontent.com"
function LoginModal(props) {
  const [otp, setOtp] = useState('');

  const handleSubmit = () => {
    const { handleOtp } = props;
    handleOtp(otp);
  };

  return (
    <div className='row'>
      <div className='col-md-6 login-page'>
        <div style={{ 'textAlign': 'center', alignItems: 'center' }}>
          <img className='logo-responsive' src="content/images/shree_prem_acharyaji.png" alt="Logo" />
        </div>
      </div>
      {/* <div className='col-md-6' style={{backgroundColor:'#ffd54f',}}> */}
      <div className='col-md-6'>
        <div id="login-page" style={{ 'justifyContent': 'center', 'alignItems': 'center', 'display': 'flex', padding: 10 }} >
          <AvForm onSubmit={handleSubmit} className='login-form'>
            <ModalBody>
              <div style={{ textAlign: 'center' }} >
                {/* <img src="content/images/logo.png" alt="Logo" style={{ 'height': 150 }} /> */}
                <img src="content/images/satshrut3.png" alt="Logo" style={{ 'height': 150 }} />
                <h4 className='login-welcome-txt'>Welcome to Satshrut</h4>
              </div>
              <Row>
                <Col md="12">
                  {/* {loginError ? (
                      <Alert color="danger">
                        <strong>Failed to sign in!</strong> Please check your credentials and try again.
                      </Alert>
                    ) : null} */}
                </Col>
                <Col md="12">
                  <AvGroup className='input-box-width'>
                    <Label id="username" className='label-font'>
                      OTP
                    </Label>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={{ width: 60, height: 60 }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <AvGroup check inline>
                <button
                  type="submit"
                  className="common-btn"
                  style={{
                    width: 470,
                    marginTop: 20,
                  }} >
                  <span className='header-title'>Verify</span>
                </button>
              </AvGroup>
            </ModalBody>
          </AvForm>
        </div >
      </div>
    </div >
  );
}

export default LoginModal;
