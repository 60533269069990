//refactored
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';

import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import CreatableSelect from 'react-select/creatable';
import { uploadEntity, updateEntity, getEntity, reset, getSpeakerList } from './admin-associations-management.reducer';

import 'react-toastify/dist/ReactToastify.css';
import './admin-associations-management.scss';
import FormUpload from './admin-association-picture-upload';
import PDFDownload from './pdf-download';
import { toast } from 'react-toastify';
import ViewDocument from './ViewDocument';
import DatePicker, { DatePickerRef } from 'react-multi-date-picker';
import indian from 'react-date-object/calendars/indian';

import indian_hi from 'react-date-object/locales/indian_hi';
import indian_gu, { formatIndianDate } from 'app/shared/util/date-utils';

import { faCalendar } from '@fortawesome/free-solid-svg-icons';
export interface IAdminAssociationManagementUpdateProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string; association: string; url: string }> {}

export const AdminAssociationManagementUpdate = (props: IAdminAssociationManagementUpdateProps) => {
  const { associationEntity, statuses, loading, updating } = props;

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const [selectedContentType, setSelectedContentType] = useState(`${props.match.params.association}`);
  const [selectedContent, setSelectedContent] = useState(null);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [speakerList, setSpeakerList] = useState([]);
  const [speaker, setSpeaker] = useState(null);
  const [fileShowThumbnail, setFileShowThumbnail] = useState(null);
  const [fileShowImage, setFileShowImage] = useState(null);

  const [isPreviewThumbnail, setPreviewThumbnail] = useState(true);
  const [isPreviewImage, setPreviewImage] = useState(true);
  const [acceptedFormat, setAcceptedFormat] = useState('');
  const [durationTotalMin, setDurationTotalMin] = useState<Number>(null);
  const [pageCount, setPageCount] = useState<Number>(null);
  const [isEditDurationDisabled, setIsEditDurationDisabled] = useState<boolean>(true);
  const [isEditTotalPageDisabled, setIsEditTotalPageDisabled] = useState<boolean>(true);
  const [selectedIndianDate, setSelectedIndianDate] = useState<string>(null);
  const [showIndianCalender, setshowIndianCalender] = useState(false);
  const [renderDetailsArray, setRenderDetailsArray] = useState<string[]>([]);
  const datePickerRef = useRef<DatePickerRef>();
  const keyTitles = {
    id: 'ID',
    thumbnailImageUrl: 'Thumbnail Image Url',
    imageUrl: 'Image Url',

    language: 'Language',
    speaker: 'Speaker',

    satsangDate: 'Satsang Date',
    publishDate: 'Publish Date',
    indianDate: 'Publish Date (Indian)',
    patraNo: 'Patra Number',
    contentUrl: 'Content Url',
    contentUrlApple: 'Apple Url',
    contentUrlSpotify: 'Spotify Url',
    writtenTo: 'Written To',
    author: 'Author',
    yearOfPatra: 'Year of Patra',
    publisher: 'Publisher',
    comments: 'Comments',
    satsangTopic: 'Satsang Topic',
    version: 'Version',
    durationTotalMin: 'Duration',
    keywords: 'Keywords',
    pageCount: 'Page Count',
    title: 'Title',
  };
  useLayoutEffect(() => {
    if (!selectedContentType) return;
    switch (selectedContentType) {
      case 'audio':
        setRenderDetailsArray([
          'contentType',
          'title',
          'durationTotalMin',
          'keywords',
          'contentUrlApple',
          'contentUrlSpotify',
          // 'language',
          // 'author',
          'publishDate',
          'indianDate',
        ]);
        break;
      case 'pad':
        setRenderDetailsArray([
          'contentType',
          'title',
          'durationTotalMin',
          'keywords',
          'contentUrlApple',
          'contentUrlSpotify',
          'writtenTo',
          'author',
          'language',
          'publishDate',
          'indianDate',
        ]);
        break;
      case 'patra':
        setRenderDetailsArray([
          'contentType',
          'title',
          'pageCount',
          'keywords',
          'writtenTo',
          'author',
          'yearOfPatra',
          'patraNo',
          'language',
          'publishDate',
          'indianDate',
          'content',
        ]);
        break;
      case 'ebook':
        setRenderDetailsArray([
          'contentType',
          'title',
          'pageCount',
          'keywords',
          'author',
          'version',
          'publisher',
          'comments',
          'language',
          'publishDate',

          'content',
        ]);
        break;
      case 'transcript':
        setRenderDetailsArray([
          'contentType',
          'title',
          'pageCount',
          'keywords',
          'topic',
          'speaker',
          'patraNo',
          'language',
          'satsangDate',

          'content',
        ]);
        break;
      default:
        break;
    }
  }, [selectedContentType]);

  useLayoutEffect(() => {
    const getAvailableSpeakerList = async () => {
      const data: string[] = await getSpeakerList();
      console.log(data);
      setSpeakerList(
        data.map((speaker, index) => {
          return { value: index, label: speaker };
        })
      );
    };

    if (selectedContentType == 'transcript') {
      getAvailableSpeakerList();
    }
  }, [selectedContentType]);
  useEffect(() => {
    const speakerFunction = () => {
      if (associationEntity && associationEntity.speaker) {
        setSpeaker(speakerList.filter(speaker => speaker.label == associationEntity.speaker)[0]);
      } else setSpeaker(null);
    };

    if (selectedContentType == 'transcript') {
      speakerFunction();
    }
  }, [speakerList, associationEntity, isNew]);
  const handleClose = () => {
    const state = { showTab: props.match.params.association };
    props.history.push('/admin-association-management' + props.location.search, state);
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setSelectedContent(selectedFile);
  };

  const handleFormatChange = event => {
    const selectedFormat = event.target.value;
    setSelectedContentType(selectedFormat);
  };

  useEffect(() => {
    if (selectedContentType === 'ebook' || selectedContentType === 'patra' || selectedContentType === 'transcript') {
      setAcceptedFormat('.pdf,.epub');
    } else if (selectedContentType === 'audio' || selectedContentType === 'pad') {
      setAcceptedFormat('.mp3');
    }
  }, [selectedContentType]);

  useEffect(() => {
    if (isNew) {
      props.reset();
      // setSelectedIndianDate(indianDateFormat.format(new Date()));
    } else {
      props.getEntity(props.match.params.id, props.match.params.association);
    }
  }, []);
  const handleUploadedFiles = (fileName, file) => {
    if (fileName === 'thumbnail') {
      setPreviewThumbnail(true);
      setThumbnail(file);
    } else if (fileName === 'bookImage') {
      setPreviewImage(true);
      setImage(file);
    }
  };
  const showUploadedFiles = (fileName, file) => {
    if (fileName === 'thumbnail') {
      setFileShowThumbnail(file);
    } else if (fileName === 'bookImage') {
      setFileShowImage(file);
    }
  };
  const convertBase64 = (file, name) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
        showUploadedFiles(name, reader.result);
        if (name === 'thumbnail') {
          setThumbnailError(false);
        } else {
          setImageError(false);
        }
      };

      reader.onerror = error => {
        reject(error);
        if (name === 'thumbnail') {
          setThumbnailError(true);
        } else {
          setImageError(true);
        }
      };

      reader.readAsDataURL(file);
    });
  };
  const uploadFile = (file, name) => {
    convertBase64(file, name)
      .then(base64File => {
        if (name === 'thumbnail') {
          setFileShowThumbnail(base64File);
        } else if (name === 'bookImage') {
          setFileShowImage(base64File);
        }
      })
      .catch(error => {
        console.error('Error converting file to base64:', error);
        if (name === 'thumbnail') {
          setThumbnailError(true);
        } else if (name === 'bookImage') {
          setImageError(true);
        }
      });
  };
  const isImageSizeAppropriate = img => {
    let maxWidth;
    let maxHeight;
    if (selectedContentType === 'ebook' || selectedContentType === 'patra' || selectedContentType === 'transcript') {
      maxWidth = 720;
      maxHeight = 1280;
    } else {
      maxWidth = 1280;
      maxHeight = 720;
    }

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(img);

      image.onload = () => {
        console.log('height: ', image.height, 'width: ', image.width);
        if (image.width == maxWidth && image.height == maxHeight) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      image.onerror = () => {
        reject('Failed to load image.');
      };
    });
  };

  const uploadSingleFile = async e => {
    setImageError(false);
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      const name = e.target.name;

      try {
        const isSizeAppropriate = await isImageSizeAppropriate(file);

        if (isSizeAppropriate) {
          handleUploadedFiles(name, file);
          uploadFile(file, name);
        } else {
          setThumbnailError(true);
          if (selectedContentType === 'ebook' || selectedContentType === 'patra' || selectedContentType === 'transcript') {
            toast.error('Please select an image file with appropriate dimensions (720x1280).');
          } else {
            toast.error('Please select an image file with appropriate dimensions (1280x720).');
          }
        }
      } catch (error) {
        // Handle any errors that occur while loading the image
        console.error(error);
      }
    }
  };

  const saveEntity = async (event, errors, values) => {
    // console.log(speaker);
    // let array ;
    // if(selectedContentType==='transcript'){
    //   array={
    //     ...values,
    //     statuses: {
    //       id: values.statusesId,
    //     },
    //     speaker:speaker.label,
    //   };

    // }else{
    //   array={
    //     ...values,
    //     statuses: {
    //       id: values.statusesId,
    //     },
    //   };
    // }
    if (errors.length === 0) {
      // const entity = {
      //   ...associationEntity,
      //   ...array,
      // };

      const formData = new FormData();

      // formData.append('title', values.title);
      // formData.append('keywords', values.keywords);
      // formData.append('publishDate', values.publishDate);
      // formData.append('indianDate', values.indianDate);
      Object.keys(values).forEach(key => {
        if (key === 'file') return;
        values[key] != null && formData.append(key, values[key]);
      });
      if (selectedContentType == 'transcript' && speaker) {
        console.log(speaker)
        formData.append('speaker', speaker.label);
      }

      if (selectedContent || associationEntity.contentUrl) {
        selectedContent && formData.append('content', selectedContent);
        if (selectedContentType == 'ebook' || selectedContentType == 'patra' || selectedContentType == 'transcript') {
          formData.append('pageCount', isNew ? 0 : values.pageCount);
        } else {
          formData.append('durationTotalMin', isNew ? 0 : values.durationTotalMin);
        }
      }

      if (!isNew) {
        formData.append('id', `${props.match.params.id}`);
      }
      if (isNew && !thumbnail && (selectedContentType == 'audio' || selectedContentType == 'pad')) {
        if (!thumbnail) {
          setThumbnailError(true);
          toast.error('Please upload a thumbnail', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        return;
      } else {
        thumbnail && formData.append('thumbnail', thumbnail);
      }

      if (isNew) {
        const data = await props.uploadEntity(formData, selectedContentType);
        if (data) {
          handleClose();
        }
      } else {
        const data = await props.updateEntity(formData, selectedContentType);
        if (data) {
          handleClose();
        }
      }
    }
  };
  const handleChangeSpeaker = (field, value) => {
    switch (field) {
      case 'options':
        setSpeaker(value);
        break;
      default:
        break;
    }
  };

  const indianDateFormat = new Intl.DateTimeFormat('hi-IN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    calendar: 'indian',
  });

  const handleShowIndianCalender = () => {
    setshowIndianCalender(prev => !prev);

    datePickerRef.current.openCalendar();
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleClose}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>{isNew ? 'Add' : 'Edit'} Associations</h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="row main-container">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : associationEntity} onSubmit={saveEntity}>
              <div className="row">
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="select" for="select" className="label-font">
                      Content type
                    </Label>
                    <AvField
                      disabled={true}
                      type="select"
                      name="format"
                      className="input-font"
                      value={selectedContentType}
                      onChange={handleFormatChange}
                      validate={{ required: { value: true, errorMessage: 'Please select a content format' } }}
                    >
                      <option value="">Select a content</option>
                      <option value="pad">Pad</option>
                      <option value="patra">Patra</option>
                      <option value="ebook">Ebook</option>
                      <option value="audio">Audio</option>
                      <option value="transcript">Transcript</option>
                    </AvField>
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="keywords" for="keywords" className="label-font">
                      Keywords*
                    </Label>
                    <AvField
                      id="keywords"
                      type="text"
                      name="keywords"
                      className="input-font"
                      validate={{
                        required: { value: true, errorMessage: ' Keywords are required.' },
                        pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                      }}
                    />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="title" for="title" className="label-font">
                      Title*
                    </Label>
                    <AvField
                      id="title"
                      type="text"
                      name="title"
                      className="input-font"
                      validate={{
                        required: { value: true, errorMessage: 'Title is required.' },
                        minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                        pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                      }}
                    />
                    {renderDetailsArray.includes('language') && (
                      <AvGroup className="input-box-width">
                        <Label id="language" for="language" className="label-font">
                          Language
                        </Label>
                        <AvField id="language" type="text" name="language" className="input-font" />
                      </AvGroup>
                    )}
                  </AvGroup>
                  {renderDetailsArray.includes('writtenTo') && (
                    <AvGroup className="input-box-width">
                      <Label id="writtenTo" for="writtenTo" className="label-font">
                        Written To
                      </Label>
                      <AvField
                        id="writtenTo"
                        type="text"
                        name="writtenTo"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                  )}
                  {(selectedContentType == 'audio' || selectedContentType == 'pad') && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div className="image-container">
                        <FormUpload
                          label={fileShowThumbnail && isNew ? 'Change Thumbnail' : isNew ? 'Upload  Thumbnail*' : 'Change Thumbnail'}
                          isPreview={false}
                          error={thumbnailError}
                          inlineLabel={false}
                          name={'thumbnail'}
                          value={null}
                          style={{ height: 150 }}
                          uploadSingleFile={uploadSingleFile}
                        />
                        {fileShowThumbnail ? (
                          <FormUpload
                            isPreview={isPreviewThumbnail}
                            error={thumbnailError}
                            inlineLabel={false}
                            value={fileShowThumbnail}
                            style={{ height: 150 }}
                            uploadSingleFile={uploadSingleFile}
                            name={'thumbnail'}
                            label={'Update thumbnail'}
                          />
                        ) : (
                          <>{!isNew && <img src={associationEntity.thumbnailImageUrl} style={{ height: 150, marginTop: 20 }}></img>}</>
                        )}
                      </div>
                    </div>
                  )}

                  {renderDetailsArray.includes('yearOfPatra') && (
                    <AvGroup className="input-box-width">
                      <Label id="yearOfPatra" for="yearOfPatra" className="label-font">
                        Year of Patra
                      </Label>
                      <AvField
                        id="yearOfPatra"
                        type="text"
                        name="yearOfPatra"
                        className="input-font"
                        validate={
                          {
                            // required: { value: true, errorMessage: 'Title is required.' },
                            // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                            // pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                          }
                        }
                      />
                    </AvGroup>
                  )}

                  {renderDetailsArray.includes('comments') && (
                    <AvGroup className="input-box-width">
                      <Label id="comments" for="comments" className="label-font">
                        Comments
                      </Label>
                      <AvField
                        id="comments"
                        type="textarea"
                        name="comments"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                  )}
                  {renderDetailsArray.includes('publisher') && (
                    <AvGroup className="input-box-width">
                      <Label id="publisher" for="publisher" className="label-font">
                        Publisher
                      </Label>
                      <AvField
                        id="publisher"
                        type="text"
                        name="publisher"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                  )}
                </div>
                <div className="col-md-4">
                  {renderDetailsArray.includes('speaker') && (
                    <AvGroup className="input-box-width">
                      <Label id="speaker" for="speaker" className="label-font">
                        Speaker
                      </Label>
                      {/* <AvField
                        id="speaker"
                        type="text"
                        name="speaker"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      /> */}
                      <CreatableSelect
                        id="speaker"
                        name="speaker"
                        options={speakerList}
                        value={speaker}
                        className="input-font"
                        onChange={value => handleChangeSpeaker('options', value)}
                        // isMulti={false}
                      />
                    </AvGroup>
                  )}
                  {renderDetailsArray.includes('topic') && (
                    <AvGroup className="input-box-width">
                      <Label id="topic" for="topic" className="label-font">
                        Topic
                      </Label>
                      <AvField
                        id="topic"
                        type="text"
                        name="topic"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                  )}

                  {renderDetailsArray.includes('author') && (
                    <AvGroup className="input-box-width">
                      <Label id="author" for="author" className="label-font">
                        Author
                      </Label>
                      <AvField
                        id="author"
                        type="text"
                        name="author"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                  )}

                  {renderDetailsArray.includes('pageCount') ? (
                    !isNew && (
                      <AvGroup className="input-box-width">
                        <Label id="  Page Count" for="  Page Count" className="label-font">
                          Page Count
                        </Label>
                        <div className="indian-date">
                          <AvField
                            id="pageCount"
                            type="number"
                            name="pageCount"
                            value={pageCount}
                            className="input-font"
                            disabled={isEditTotalPageDisabled}
                          />
                          {(associationEntity.contentUrl || selectedContent) && (
                            <FontAwesomeIcon
                              className="show-indianCal-btn"
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: '20px',
                                color: '#275cb0',
                              }}
                              onClick={() => {
                                setIsEditTotalPageDisabled(prev => !prev);
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          )}
                        </div>
                      </AvGroup>
                    )
                  ) : (
                    <AvGroup className="input-box-width">
                      <Label id="Duration in minutes" for="Duration in minutes" className="label-font">
                        Duration in minutes
                      </Label>
                      {/* style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} */}
                      <div className="indian-date">
                        <AvField
                          id="durationTotalMin"
                          type="number"
                          name="durationTotalMin"
                          value={durationTotalMin}
                          className="input-font"
                          disabled={isEditDurationDisabled}
                        />
                        {
                          <FontAwesomeIcon
                            className="show-indianCal-btn"
                            icon="pencil-alt"
                            style={{
                              height: ' 23px',
                              width: '18px',
                              marginRight: '20px',
                              color: '#275cb0',
                            }}
                            onClick={() => {
                              setIsEditDurationDisabled(prev => !prev);
                            }}
                            data-toggle="tooltip"
                            title="Edit"
                          />
                        }
                      </div>
                    </AvGroup>
                  )}
                  {renderDetailsArray.includes('contentUrlApple') && (
                    <AvGroup className="input-box-width">
                      <Label id=" Apple content Url" for="Apple content Url" className="label-font">
                        Apple Music Link
                      </Label>
                      <AvField
                        id="contentUrlApple"
                        type="text"
                        name="contentUrlApple"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: {
                            value:
                              '/(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]+.[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})/',
                            errorMessage: 'Please enter valid link (e.g https://)',
                          },
                        }}
                      />
                    </AvGroup>
                  )}

                  {renderDetailsArray.includes('contentUrlSpotify') && (
                    <AvGroup className="input-box-width">
                      <Label id="Spottify content Url" for="Spottify content Url" className="label-font">
                        Spottify Music Link
                      </Label>
                      <AvField
                        id="contentUrlSpotify"
                        type="text"
                        name="contentUrlSpotify"
                        className="input-font"
                        validate={{
                          // required: { value: true, errorMessage: 'Title is required.' },
                          // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                          pattern: {
                            value:
                              '/(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]+.[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})/',
                            errorMessage: 'Please enter valid link (e.g https://)',
                          },
                        }}
                      />
                    </AvGroup>
                  )}

                  {renderDetailsArray.includes('version') && (
                    <AvGroup className="input-box-width">
                      <Label id="version" for="version" className="label-font">
                        Version
                      </Label>
                      <AvField
                        id="version"
                        type="text"
                        name="version"
                        className="input-font"
                        validate={
                          {
                            // required: { value: true, errorMessage: 'Title is required.' },
                            // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                            // pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                          }
                        }
                      />
                    </AvGroup>
                  )}

                  {renderDetailsArray.includes('publishDate') && (
                    <AvGroup className="input-box-width">
                      <Label id="publishDate" for="publishDate" className="label-font">
                        Publish Date
                      </Label>
                      <AvField
                        id="publishDate"
                        type="date"
                        name="publishDate"
                        className="input-font"
                        // validate={{
                        //   required: { value: true, errorMessage: 'Publish Date is required.' },
                        // }}
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </AvGroup>
                  )}

                  {/* {renderDetailsArray.includes('indianDate') && (
                    <AvGroup className="input-box-width">
                      <Label id="indianDate" for="indianDate" className="label-font">
                        Publish Date (Indian)
                      </Label>
                      <div className="indian-date">
                        <AvField
                          onChange={e => {
                            setSelectedIndianDate(e.target.value);
                          }}
                          value={selectedIndianDate}
                          id="indianDate"
                          name="indianDate"
                          className="input-font"
                        />

                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{
                            height: ' 23px',
                            width: '18px',
                          }}
                          className="show-indianCal-btn"
                          onClick={handleShowIndianCalender}
                          // onClick={() => document.getElementById('datepicker').click()}
                          data-toggle="tooltip"
                          title="Edit"
                        />
                        <DatePicker
                          ref={datePickerRef}
                          style={{ display: 'none', position: 'absolute', left: 0, top: 0, bottom: 0 }}
                          id="datepicker"
                          onChange={date => {
                            setSelectedIndianDate(indianDateFormat.format(date).toString());
                          }}
                          format="DD/MM/YYYY"
                          calendar={indian}
                          locale={indian_gu}
                          maxDate={new Date()}
                        />
                      </div>
                    </AvGroup>
                  )} */}

                  {renderDetailsArray.includes('satsangDate') && (
                    <AvGroup className="input-box-width">
                      <Label id="satsangDate" for="satsangDate" className="label-font">
                        Satsang Date
                      </Label>
                      <AvField
                        id="satsangDate"
                        type="date"
                        name="satsangDate"
                        className="input-font"
                        // validate={{
                        //   required: { value: true, errorMessage: 'Publish Date is required.' },
                        // }}
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </AvGroup>
                  )}
                  {renderDetailsArray.includes('patraNo') && (
                    <AvGroup className="input-box-width">
                      <Label id="patraNo" for="patraNo" className="label-font">
                        Patra Number
                      </Label>
                      <AvField
                        id="patraNo"
                        type="text"
                        name="patraNo"
                        className="input-font"
                        validate={
                          {
                            // required: { value: true, errorMessage: 'Title is required.' },
                            // minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                            // pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                          }
                        }
                      />
                    </AvGroup>
                  )}
                  {renderDetailsArray.includes('content') && (
                    <AvGroup className="input-box-width upload-component ">
                      <Label id="title" for="title" className="label-font">
                        {isNew ? 'Select the content*' : 'Change the content'}
                      </Label>
                      <AvField
                        type="file"
                        name="file"
                        accept={acceptedFormat}
                        onChange={handleFileChange}
                        className="input-font "
                        validate={{ required: { value: isNew, errorMessage: 'Please select a file' } }}
                      />
                    </AvGroup>
                  )}
                </div>
                <div className="col-md-4">
                  {associationEntity.contentUrl && (
                    <div className="input-box-width">
                      <Label id="indianDate" for="indianDate" className="label-font">
                        Download
                      </Label>
                      <PDFDownload filePath={associationEntity.contentUrl} />
                    </div>
                  )}
                  {(associationEntity.contentUrl || selectedContent) && (
                    <div className="input-box-width">
                      <Label id="indianDate" for="indianDate" className="label-font">
                        View
                      </Label>
                      <ViewDocument
                        content={{
                          type: selectedContent
                            ? selectedContent.name.slice(selectedContent.name.lastIndexOf('.') + 1)
                            : associationEntity.contentUrl.slice(associationEntity.contentUrl.lastIndexOf('.') + 1),
                          url: selectedContent ? '' : associationEntity.contentUrl,
                          file: selectedContent ? selectedContent : null,
                          setDuration: setDurationTotalMin,
                          setPageCount: setPageCount,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                disabled={updating}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{
                  width: 200,
                  marginTop: 20,
                }}
              >
                <span className="header-title">Save</span>
              </button>
            </AvForm>
          )}
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  // subscriptionPlans: storeState.subscriptionPlan.entities,
  statuses: storeState.statuses.entities,
  associationEntity: storeState.adminAssociationManagement.entity,
  loading: storeState.adminAssociationManagement.loading,
  updating: storeState.adminAssociationManagement.updating,
  updateSuccess: storeState.adminAssociationManagement.updateSuccess,
});

const mapDispatchToProps = {
  getStatuses,
  uploadEntity,
  updateEntity,
  getEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminAssociationManagementUpdate);
