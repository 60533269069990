//refactored
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';

import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import 'react-toastify/dist/ReactToastify.css';
import './live-video.scss';

import { toast } from 'react-toastify';
import { reset,saveAttachment,updateAttacments,getLiveVidAssociation } from './live-video.reducer';
import PDFDownload from '../admin-associations-Management/pdf-download';
import ViewDocument from '../admin-associations-Management/ViewDocument';

export interface ILiveVideoAttachAssociationProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id?: string; association: string; url: string,liveVideoId:string }> {}

export const LiveVideoAttachAssociation = (props: ILiveVideoAttachAssociationProps) => {
  const { associationEntity, loading, updating } = props;

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const [selectedContentType, setSelectedContentType] = useState(`${props.match.params.association}`);
  const [selectedContent, setSelectedContent] = useState(null);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [thumbnailError, setThumbnailError] = useState(false);

  const [acceptedFormat, setAcceptedFormat] = useState('');
  const [durationTotalMin, setDurationTotalMin] = useState<Number>(null);
  const [pageCount, setPageCount] = useState<Number>(null);



  const handleClose = () => {
    const state = { showTab: props.match.params.association };
    props.history.push(`/live-video/${props.match.params.liveVideoId}/details` + props.location.search, state);
  };
  const handleGoBack = () => {
    const state = { showTab: props.match.params.association };
    props.history.push(`/live-video` + props.location.search, state);
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setSelectedContent(selectedFile);
  };

  const handleFormatChange = event => {
    const selectedFormat = event.target.value;
    setSelectedContentType(selectedFormat);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
     
    } else {
         props.getLiveVidAssociation(props.match.params.id);
    }
  }, []);


  useEffect(() => {
    if (selectedContentType === 'ebook' || selectedContentType === 'patra' || selectedContentType === 'transcript') {
      setAcceptedFormat('.pdf,.epub');
    } else if (selectedContentType === 'audio' || selectedContentType === 'pad') {
      setAcceptedFormat('.mp3');
    }
  }, [selectedContentType]);
  useEffect(() => {
    if(isNew)return;
    if (associationEntity.contentType === 'ebook' || associationEntity.contentType === 'patra' || associationEntity.contentType === 'transcript') {
      setAcceptedFormat('.pdf,.epub');
    } else if (associationEntity.contentType === 'audio' || associationEntity.contentType === 'pad') {
      setAcceptedFormat('.mp3');
    }
  }, [associationEntity]);

  const saveEntity = async (event, errors, values) => {
    console.log(values);
    const array = {
      ...values,
      statuses: {
        id: values.statusesId,
      },
    };
    if (errors.length === 0) {
      const entity = {
        ...associationEntity,
        ...array,
      };

      const formData = new FormData();

      Object.keys(values).forEach(key => {
        if (key === 'file') return;
        values[key] != null && formData.append(key, values[key]);
      });
      if (selectedContent || associationEntity.attachmentUrl) {
        selectedContent && formData.append('content', selectedContent);
       
      }

      
      if (!isNew) {
        formData.append('id', `${props.match.params.id}`);
      }else{
        formData.append('youtubeLiveVideo', `${props.match.params.liveVideoId}`);
      }
      

        if (isNew) {
          const data = await props.saveAttachment(formData);
          if (data) {
            handleClose();
          }
        } else {
          const data = await props.updateAttacments(formData);
          if (data) {
            handleClose();
          }
        }
    }
  };



  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleGoBack}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>{isNew ? 'Add' : 'Edit'} Attachments</h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="row main-container">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : associationEntity} onSubmit={saveEntity}>
              <div className="row">
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="contentType" for="contentType" className="label-font">
                      Content type
                    </Label>
                    <AvField
                      id="contentType"
                      disabled={!isNew}
                      type="select"
                      name="contentType"
                      className="input-font"
                      value={isNew&&selectedContentType}
                      onChange={handleFormatChange}
                      validate={{ required: { value: true, errorMessage: 'Please select a content format' } }}
                    >
                      <option value="">Select a content</option>
                      {/* <option value="pad">Pad</option> */}
                      <option value="patra">Patra</option>
                      <option value="ebook">Ebook</option>
                      {/* <option value="audio">Audio</option> */}
                      <option value="transcript">Transcript</option>
                    </AvField>
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="title" for="title" className="label-font">
                      Title*
                    </Label>
                    <AvField
                      id="title"
                      type="text"
                      name="title"
                      className="input-font"
                      validate={{
                        required: { value: true, errorMessage: 'Title is required.' },
                        minLength: { value: 4, errorMessage: 'Title is required to be at least 4 characters.' },
                        pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                      }}
                    />
                  </AvGroup>
                  <AvGroup className="input-box-width upload-component ">
                    <Label id="file" for="file" className="label-font">
                      {isNew ? 'Select the content*' : 'Change the content'}
                    </Label>
                    <AvField
                      type="file"
                      // id="content"
                      name="file"
                      accept={acceptedFormat}
                      onChange={handleFileChange}
                      className="input-font "
                      validate={{ required: { value: isNew, errorMessage: 'Please select a file' } }}
                    />
                  </AvGroup>
                </div>

                <div className="col-md-4">
                  {associationEntity.attachmentUrl && (
                    <div className="input-box-width">
                      <Label id="indianDate" for="indianDate" className="label-font">
                        Download
                      </Label>
                      <PDFDownload filePath={associationEntity.attachmentUrl} />
                    </div>
                  )}
                  {(associationEntity.attachmentUrl || selectedContent) && (
                    <div className="input-box-width">
                      <Label id="indianDate" for="indianDate" className="label-font">
                        View
                      </Label>
                      <ViewDocument
                        content={{
                          type: selectedContent
                            ? selectedContent.name.slice(selectedContent.name.lastIndexOf('.') + 1)
                            : associationEntity.attachmentUrl.slice(associationEntity.attachmentUrl.lastIndexOf('.') + 1),
                          url: selectedContent ? '' : associationEntity.attachmentUrl,
                          file: selectedContent ? selectedContent : null,
                          setDuration: setDurationTotalMin,
                          setPageCount: setPageCount,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                disabled={updating}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{
                  width: 200,
                  marginTop: 20,
                }}
              >
                <span className="header-title">Save</span>
              </button>
            </AvForm>
          )}
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  associationEntity: storeState.liveVideoList.entity,
  loading: storeState.liveVideoList.loading,
  updating: storeState.liveVideoList.updating,
  updateSuccess: storeState.liveVideoList.updateSuccess,
});

const mapDispatchToProps = {
  getStatuses,
  saveAttachment,
  updateAttacments,
  reset,
  getLiveVidAssociation
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideoAttachAssociation);
