import { Moment } from 'moment';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { IStatuses } from 'app/shared/model/statuses.model';

export interface IVideoMetaData {
  isAudioActivated: any;
  subscriptionName?: string;
  videoRecordDate?: string;
  id?: number;
  videoUrl?: string;
  updatedDate?: string;
  serialNumber?: string;
  publishDate?: string;
  keywords?: [];
  videotype?: string;
  subscriptionPlan?: ISubscriptionPlan;
  statuses?: IStatuses;
  privacyStatus?: string;
  language?: string;
  playlistName?: string;
  contentIds?: number[];
  systemPlayList?: { id: number };
  unselectContentIds?: number[];
  thumbnail?: boolean;
  noOfVideos?: number;
  noOfaudios?: number;
  noOfEbooks?: number;
  noOfPads?: number;
  noOfPatras?: number;
  noOfTranscript?: number;
  playListSerialNumber?: number;
  title?: string;
  status?: string;
  thumbnailImageUrl?: string;
  bookImage?: string;
  contentUrl?: string;
  videoTitle?: string;
  serialNo?: string;
  contentId?: string;
  videoType?: string;
  location?: string;
  audience?: string;
  category?: string;
  videoDescription?: string;
  videoId?: string;
  channelName?: string;
  speaker?: string;
  thumbnailBlobName?: string;
  imageBlobName?: string;
  contentUrlBlobName?: string;
  crowed?: boolean;
  satsangDate?: string;

  indianDate?: string;
  patraNo?: number;

  contentUrlApple?: string;
  contentUrlSpotify?: string;
  writtenTo?: string;
  author?: string;
  yearOfPatra?: string;
  publisher?: string;
  comments?: string;
  satsangTopic?: string;
  version?: string;
  durationTotalMin?: string;

  pageCount?: string;
  audioUrl?:string;
}

export const defaultValue: Readonly<IVideoMetaData> = {};
