import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { IStatuses } from 'app/shared/model/statuses.model';

export interface IAdminPlaylist {
  writtenBy?: string;
  createdDate?: string;
  id?: number;
  videoUrl?: string;
  updatedDate?: string;
  serialNumber?: string;
  publishDate?: string;
  keywords?: [];
  videotype?: string;
  subscriptionPlan?: ISubscriptionPlan;
  statuses?: IStatuses;
  privacyStatus?: string;
  playlistName?: string;
  contentIds?: number[];
  systemPlayList?: { id: number };
  unselectContentIds?: number[];
  thumbnail?: any;
  noOfVideos?: number;
  noOfaudios?: number;
  noOfEbooks?: number;
  noOfPads?: number;
  noOfPatras?: number;
  noOfTranscript?: number;
  playListSerialNumber?: number;
  status?: string;
  title?: string;
  thumbnailImageUrl?: string;
  contentUrl?: string;
  contentId?: number;
  videoTitle?: string;
  language?: string;
  startDate?: string;
  endDate?: string;
  speaker?: string;
  description?: string;
  location?: string;
  recommendedBy?: string;
  thumbnailBlobName?: string;
  imageBlobName?: string;
  contentUrlBlobName?: string;

  satsangDate?: string;

  indianDate?: string;
  patraNo?: number;

  contentUrlApple?: string;
  contentUrlSpotify?: string;
  writtenTo?: string;
  author?: string;
  yearOfPatra?: string;
  publisher?: string;
  comments?: string;
  satsangTopic?: string;
  version?: string;
  durationTotalMin?: string;

  pageCount?: string;
}

export const defaultValue: Readonly<IAdminPlaylist> = {};
