import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IYoutubeLiveVideo {
  id?: number;
  title?: string;
  liveUrl?: string;
  status?: string;
  createdOn?: string;
  user?: IUser;
}

export const defaultValue: Readonly<IYoutubeLiveVideo> = {};
