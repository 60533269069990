import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './admin-audit-history.reducer';
import { IAdminAuditHistory } from 'app/shared/model/admin-audit-history.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAdminAuditHistoryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AdminAuditHistoryDetail = (props: IAdminAuditHistoryDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { adminAuditHistoryEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          AdminAuditHistory [<b>{adminAuditHistoryEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="newJson">New Json</span>
          </dt>
          <dd>{adminAuditHistoryEntity.newJson}</dd>
          <dt>
            <span id="oldJson">Old Json</span>
          </dt>
          <dd>{adminAuditHistoryEntity.oldJson}</dd>
          <dt>
            <span id="type">Type</span>
          </dt>
          <dd>{adminAuditHistoryEntity.type}</dd>
          <dt>
            <span id="subscriptionHistoryId">Subscription History Id</span>
          </dt>
          <dd>{adminAuditHistoryEntity.subscriptionHistoryId}</dd>
          <dt>
            <span id="subscriptionPlanId">Subscription Plan Id</span>
          </dt>
          <dd>{adminAuditHistoryEntity.subscriptionPlanId}</dd>
          <dt>
            <span id="updatedBy">Updated By</span>
          </dt>
          <dd>{adminAuditHistoryEntity.updatedBy}</dd>
          <dt>
            <span id="updatedOn">Updated On</span>
          </dt>
          <dd>
            {adminAuditHistoryEntity.updatedOn ? (
              <TextFormat value={adminAuditHistoryEntity.updatedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/admin-audit-history" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/admin-audit-history/${adminAuditHistoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ adminAuditHistory }: IRootState) => ({
  adminAuditHistoryEntity: adminAuditHistory.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminAuditHistoryDetail);
