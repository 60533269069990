import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './file-history.reducer';
import { IFileHistory } from 'app/shared/model/file-history.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IFileHistoryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const FileHistoryUpdate = (props: IFileHistoryUpdateProps) => {
  const [uploadedById, setUploadedById] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { fileHistoryEntity, users, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/file-history');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...fileHistoryEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="satshrutApp.fileHistory.home.createOrEditLabel">Create or edit a FileHistory</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : fileHistoryEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="file-history-id">ID</Label>
                  <AvInput id="file-history-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="jhiKeyLabel" for="file-history-jhiKey">
                  Jhi Key
                </Label>
                <AvField id="file-history-jhiKey" type="text" name="jhiKey" />
              </AvGroup>
              <AvGroup>
                <Label id="fileNameLabel" for="file-history-fileName">
                  File Name
                </Label>
                <AvField id="file-history-fileName" type="text" name="fileName" />
              </AvGroup>
              <AvGroup>
                <Label id="sizeLabel" for="file-history-size">
                  Size
                </Label>
                <AvField id="file-history-size" type="string" className="form-control" name="size" />
              </AvGroup>
              <AvGroup>
                <Label id="numberOfRecordsLabel" for="file-history-numberOfRecords">
                  Number Of Records
                </Label>
                <AvField id="file-history-numberOfRecords" type="string" className="form-control" name="numberOfRecords" />
              </AvGroup>
              <AvGroup>
                <Label id="failedCountLabel" for="file-history-failedCount">
                  Failed Count
                </Label>
                <AvField id="file-history-failedCount" type="string" className="form-control" name="failedCount" />
              </AvGroup>
              <AvGroup>
                <Label id="succeedCountLabel" for="file-history-succeedCount">
                  Succeed Count
                </Label>
                <AvField id="file-history-succeedCount" type="string" className="form-control" name="succeedCount" />
              </AvGroup>
              <AvGroup>
                <Label id="timeTakenLabel" for="file-history-timeTaken">
                  Time Taken
                </Label>
                <AvField id="file-history-timeTaken" type="string" className="form-control" name="timeTaken" />
              </AvGroup>
              <AvGroup>
                <Label id="failedMessageJsonLabel" for="file-history-failedMessageJson">
                  Failed Message Json
                </Label>
                <AvField id="file-history-failedMessageJson" type="text" name="failedMessageJson" />
              </AvGroup>
              <AvGroup>
                <Label id="succeedMessageJsonLabel" for="file-history-succeedMessageJson">
                  Succeed Message Json
                </Label>
                <AvField id="file-history-succeedMessageJson" type="text" name="succeedMessageJson" />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="file-history-status">
                  Status
                </Label>
                <AvField id="file-history-status" type="text" name="status" />
              </AvGroup>
              <AvGroup>
                <Label id="uploadedOnLabel" for="file-history-uploadedOn">
                  Uploaded On
                </Label>
                <AvField id="file-history-uploadedOn" type="date" className="form-control" name="uploadedOn" />
              </AvGroup>
              <AvGroup>
                <Label id="executionStartTimeLabel" for="file-history-executionStartTime">
                  Execution Start Time
                </Label>
                <AvField id="file-history-executionStartTime" type="string" className="form-control" name="executionStartTime" />
              </AvGroup>
              <AvGroup>
                <Label id="executionEndTimeLabel" for="file-history-executionEndTime">
                  Execution End Time
                </Label>
                <AvField id="file-history-executionEndTime" type="string" className="form-control" name="executionEndTime" />
              </AvGroup>
              <AvGroup>
                <Label for="file-history-uploadedBy">Uploaded By</Label>
                <AvInput id="file-history-uploadedBy" type="select" className="form-control" name="uploadedBy.id">
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.login}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/file-history" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  fileHistoryEntity: storeState.fileHistory.entity,
  loading: storeState.fileHistory.loading,
  updating: storeState.fileHistory.updating,
  updateSuccess: storeState.fileHistory.updateSuccess,
});

const mapDispatchToProps = {
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileHistoryUpdate);
