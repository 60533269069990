import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import './live-video.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import SocketComponent from 'app/config/SocketComponent';
import Satshrut from '../../../content/images/jhipster_family_member_0_head-256.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Row, Table, Col } from 'reactstrap';
import { getAllQuestion,reset, editMessage, deleteMessage, getComments } from './live-video.reducer';
import { faCommentDots, faPencilAlt, faReply, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IQuestion } from 'app/shared/model/live-video.model';

export interface ILiveVideoProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; title: string; id: string }> {}
interface Question {
  messageData?: IQuestion;
  isQuestion: boolean;
  handleReply?: () => void;
  handleDelete?: () => void;
  handleEdit?: () => void;
  isNewReply?: boolean;
  isEdit?: boolean;
  isDelete?: boolean;
}

const Message = ({ isQuestion, messageData, handleReply, handleEdit, handleDelete, isNewReply }: Question) => (
  <div className="message">
    <div className="user-info">
      <img src={messageData?.user?.imageUrl ? messageData?.user?.imageUrl : Satshrut} alt={'profile'} />
      <span>{`${messageData.user.firstName}${' '}${messageData.user.lastName}`}</span>
      <span className="questions-createdon">{`${' '}${messageData?.createdon}`}</span>
    </div>
    <div className="message-text">
      <div>{isQuestion ? messageData.question : messageData.comment}</div>
      <div>
        <span className="messsage-reply">
          {isQuestion && (
            <>
              <FontAwesomeIcon onClick={() => handleReply()} icon={faReply} style={{ height: 15, width: 25 }} />
              {messageData.childComment && (
                <span onClick={() => handleReply()} className={`more ${isNewReply ? `newReply` : null}`}>
                  ...View Replies
                </span>
              )}
            </>
          )}
          { (
            <FontAwesomeIcon onClick={() => handleDelete()} icon={faTrash} style={{ color: 'red', height: 15, width: 25 }} />
          )}
          {messageData.admin && <FontAwesomeIcon onClick={() => handleEdit()} icon={faPencilAlt} style={{ height: 15, width: 25 }} />}
        </span>
      </div>
    </div>
  </div>
);

export const LiveVideoQuestions = (props: ILiveVideoProps) => {
  const [reload, setReload] = useState(false);
  const scrollContainerRef = useRef(null);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [comments, setComments] = useState<IQuestion[]>([]);
  const [hasMoreQuestions, sethasMoreQuestions] = useState(true);
  const [hasMoreComments, setHasMoreComments] = useState(true);

  const [newMessage, setNewMessage] = useState('');
  const [sendMessage, setSendMessage] = useState('');
  const [replyData, setReplyData] = useState<IQuestion>(null);
  const [questionPageNo, setQuestionPageNo] = useState<number>(0);
  const [commentsPageNo, setCommentsPageNo] = useState<number>(0);
  const [isReplyingToAQuestion, setIsReplyingToAQuestion] = useState<boolean>(false);
  const [replyToQuestion, setReplyToQuestion] = useState<IQuestion>(null);
  const [newReplyArray, setNewReplyArray] = useState([]);
  const [editItem, setEditItem] = useState<IQuestion>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<IQuestion>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  useEffect(() => {
    props.getAllQuestion({
      pageNo: 0,
      pageSize: 10,
      videoid: props.match.params.id,
    });
  }, []);
  const fetchQuestions = (page: number) => {
    props.getAllQuestion({
      pageNo: page,
      pageSize: 10,
      videoid: props.match.params.id, // You can change this to use props.match.params.id if needed
    });
  };
  const fetchComments = (page: number) => {
    props.getComments({
      pageNo: page,
      pageSize: 10,
      questionid: replyToQuestion.id,
    });
  };

  const fetchMoreData = () => {
    if (hasMoreQuestions) {
      if (replyToQuestion) {
        const nextPage = commentsPageNo + 1;
        fetchComments(nextPage);
        setCommentsPageNo(nextPage);
      } else {
        const nextPage = questionPageNo + 1;
        fetchQuestions(nextPage);
        setQuestionPageNo(nextPage);
      }
    }
  };
  useEffect(() => {
    if (!isReplyingToAQuestion) {
      setQuestions([...questions, ...props?.commentList.filter(item => !questions.some(qItem => qItem.id === item.id))]);
      // setNewReplyArray(prev => [
      //   ...prev,
      //   ...props.commentList.map(item => {
      //     if (item.childComment) {
      //       return item.questionId;
      //     }
      //   }),
      // ]);
    } else {
      setComments([...comments, ...props?.commentList.filter(item => !comments.some(cItem => cItem.id === item.id))]);
    }
    if (props?.commentList.length < 10) {
     isReplyingToAQuestion?setHasMoreComments(false): sethasMoreQuestions(false);
    } else {
      isReplyingToAQuestion?setHasMoreComments(true): sethasMoreQuestions(true)
    }
  }, [props?.commentList]);

  const handleClose = () => {
    props.reset()
    props.history.push(`/live-video`+props.location.search);
   
  };
  const handleReply = (item: Question) => {};
  const handleSocketConnect = () => {
    console.log('Socket connected!');
  };

  const handleSocketDisconnect = () => {
    console.log('Socket disconnected!');
  };

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setSendMessage(newMessage);
      setNewMessage('');

      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };
  const handleKeyDownSendMessage = e => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };
  const handleKeyDownSaveEdit = e => {
    if (e.key === 'Enter') {
      handleSaveEdit();
    }
  };

  useEffect(() => {
    if (replyData) {
      console.log(replyData, replyToQuestion);

      if (replyData.question) {
        setQuestions([replyData, ...questions]);
      } else {
        const isReply = replyData.questionId === replyToQuestion?.questionId;

        if (isReply) {
          setComments([replyData, ...comments]);
        } else {
          const questionIndex = questions.findIndex(item => item.questionId === replyData.questionId);

          if (questionIndex !== -1) {
            const updatedQuestion = { ...questions[questionIndex] };

            if (!updatedQuestion.childComment) {
              updatedQuestion.childComment = true;
              questions.splice(questionIndex, 1, updatedQuestion);
            }

            if (!newReplyArray.includes(questions[questionIndex].questionId)) {
              setNewReplyArray(prev => [...prev, questions[questionIndex].questionId]);
            }
          }
        }
      }

      setReplyData(null);
    }
  }, [replyData]);

  const handleOpenComments = (question: IQuestion) => {
    handleCancelDelete()
    handleCancelEdit()
    setHasMoreComments(true);
    setQuestions([])
    setQuestionPageNo(0)
    setReplyToQuestion(question);
    setIsReplyingToAQuestion(true);
    setNewReplyArray(prev => [...prev.filter(item => item != question.questionId)]);
  };
  const handleCloseComment = () => {
    sethasMoreQuestions(true);
    setCommentsPageNo(0);
    setReplyToQuestion(null);
    setComments([]);
    setIsReplyingToAQuestion(false);
  };
  useEffect(() => {
    isReplyingToAQuestion ? fetchComments(0):fetchQuestions(0);
  }, [isReplyingToAQuestion]);
  const handleInputChange = e => {
    const inputValue = e.target.value;
    setNewMessage(inputValue);
  };
  const handleEdit = (item: IQuestion) => {
    handleCancelDelete()
    setEditItem(item);
    setNewMessage(item.comment ? item.comment : item.question);
    setIsEdit(true);
  };
  const handleSaveEdit =async () => {
    let result;
    if (editItem?.comment ) {
      const params = {
        id: editItem.id,
        comment:newMessage
      };
       result = await props.editMessage(params, 'comment');
     
      if (result==200) {
        const index = comments.findIndex(item => item.id == editItem.id);
        comments.splice(index, 1, { ...comments[index], comment: newMessage });
        handleCancelEdit();
      }
    } else if (editItem?.question ) {
      const params = {
        questionId: editItem.questionId,
        question:newMessage
      };
       result = await props.editMessage(params, 'question');
       console.log(result)
      if (result==200) {
        const index = questions.findIndex(item => item.questionId == editItem.questionId);
        questions.splice(index, 1, { ...questions[index], question: newMessage });

        handleCancelEdit();
      }

    }
 
  };
  const handleCancelEdit = () => {
    setIsEdit(false);
    setNewMessage('');
    setEditItem(null);
  };
  const handleDelete = (item: IQuestion) => {
    handleCancelEdit()
    setDeleteItem(item);
    setIsDelete(true);
  };
  const handleCancelDelete = () => {
    setIsDelete(false);
    setDeleteItem(null);
  };
  const handleConfirmDelete = async() => {
    let result;
    if (deleteItem?.comment) {
      const params = {
        id: deleteItem.id,
      };
       result = await props.deleteMessage(params, 'comment');
      if (result==200) {
        const index = comments.findIndex(item => item.id == deleteItem.id);
        comments.splice(index, 1);
        handleCancelDelete();
      }
    }  else if (deleteItem?.question) {
      const params = {
        questionId: deleteItem.questionId,
      };
       result = await props.deleteMessage(params, 'question');
      if (result==200) {
        const index = questions.findIndex(item => item.questionId == deleteItem.questionId);
        questions.splice(index, 1);
        handleCancelDelete();
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={() => handleClose()}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Questions </h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="chat-app">
        <InfiniteScroll
          dataLength={!isReplyingToAQuestion ? questions.length : comments.length}
          next={fetchMoreData}
          hasMore={isReplyingToAQuestion?hasMoreComments:hasMoreQuestions}
          loader={null}
        >
          <div ref={scrollContainerRef}></div>
          {!isReplyingToAQuestion ? (
            <div className="messages">
              {questions.map((data, index) => (
                <>
                  <Message
                    isNewReply={newReplyArray.includes(data.questionId)}
                    handleReply={() => handleOpenComments(data)}
                    handleDelete={() => handleDelete(data)}
                    handleEdit={() => handleEdit(data)}
                    key={index}
                    isQuestion={true}
                    messageData={data}
                    isEdit={isEdit}
                    isDelete={isDelete}
                  />
                  {isDelete && deleteItem?.questionId == data?.questionId && (
                    <div className="delete-confirm-popup">
                      {' '}
                      <p>
                        Are you sure you want to delete this reply ?{' '}
                        <span className="delete-message-button">
                          <button onClick={handleConfirmDelete}>Yes</button>
                          <button onClick={handleCancelDelete} className="cancel-button">
                            Cancel
                          </button>
                        </span>
                      </p>
                    </div>
                  )}
                </>
              ))}
              {hasMoreQuestions && (
                <div onClick={fetchMoreData} className="fetch-more-messages">
                  <span>More</span>
                </div>
              )}
            </div>
          ) : (
            <div className="messages">
              <div className="detail-page-heading">
                <div style={{ padding: '5px' }} className="detail-back-btn">
                  <button onClick={() => handleCloseComment()}>
                    <FontAwesomeIcon icon="arrow-left" style={{ height: 20, width: 20 }} />
                  </button>
                  <h1 style={{ fontSize: '20px', marginLeft: '10px', color: 'rgb(114 108 108)' }}>Back </h1>
                </div>
              </div>

              {comments.map((data, index) => (
                <>
                  <Message
                    key={index}
                    handleDelete={() => handleDelete(data)}
                    handleReply={() => handleOpenComments(data)}
                    handleEdit={() => handleEdit(data)}
                    isQuestion={false}
                    messageData={data}
                    isEdit={isEdit}
                    isDelete={isDelete}
                  />
                  {isDelete && deleteItem?.id == data?.id && (
                    <div className="delete-confirm-popup">
                      {' '}
                      <p>
                        Are you sure you want to delete this reply ?{' '}
                        <span className="delete-message-button">
                          <button onClick={handleConfirmDelete}>Yes</button>
                          <button onClick={handleCancelDelete} className="cancel-button">
                            Cancel
                          </button>
                        </span>
                      </p>
                    </div>
                  )}
                </>
              ))}
              {hasMoreComments && (
                <div onClick={fetchMoreData} className="fetch-more-messages">
                  <span>More</span>
                </div>
              )}
            </div>
          )}
        </InfiniteScroll>
        <div className="message-input">
          <textarea rows={3} value={newMessage} onChange={handleInputChange} placeholder="Type your message..." onKeyDown={isEdit?handleKeyDownSaveEdit:handleKeyDownSendMessage} />
          {isEdit ? (
            <div className="edit-message">
              <button disabled={newMessage==''} style={newMessage==''?{backgroundColor:"	#D3D3D3"}:null} className="save-button" onClick={handleSaveEdit}>
                Save
              </button>
              <button className="cancel-button" onClick={handleCancelEdit}>
                Cancel
              </button>
            </div>
          ) : (
            <button onClick={handleSendMessage}>Send</button>
          )}
        </div>
      </div>
      <SocketComponent
        setMessage={setSendMessage}
        questionId={replyToQuestion ? replyToQuestion.id : null}
        youtubeLiveVideoId={props.match.params.id}
        setReplyData={setReplyData}
        message={sendMessage}
        onConnect={handleSocketConnect}
        onDisconnect={handleSocketDisconnect}
      />
    </div>
  );
};

const mapStateToProps = ({ liveVideoList }: IRootState) => ({
  commentList: liveVideoList.questions,
  loading: liveVideoList.loading,
  totalItems: liveVideoList.totalItems,
});

const mapDispatchToProps = {
  getAllQuestion,
  getComments,
  editMessage,
  deleteMessage,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideoQuestions);
