import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AdminPlaylist from './admin-playlist';
import AdminPlaylistDetails from './admin-playlist-detail';
import AdminPlaylistUpdate from './admin-playlist-update';
import AdminPlaylistEbookItemUpdate from './admin-playlist-ebookList-update';
import AdminPlaylistVideoItemUpdate from './admin-playlist-videoList-update';
import AdminPlaylistDeleteDialog from './admin-playlist-delete-dialog';
import  AdminPlaylistAssociations  from './admin-playlist-associations';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:type/new`} component={AdminPlaylistUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:type/:id/edit`} component={AdminPlaylistUpdate} />
     
      <ErrorBoundaryRoute exact path={`${match.url}/:type/:pSearch/:pPage/:pSort/:pOrder/:id/:title/playlistAssociations/videoPlaylist/add`} component={AdminPlaylistVideoItemUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:type/:pSearch/:pPage/:pSort/:pOrder/:id/:title/playlistAssociations/ebookPlaylist/:contentType/add`} component={AdminPlaylistEbookItemUpdate} />


      <ErrorBoundaryRoute exact path={`${match.url}/:type/:id`} component={AdminPlaylistDetails} />
   
      <ErrorBoundaryRoute exact path={`${match.url}/:type/:pSearch/:pPage/:pSort/:pOrder/:id/:title/playlistAssociations`} component={AdminPlaylistAssociations} />
      


      <ErrorBoundaryRoute path={match.url} component={AdminPlaylist} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:type/:id/delete`} component={AdminPlaylistDeleteDialog} />
  </>
);

export default Routes;
