import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';

import { NavLink, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { updateIncident, getEntity, reset, saveIncident } from './crowd-sourcing.reducer';

import './crowd-sourcing.scss';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
export interface ICrowdSourcingUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CrowdSorcingUpdate = (props: ICrowdSourcingUpdateProps) => {
  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const { loading, updating, updateSuccess, incident } = props;
  const [videoUrl, setVideoUrl] = useState(null);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [editVideoTitle, setEditVideoTitle] = useState('');
  const [editStatus, setEditSatus] = useState(null);
  const handleChangeVideoUrl = event => {
    setVideoUrl(event.target.value);
  };

  const handleClose = () => {
    props.reset();
    props.history.push('/crowd-sourcing' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, [props.match.params.id]);

  const handleSave = async (event, errors, values) => {
    let result;
    try {
      const param = { ...values };

      if (isNew) {
        result = await props.saveIncident(param);
      } else {
        result = await props.updateIncident(param);
      }
    } catch (error) {
      console.log(error);
    }
    if (result) {
      handleClose();
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="back-arrow" style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
            <NavLink
              // tag={Link} to={`/live-video`+props.location.search}
              onClick={handleClose}
            >
              <FontAwesomeIcon icon="arrow-left" style={{ width: 25, height: 25 }} />
            </NavLink>
            <h2 id="satshrutApp.liveVideo.home.createOrEditLabel" style={{ color: 'rgb(114 108 108)' }}>
              {' '}
              {isNew ? 'Create' : 'Edit'} Live Video
            </h2>
          </div>
        </Col>
      </Row>
      {!props.loading && (
        <Row className="justify-content-center main-container">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : incident} onSubmit={handleSave}>
                <div className="row">
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label for="incidentType" className="label-font">
                        Incident Type
                      </Label>
                      <AvInput id="incidentType" type="select" className="form-control input-font" name="incidentType" required>
                        <option value="" key="0" label="Select" />

                        <option value={'SHABDARTH'} key={'0'}>
                          {'SHABDARTH'}
                        </option>
                        <option value={'PURUSHARTH'} key={'1'}>
                          {'PURUSHARTH'}
                        </option>
                        <option value={'SHANKA'} key={'3'}>
                          {'SHANKA'}
                        </option>
                      </AvInput>
                      <AvFeedback> Incident type is required.</AvFeedback>
                    </AvGroup>

                    <AvGroup className="input-box-width">
                      <Label id="incidentWord" for="incidentWord" className="label-font">
                        Incident Description
                      </Label>
                      <AvField
                        id="incidentWord"
                        type="textarea"
                        cols="45"
                        rows="4"
                        name="incidentWord"
                        className="input-font"
                        validate={{
                          required: { value: true, errorMessage: ' Description is required.' },
                          minLength: { value: 4, errorMessage: ' Description is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                    <AvGroup className="input-box-width">
                      <Label id="startTimeFrame" for="startTimeFrame" className="label-font">
                        Incident Time Stamp (Starts at)
                      </Label>
                      <AvField
                        id="startTimeFrame"
                        type="text"
                        placeholder={'00:00:00'}
                        name="startTimeFrame"
                        className="input-font"
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' },
                          pattern: {
                            value: '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$',
                            errorMessage: 'Please enter a valid time in the format 00:00:00',
                          },
                        }}
                      />
                    </AvGroup>

                    <AvGroup className="input-box-width">
                      <Label id="endTimeFrame" for="endTimeFrame" className="label-font">
                        Incident Time Stamp (Ends at)
                      </Label>
                      <AvField
                        id="endTimeFrame"
                        placeholder={'00:00:00'}
                        type="text"
                        name="endTimeFrame"
                        className="input-font"
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' },
                          pattern: {
                            value: '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$',
                            errorMessage: 'Please enter a valid time in the format 00:00:00',
                          },
                        }}
                      />
                    </AvGroup>

                    <button
                      disabled={updating}
                      id="save-entity"
                      type="submit"
                      className="common-btn"
                      style={{
                        width: 200,
                        marginTop: 20,
                      }}
                    >
                      <span className="header-title">Save</span>
                    </button>
                  </div>
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="contentId" for="contentId" className="label-font">
                        Video Id
                      </Label>
                      <AvField
                        id="contentId"
                        type="text"
                        disabled={!isNew}
                        name="contentId"
                        className="input-font"
                        validate={{
                          required: { value: true, errorMessage: ' This field is required.' },

                          pattern: { value: '^[0-9]+$', errorMessage: 'Please enter valid input with numbers only' },
                        }}
                      />
                    </AvGroup>
                    <AvGroup className="input-box-width">
                      <Label id="videoTitle" for="videoTitle" className="label-font">
                        Video Title
                      </Label>
                      <AvField
                        id="videoTitle"
                        type="text"
                        name="videoTitle"
                        className="input-font"
                        validate={{
                          required: { value: true, errorMessage: ' This field is required.' },

                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                    <AvGroup className="input-box-width">
                      <Label id="videoUrl" for="videoUrl" className="label-font">
                        Video Url
                      </Label>
                      <AvField
                        id="videoUrl"
                        type="text"
                        name="videoUrl"
                        placeholder={'https://'}
                        className="input-font"
                        validate={{
                          required: { value: true, errorMessage: ' This field is required.' },

                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                    {!isNew && (
                      <AvGroup className="input-box-width">
                        <Label for="status" className="label-font">
                          Incident Status
                        </Label>
                        <AvInput id="status" type="select" className="form-control input-font" name="status" required>
                          <option value="" key="0" label="Select" />

                          <option value={'REPORTED'} key={'0'}>
                            {'REPORTED'}
                          </option>
                          <option value={'UNDER_REVIEW'} key={'1'}>
                            {'UNDER_REVIEW'}
                          </option>
                          <option value={'REVIEW_COMPLETED'} key={'3'}>
                            {'REVIEW_COMPLETED'}
                          </option>
                        </AvInput>
                        <AvFeedback> Incident type is required.</AvFeedback>
                      </AvGroup>
                    )}
                    {!isNew && (
                      <AvGroup className="input-box-width">
                        <Label id="createdDate" for="" className="label-font">
                          Created Date*
                        </Label>
                        <AvField
                          id="createdDate"
                          disabled={true}
                          type="text"
                          name="createdDate"
                          className="input-font"
                          // validate={{
                          //   required: { value: true, errorMessage: 'Publish Date is required.' },
                          // }}
                        />
                      </AvGroup>
                    )}
                  </div>
                  {!isNew && (
                    <div className="col-md-4">
                      <AvGroup className="input-box-width">
                        <Label id="id" for="id" className="label-font">
                          Incident Id
                        </Label>
                        <AvField id="id" type="text" name="id" disabled={true} className="input-font" />
                      </AvGroup>
                      <AvGroup className="input-box-width">
                        <Label id="createdBy" for="createdBy" className="label-font">
                          Incident Created By
                        </Label>
                        <AvField id="createdBy" type="text" name="createdBy" disabled={true} className="input-font" />
                      </AvGroup>
                      <AvGroup className="input-box-width">
                        <Label id="reportedBy.id" for="reportedBy.id" className="label-font">
                          Incident Created By Id
                        </Label>
                        <AvField id="reportedBy.id" type="text" name="reportedBy.id" disabled={true} className="input-font" />
                      </AvGroup>
                    </div>
                  )}
                </div>
              </AvForm>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  incident: storeState.crowdSourcingState.entity,

  loading: storeState.crowdSourcingState.loading,
  updating: storeState.crowdSourcingState.updating,
  updateSuccess: storeState.crowdSourcingState.updateSuccess,
});

const mapDispatchToProps = {
  updateIncident,
  reset,
  getEntity,
  saveIncident,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CrowdSorcingUpdate);
