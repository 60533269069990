import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { lastIndexOf } from 'lodash';

const getFileExtension = (filename:string) => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};
interface props {
  filePath:string
}
const PDFDownload = ({ filePath }:props) => {

  const handleDownload = () => {
    window.open(filePath, '_blank');
  };
  // Extract the name from the file path
  const fileName = filePath.substring(filePath.lastIndexOf('_') + 1);

  return (
    <div className="pdf-download">
      {filePath && (
        <div className="pdf-download-container">
          <div className="pdf-download-label">{fileName}</div>
          <button className="pdf-download-button" type="button" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} className="pdf-download-icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default PDFDownload;
