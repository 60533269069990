import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Statuses from './statuses';
import SubscriptionPlan from './subscription-plan';
import VideoMetaData from './video-meta-data';
import SubscriptionHistory from './subscription-history';
import VideoViewHistory from './video-view-history';
import MyFavourite from './my-favourite';
import AuditHistory from './audit-history';
import ApplicationConfiguration from './application-configuration';
import VideoMetaDataKeywords from './video-meta-data-keywords';
import FileHistory from './file-history';
import GloballyFreeSubscriptionPlan from './globally-free-subscription-plan';
import AdminAuditHistory from './admin-audit-history';
import SubscriptionPlanSpecification from './subscription-plan-specification';
import LiveVideo from './live-video';
import { Reports } from './reports/reports';
import AdminPlaylist from './admin-playlist';
import  AdminPlaylistAssociationsManagement  from './admin-associations-Management';
import  CrowdSourcing  from './crowd-sourcing';
import Sparsh from './sparsh';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}statuses`} component={Statuses} />
      <ErrorBoundaryRoute path={`${match.url}live-video`} component={LiveVideo} />
      <ErrorBoundaryRoute path={`${match.url}subscription-plan`} component={SubscriptionPlan} />
      <ErrorBoundaryRoute path={`${match.url}video-meta-data`} component={VideoMetaData} />
      <ErrorBoundaryRoute path={`${match.url}subscription-history`} component={SubscriptionHistory} />
      <ErrorBoundaryRoute path={`${match.url}video-view-history`} component={VideoViewHistory} />
      <ErrorBoundaryRoute path={`${match.url}my-favourite`} component={MyFavourite} />
      <ErrorBoundaryRoute path={`${match.url}audit-history`} component={AuditHistory} />
      <ErrorBoundaryRoute path={`${match.url}application-configuration`} component={ApplicationConfiguration} />
      <ErrorBoundaryRoute path={`${match.url}video-meta-data-keywords`} component={VideoMetaDataKeywords} />
      <ErrorBoundaryRoute path={`${match.url}file-history`} component={FileHistory} />
      <ErrorBoundaryRoute path={`${match.url}reports`} component={Reports} />
      <ErrorBoundaryRoute path={`${match.url}globally-free-subscription-plan`} component={GloballyFreeSubscriptionPlan} />
      <ErrorBoundaryRoute path={`${match.url}admin-audit-history`} component={AdminAuditHistory} />
      <ErrorBoundaryRoute path={`${match.url}subscription-plan-specification`} component={SubscriptionPlanSpecification} />
      <ErrorBoundaryRoute path={`${match.url}admin-playlist`} component={AdminPlaylist} />
      <ErrorBoundaryRoute path={`${match.url}admin-association-management`} component={AdminPlaylistAssociationsManagement} />
      <ErrorBoundaryRoute path={`${match.url}crowd-sourcing`} component={CrowdSourcing} />
      <ErrorBoundaryRoute path={`${match.url}sparsh`} component={Sparsh} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
