import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VideoViewHistory from './video-view-history';
import VideoViewHistoryDetail from './video-view-history-detail';
import VideoViewHistoryUpdate from './video-view-history-update';
import VideoViewHistoryDeleteDialog from './video-view-history-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VideoViewHistoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VideoViewHistoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={VideoViewHistoryDetail} />
      <ErrorBoundaryRoute path={match.url} component={VideoViewHistory} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={VideoViewHistoryDeleteDialog} />
  </>
);

export default Routes;
