import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import statuses, {
  StatusesState
} from 'app/entities/statuses/statuses.reducer';
// prettier-ignore
import subscriptionPlan, {
  SubscriptionPlanState
} from 'app/entities/subscription-plan/subscription-plan.reducer';
// prettier-ignore
import liveVideoList, {
  LiveVideoState
 } from 'app/entities/live-video/live-video.reducer';
// prettier-ignore
import videoMetaData, {
  VideoMetaDataState
} from 'app/entities/video-meta-data/video-meta-data.reducer';
// prettier-ignore
import subscriptionHistory, {
  SubscriptionHistoryState
} from 'app/entities/subscription-history/subscription-history.reducer';
// prettier-ignore
import videoViewHistory, {
  VideoViewHistoryState
} from 'app/entities/video-view-history/video-view-history.reducer';
// prettier-ignore
import myFavourite, {
  MyFavouriteState
} from 'app/entities/my-favourite/my-favourite.reducer';
// prettier-ignore
import auditHistory, {
  AuditHistoryState
} from 'app/entities/audit-history/audit-history.reducer';
// prettier-ignore
import applicationConfiguration, {
  ApplicationConfigurationState
} from 'app/entities/application-configuration/application-configuration.reducer';
// prettier-ignore
import videoMetaDataKeywords, {
  VideoMetaDataKeywordsState
} from 'app/entities/video-meta-data-keywords/video-meta-data-keywords.reducer';
// prettier-ignore
import fileHistory, {
  FileHistoryState
} from 'app/entities/file-history/file-history.reducer';
// prettier-ignore
import globallyFreeSubscriptionPlan, {
  GloballyFreeSubscriptionPlanState
} from 'app/entities/globally-free-subscription-plan/globally-free-subscription-plan.reducer';
// prettier-ignore
import adminAuditHistory, {
  AdminAuditHistoryState
} from 'app/entities/admin-audit-history/admin-audit-history.reducer';
// prettier-ignore
import subscriptionPlanSpecification, {
  SubscriptionPlanSpecificationState
} from 'app/entities/subscription-plan-specification/subscription-plan-specification.reducer';
// prettier-ignore
import crowdSourcingState, {
  CrowdSourcingState
} from 'app/entities/crowd-sourcing/crowd-sourcing.reducer';
import youtubeLiveVideo, { YoutubeLiveVideoState } from 'app/entities/youtube-live-video/youtube-live-video.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

import videoList, { VideoYoutubVideoState } from 'app/modules/home/home.reducer';
// import { ReportState } from 'app/entities/reports/reports.reducer';
import adminPlaylist, { AdminPlaylistState } from 'app/entities/admin-playlist/admin-playlist.reducer';
import adminAssociationManagement, {
  AdminAssociationManagemetState,
} from 'app/entities/admin-associations-Management/admin-associations-management.reducer';
import sparshAlbumManagement, {sparshAlbumManagementState,} from 'app/entities/sparsh/admin-sparsh-reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly videoList: VideoYoutubVideoState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly statuses: StatusesState;
  readonly subscriptionPlan: SubscriptionPlanState;
  readonly crowdSourcingState: CrowdSourcingState;
  // readonly reportsList :ReportState,
  readonly liveVideoList: LiveVideoState;
  readonly videoMetaData: VideoMetaDataState;
  readonly adminPlaylist: AdminPlaylistState;
  readonly adminAssociationManagement: AdminAssociationManagemetState;
  readonly subscriptionHistory: SubscriptionHistoryState;
  readonly videoViewHistory: VideoViewHistoryState;
  readonly myFavourite: MyFavouriteState;
  readonly auditHistory: AuditHistoryState;
  readonly applicationConfiguration: ApplicationConfigurationState;
  readonly videoMetaDataKeywords: VideoMetaDataKeywordsState;
  readonly fileHistory: FileHistoryState;
  readonly globallyFreeSubscriptionPlan: GloballyFreeSubscriptionPlanState;
  readonly adminAuditHistory: AdminAuditHistoryState;
  readonly subscriptionPlanSpecification: SubscriptionPlanSpecificationState;
  readonly youtubeLiveVideo: YoutubeLiveVideoState;
  readonly sparshAlbumManagement: sparshAlbumManagementState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  statuses,
  subscriptionPlan,
  // reportsList,
  liveVideoList,
  videoMetaData,
  subscriptionHistory,
  videoViewHistory,
  myFavourite,
  auditHistory,
  applicationConfiguration,
  videoMetaDataKeywords,
  fileHistory,
  globallyFreeSubscriptionPlan,
  adminAuditHistory,
  subscriptionPlanSpecification,
  youtubeLiveVideo,
  adminPlaylist,
  adminAssociationManagement,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  videoList,
  crowdSourcingState,
  sparshAlbumManagement,
});

export default rootReducer;
