import { Moment } from 'moment';

export interface IAuditHistory {
  id?: number;
  videoOriginalJson?: string;
  videoNewJson?: string;
  updatedBy?: number;
  updatedOn?: string;
}

export const defaultValue: Readonly<IAuditHistory> = {};
