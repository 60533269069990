import React, { useEffect, useState } from 'react';
import Camera from '../../../content/images/Camera.jpg';
import './admin-associations-management.scss';

const FormUpload = (props) => {
    const [isError,setIsError]=useState(false)
    const {
        value,
        name,
        label,
        register,
         error,
                 inlineLabel,
        isrequired,
        isPreview,
        labelTypography,
        uploadSingleFile
    } = props;

    useEffect(() => {

    }, [isPreview])
    useEffect(() => {
        setIsError(error)
    }, [error])
    const onFileChange = () => {

    }

    const _onEditImg = () => {

    }

    const _onDeleteImg = () => {

    }

    return (
        <div className="form-upload-container">
            <div className="field-container">
                <div className="input-field-container sub-image ">
                    {!isPreview && <input type="file" accept='.png, .jpg, .jpeg' name={name} {...register} onChange={uploadSingleFile} />}
                    {(!isPreview && value === null) && (
                        <div className={`${isError?"associates-customized-fileupload-error":"associates-customized-fileupload"}`} style={{ 'width': 312 }} >
                            <span className="img-upload-icon"><img src={Camera} alt="" /></span>
                            {label}
                        </div>
                    )}

                    {(isPreview && value != null) && (
                        <div  >

                            <span ><img src={value} alt="" style={{  'height': 150 }} /></span>
                            
                        </div>
                    )}

                   

                    {error && <p className="error">{error}</p>}
                </div>
            </div>
        </div>

    );
};

FormUpload.defaultProps = {
    icon: '',
    isrequired: false,
    inlineLabel: false
};

export default FormUpload;