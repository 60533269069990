import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink, Row, Table, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, getLiveVidAssociationList, deleteLiveVidAssociation } from './live-video.reducer';

import './live-video.scss';
import Satshrut from '../../../content/images/satshrut3.png';

export interface ILiveVideoDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const LiveVideoDetails = (props: ILiveVideoDetailProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [associationDel, setAssociationDel] = useState<boolean>(false);
  const [associationId, setAssociationId] = useState<number>(null);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  const { liveVideoEntity, loading, liveVideoAssociations, totalItems, updating } = props;
  console.log('adminPlaylistEntity: ', liveVideoEntity);

  useEffect(() => {
    props.getEntity(props?.match?.params?.id);
    props.getLiveVidAssociation(props?.match?.params?.id);
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
  }, [props?.match?.params?.id]);

  const videoPlaylistDelete = id => {
    setAssociationDel(true);
    setAssociationId(id);
  };
  const handleCloseVidDelModel = () => {
    setAssociationDel(false);
  };
  const confirmDeleteAssociation = async () => {
    const result = await props.deleteLiveVidAssociation(associationId);
    if (result) {
      props.getLiveVidAssociation(props?.match?.params?.id);
      setAssociationDel(false);
    }
  };
  return liveVideoEntity && liveVideoEntity?.id ? (
    <div className="row">
      <Col md="12">
        {/* <h2>
          VideoMetaData [<b>{videoMetaDataEntity.id}</b>]
        </h2> */}
        <div className="detail-page-heading">
          <div className="detail-back-btn">
            <NavLink tag={Link} to={'/live-video' + props.location.search}>
              <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
            </NavLink>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
          <div className="common-btn" style={{ width: '112px', paddingLeft: 18 }}>
            <NavLink tag={Link} to={`/live-video/${liveVideoEntity?.id}/edit` + props.location.search}>
              <span className="header-title">Edit</span>
            </NavLink>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Live Video Title
              </p>
              <p className="detail-txt">{liveVideoEntity?.title}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className="detail-lable-txt">
                Live Video Id
              </p>
              <p className="detail-txt">{liveVideoEntity?.id}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="statuses" className="detail-lable-txt">
                Status
              </p>
              <p className="detail-txt">{liveVideoEntity.status ? liveVideoEntity?.status : '--'}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Created Date
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {liveVideoEntity?.createdOn ? liveVideoEntity?.createdOn : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Thumbnail
              </p>
              {liveVideoEntity?.thumbnails ? (
                <img src={liveVideoEntity?.thumbnails} style={{ maxWidth: 180, maxHeight: 150 }} key={liveVideoEntity?.id}></img>
              ) : (
                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
              )}
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Live Video Url
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {liveVideoEntity?.liveUrl ? liveVideoEntity?.liveUrl : '--'}
              </p>
            </div>
          </div>
        </div>
      </Col>
      {liveVideoEntity && (
        <Col md="12">
          <div className="main-container">
            <div className="page-title-btn-container">
              <Row className="justify-content-center">
                <Col md="12">
                  <div className="detail-page-heading">
                    <div className="detail-back-btn">
                      <h2 style={{ color: 'rgb(114 108 108)' }}>Attachments</h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="table-responsive">
              <div className="table-responsive">
                {liveVideoAssociations && show && (
                  <>
                    {' '}
                    {liveVideoAssociations.length > 0 ? (
                      <Table responsive style={{ marginTop: 10 }} id="userTableId">
                        <thead>
                          <tr>
                            <th className="hand">Id</th>
                            <th
                              className="hand"
                              // onClick={sort('playlistName')}
                            >
                              Title
                              <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                            </th>
                            <th className="hand">Content Url</th>
                            <th className="hand">Type</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {liveVideoAssociations&&liveVideoAssociations?.map((entity, i) => (
                            <tr key={`entity-${i}`}>
                              <td>{entity?.id ? entity.id : '--'}</td>
                              <td>{entity?.title ? entity.title : '--'}</td>
                              <td>{entity?.attachmentUrl ? entity.attachmentUrl : '--'}</td>
                              <td>{entity?.contentType ? entity.contentType.toUpperCase() : '--'}</td>

                              {/* <td /> */}
                              <td>
                                <div className="btn-group flex-btn-group-container">
                                  <button onClick={() => videoPlaylistDelete(entity.id)}>
                                    <FontAwesomeIcon
                                      icon="trash"
                                      style={{
                                        height: ' 23px',
                                        width: '18px',
                                        marginBottom: 2,
                                        color: '#db261d',
                                      }}
                                      data-toggle="tooltip"
                                      title="Delete"
                                    />
                                  </button>
                                  <NavLink
                                    tag={Link}
                                    to={`/live-video/${props.match.params.id}/${entity.id}/uploadAttachments`}
                                    // onClick={handleEditAssociation}
                                  >
                                    <FontAwesomeIcon
                                      icon="pencil-alt"
                                      style={{
                                        height: ' 23px',
                                        width: '18px',
                                        marginRight: 2,
                                        color: '#275cb0',
                                      }}
                                      data-toggle="tooltip"
                                      title="Edit"
                                    />
                                  </NavLink>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      !loading && <div className="alert alert-warning">No Data found</div>
                    )}
                  </>
                )}
              </div>{' '}
            </div>

            <Modal isOpen={associationDel} toggle={handleCloseVidDelModel}>
              <ModalHeader toggle={handleCloseVidDelModel}>Confirm delete operation</ModalHeader>
              <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this attachment?</ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={handleCloseVidDelModel}>
                  <FontAwesomeIcon icon="ban" />
                  &nbsp; Cancel
                </Button>
                <Button disabled={updating} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDeleteAssociation}>
                  <FontAwesomeIcon icon="trash" />
                  &nbsp; Delete
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Col>
      )}
    </div>
  ) : null;
};

const mapStateToProps = ({ liveVideoList }: IRootState) => ({
  liveVideoEntity: liveVideoList.entity,
  liveVideoAssociations: liveVideoList.entities,
  totalItems: liveVideoList.totalItems,
  loading: liveVideoList.loading,
  updating: liveVideoList.updating,
});

const mapDispatchToProps = { getEntity, getLiveVidAssociation: getLiveVidAssociationList, deleteLiveVidAssociation };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideoDetails);
