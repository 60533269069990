// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/jhipster_family_member_2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 497px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center top;\n  background-size: contain;\n}\n\n.content {\n  background-color: #f9f9f9;\n  width: 100%;\n  height: 100%;\n  padding: 15px 15px;\n  border-top: 1px solid #ddd;\n  overflow-y: scroll;\n}\n\n.videos {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.video {\n  width: 270px;\n  margin-bottom: 30px;\n  margin: 10px;\n}\n\n.thumbnail {\n  width: 100%;\n  height: 170px;\n}\n\n.thumbnail img {\n  -o-object-fit: cover;\n     object-fit: cover;\n  height: 94%;\n  width: 100%;\n}\n\n.author img {\n  -o-object-fit: cover;\n     object-fit: cover;\n  border-radius: 50%;\n  height: 40px;\n  width: 40px;\n  margin-right: 10px;\n}\n\n.details {\n  display: flex;\n}\n\n.title {\n  display: flex;\n  flex-direction: column;\n}\n\n.title h3 {\n  color: #030303;\n  line-height: 18px;\n  font-size: 14px;\n  margin-bottom: 6px;\n}\n\n.title a,\nspan {\n  text-decoration: none;\n  color: #606060;\n  font-size: 12px;\n}", ""]);
// Exports
module.exports = exports;
