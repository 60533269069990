import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VideoMetaDataKeywords from './video-meta-data-keywords';
import VideoMetaDataKeywordsDetail from './video-meta-data-keywords-detail';
import VideoMetaDataKeywordsUpdate from './video-meta-data-keywords-update';
import VideoMetaDataKeywordsDeleteDialog from './video-meta-data-keywords-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VideoMetaDataKeywordsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VideoMetaDataKeywordsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={VideoMetaDataKeywordsDetail} />
      <ErrorBoundaryRoute path={match.url} component={VideoMetaDataKeywords} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={VideoMetaDataKeywordsDeleteDialog} />
  </>
);

export default Routes;
