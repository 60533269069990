import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { NavLink, Row, Table } from 'reactstrap';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntities } from './admin-associations-management.reducer';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import Satshrut from '../../../content/images/satshrut3.png';
import { Document, Page, pdfjs } from 'react-pdf';

export interface IAdminAssociationsManagementProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));
enum Tablist {
  audio = 'audio',
  ebook = 'ebook',
  pad = 'pad',
  patra = 'patra',
  transcript = 'transcript',
}

const AdminAssociationsManagement = (props: IAdminAssociationsManagementProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const location = useLocation();
  const classes = useStyles();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE), location.search)
  );
  const [activeTab, setActiveTab] = useState(location.state?.showTab || Tablist.audio);
  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchValue') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchValue')
  );
  const [newSearch, setNewSearch] = useState(searchText);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'desc' ? 'asc' : 'desc',
      sort: p,
    });
  };

  const isPaginationCorrect = (sortColumn): Boolean => {
    switch (activeTab) {
      case 'audio':
        return sortColumn === 'title' || sortColumn === 'publish_date' ||  sortColumn === 'keywords';
        
      case 'ebook':
        return sortColumn === 'title' || sortColumn === 'publish_date' ||  sortColumn === 'keywords';
    
      case 'pad':
        return sortColumn === 'title' || sortColumn === 'publish_date' ||  sortColumn === 'keywords';
        
      case 'patra':
        return sortColumn === 'title' || sortColumn === 'publish_date' ||  sortColumn === 'keywords';
        
      case 'transcript':
        return sortColumn === 'title' || sortColumn === 'satsang_date' ||  sortColumn === 'keywords';
      default:
        return false;
      
    }
  
  };

  const getAllEntities = () => {
    props.getEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'title'},${paginationState.order}`,
      activeTab,
      searchText || null
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'title'
    },${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
    }
  };

  useEffect(() => {
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'publish_date'
    },${paginationState.order}`;

    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab, searchText]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSearchButton = () => {
    setSearchText(newSearch);
  };

  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };

  const { entities, match, loading, totalItems, isEntityLoading } = props;

  function handleSelectionChange(selection) {
    console.log('selection: ', selection);
  }

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab !== newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };

  return (
    <div  className="main-container">
      <div className="page-title-btn-container">
        <h2 id="video-meta-data-heading" style={{ color: 'rgb(114 108 108)' }}>
          Manage Associations
        </h2>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink
              tag={Link}
              to={`${match.url}/${activeTab}/new?searchValue=${searchText == '' ? null : searchText}&page=${
                paginationState.activePage
              }&sort=${paginationState.sort},${paginationState.order}`}
            >
              <span className="header-title">Upload</span>
            </NavLink>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        <Tab label="Audio" value={Tablist.audio} className={`${classes.tab} ${activeTab === Tablist.audio ? classes.activeTab : ''}`} />
        <Tab label="E-book" value={Tablist.ebook} className={`${classes.tab} ${activeTab === Tablist.ebook ? classes.activeTab : ''}`} />
        <Tab label="Pad" value={Tablist.pad} className={`${classes.tab} ${activeTab === Tablist.pad ? classes.activeTab : ''}`} />
        <Tab label="Patra" value={Tablist.patra} className={`${classes.tab} ${activeTab === Tablist.patra ? classes.activeTab : ''}`} />
        <Tab
          label="Transcript"
          value={Tablist.transcript}
          className={`${classes.tab} ${activeTab === Tablist.transcript ? classes.activeTab : ''}`}
        />
      </Tabs>
      <div className="table-responsive">
        {activeTab === 'audio' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      Audio Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">Audio Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      Audio Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('publish_date')}>
                      Publish Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((audioMetaData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {audioMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {audioMetaData.thumbnailImageUrl ? (
                              <img
                                src={audioMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={audioMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{audioMetaData?.title ? audioMetaData.title : '--'}</td>
                      <td>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <p>Apple Url : {audioMetaData?.contentUrlApple ? audioMetaData.contentUrlApple : '--'}</p>
                          <p>Spotify Url : {audioMetaData?.contentUrlSpotify ? audioMetaData.contentUrlSpotify : '--'}</p>
                        </div>
                      </td>
                      <td>{audioMetaData?.keywords ? audioMetaData.keywords : '--'}</td>

                      <td>{audioMetaData?.publishDate ? audioMetaData.publishDate : '--'}</td>

                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${audioMetaData.id}/details`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${audioMetaData.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${audioMetaData.id}/add`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${audioMetaData.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Audio Data found</div>
            )}
          </>
        )}
        {activeTab === 'ebook' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      E-book Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">E-book Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      E-book Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('publish_date')}>
                      Publish Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((ebookMetaData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {ebookMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {ebookMetaData.thumbnailImageUrl ? (
                              <img
                                src={ebookMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={ebookMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{ebookMetaData?.title ? ebookMetaData.title : '--'}</td>
                      <td>{ebookMetaData?.contentUrl ? ebookMetaData.contentUrl : '--'}</td>
                      <td>{ebookMetaData?.keywords ? ebookMetaData.keywords : '--'}</td>

                      <td>{ebookMetaData?.publishDate ? ebookMetaData.publishDate : '--'}</td>

                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${ebookMetaData.id}/details`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${ebookMetaData.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${ebookMetaData.id}/add`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${ebookMetaData.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No E-book Data found</div>
            )}
          </>
        )}
        {activeTab === 'pad' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      Pad Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">Pad Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      Pad Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('publish_date')}>
                      Publish Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((padMetaData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {padMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {padMetaData.thumbnailImageUrl ? (
                              <img src={padMetaData.thumbnailImageUrl} style={{ maxWidth: 70, maxHeight: 50 }} key={padMetaData.id}></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{padMetaData?.title ? padMetaData.title : '--'}</td>
                      <td>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <p>Apple Url : {padMetaData?.contentUrlApple ? padMetaData.contentUrlApple : '--'}</p>
                          <p>Spotify Url : {padMetaData?.contentUrlSpotify ? padMetaData.contentUrlSpotify : '--'}</p>
                        </div>
                      </td>

                      <td>{padMetaData?.keywords ? padMetaData.keywords : '--'}</td>

                      <td>{padMetaData?.publishDate ? padMetaData.publishDate : '--'}</td>

                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${padMetaData.id}/details`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${padMetaData.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${padMetaData.id}/add`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${padMetaData.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Pad Data found</div>
            )}
          </>
        )}
        {activeTab === 'patra' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      Patra Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">Patra Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      Patra Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('publish_date')}>
                      Publish Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((patraMetaData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {patraMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {patraMetaData.thumbnailImageUrl ? (
                              <img
                                src={patraMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={patraMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{patraMetaData?.title ? patraMetaData.title : '--'}</td>
                      <td>{patraMetaData?.contentUrl ? patraMetaData.contentUrl : '--'}</td>
                      <td>{patraMetaData?.keywords ? patraMetaData.keywords : '--'}</td>

                      <td>{patraMetaData?.publishDate ? patraMetaData.publishDate : '--'}</td>

                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${patraMetaData.id}/details`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${patraMetaData.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${patraMetaData.id}/add`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${patraMetaData.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Patra Data found</div>
            )}
          </>
        )}
        {activeTab === 'transcript' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      Transcript Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">Transcript Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      Transcript Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('satsang_date')}>
                      Satsang Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((transcriptMetaData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {transcriptMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {transcriptMetaData.thumbnailImageUrl ? (
                              <img
                                src={transcriptMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={transcriptMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{transcriptMetaData?.title ? transcriptMetaData.title : '--'}</td>
                      <td>{transcriptMetaData?.contentUrl ? transcriptMetaData.contentUrl : '--'}</td>
                      <td>{transcriptMetaData?.keywords ? transcriptMetaData.keywords : '--'}</td>

                      <td>{transcriptMetaData?.satsangDate ? transcriptMetaData.satsangDate : '--'}</td>

                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${transcriptMetaData.id}/details`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${transcriptMetaData.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${activeTab}/${transcriptMetaData.id}/add`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${transcriptMetaData.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Transcript Data found</div>
            )}
          </>
        )}{' '}
        {props.totalItems ? (
          <div className={` asso-table ${entities && entities.length > 0 && show && !isEntityLoading ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminAssociationManagement}: IRootState) => ({
  entities: adminAssociationManagement.entities,
  loading: adminAssociationManagement.loading,
  totalItems: adminAssociationManagement.totalItems,
  template: adminAssociationManagement.template,
  isEntityLoading: adminAssociationManagement.loading,
  loadingFile: adminAssociationManagement.loadingFile,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminAssociationsManagement);
