import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IGloballyFreeSubscriptionPlan {
  id?: number;
  isFreeSubscriptionPlan?: boolean;
  updatedOn?: string;
  updatedBy?: IUser;
}

export const defaultValue: Readonly<IGloballyFreeSubscriptionPlan> = {
  isFreeSubscriptionPlan: false,
};
