import { Moment } from 'moment';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';

export interface ISubscriptionPlanSpecification {
  id?: number;
  specificationName?: string;
  createdOn?: string;
  subscriptionPlan?: ISubscriptionPlan;
}

export const defaultValue: Readonly<ISubscriptionPlanSpecification> = {};
