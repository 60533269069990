import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faUpload } from '@fortawesome/free-solid-svg-icons'; // Import the icons
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import FormUpload from './admin-sparsh-management-track-upload';
import { getTrackDetailsByTrackId, uploadTrack } from './admin-sparsh-reducer';
import { toast } from 'react-toastify';

export interface IAdminSparshLyricsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      trackId: any;
      id?: string;
      association: string;
      url: string;
      liveVideoId: string;
    }> {}

export const AdminSparshTrackEdit = (props: IAdminSparshLyricsProps) => {
  const { associationEntity, loading, updating, entity } = props;
  const [trackDetails, setTrackDetails] = useState(null);
  const [isFileError, setFileError] = useState<boolean>(false);
  const [fileShow, setFileShow] = useState(null);
  const [isPreview, setPreview] = useState(props?.entity?.content?.[0]?.thumbnailUrl);
  const [file, setFile] = useState<any>(null);
  const [durationNumber, setNewDuration] = useState<any>(null);

  

  useEffect(() => {
    const fetchTrackDetails = async () => {
      const data = await props.getTrackDetailsByTrackId(props.match.params.trackId);
      setTrackDetails(data?.value?.data);
    };
    fetchTrackDetails();
  }, [props.match.params.trackId]);

  const convertBase64 = files => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result);
        setFileShow(fileReader.result);
        setFileError(false);
      };

      fileReader.onerror = error => {
        reject(error);
        setFileError(true);
      };
    });
  };

  const uploadSingleFile = async e => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      try {
        setPreview(true);
        setFile(file);
            
        const duration = await getMp3Duration(file);
        const durationNumber = Math.floor(Number(duration));
        
        setNewDuration(durationNumber)
        convertBase64(file);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getMp3Duration = (file) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.src = URL.createObjectURL(file);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = () => {
        reject(new Error('Error loading audio file'));
      };
    });
  };

  const handleFormSubmitFile = async (event, values) => {
    if (!file) {
      toast.warn('Add a MP3 file!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let result = await props?.uploadTrack(props.match.params.trackId, file, values?.duration); // Adjust this function as needed for MP3
    if (result) {
      props.history.goBack();
    }
  };

  const convertToMinutes = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={() => props.history.goBack()}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Edit Track Details</h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          {(
            <AvForm onValidSubmit={handleFormSubmitFile} model={props.associationEntity}>
              <div className="row">
                {/* Left Side */}
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="id" for="id" className="label-font">Track No.</Label>
                    <AvField id="id" type="text" name="id" className="input-font" value={props.match.params.trackId} disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="add1" for="add1" className="label-font">Title</Label>
                    <AvField id="add1" type="text" name="add1" className="input-font" value={trackDetails?.title} disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="duration" for="duration" className="label-font">Duration in seconds*</Label>
                    <AvField
                      id="duration"
                      type="number"
                      name="duration"
                      className="input-font"
                      value={trackDetails?.duration ? trackDetails.duration : ''}
                      disabled={durationNumber ? (durationNumber === trackDetails?.duration) : true}
                      validate={{
                        required: { value: true, errorMessage: 'Duration is required' },
                        pattern: { value: '^[0-9]*$', errorMessage: 'Duration must be a number' },
                        custom: (value, ctx, input, cb) => {
                          cb(parseInt(value, 10) === durationNumber ? true : `Duration does not match the uploaded MP3 file (expected ${durationNumber} seconds).`);
                        }
                      }}
                    />
                  </AvGroup>


                  
                  <div className="row" style={{ marginBottom: '40px' }}>
                    <div className="col-md-12">
                      <div className="file-container">
                        <FormUpload
                          label={<FontAwesomeIcon icon={faUpload} style={{ marginRight: '5px' }} />}
                          errorMsg={isFileError}
                          inlineLabel={false}
                          value={null}
                          style={{ height: 150 }}
                          uploadSingleFile={uploadSingleFile}
                        />
                        {fileShow ? (
                          <FormUpload
                            isPreview={isPreview}
                            inlineLabel={false}
                            value={fileShow}
                            style={{ height: 50 }}
                            uploadSingleFile={uploadSingleFile}
                          />
                        ) : (
                          <>
                            {/* {entity && (props?.entity?.content?.[0]?.thumbnailUrl) && (
                              <audio controls src={props?.entity?.content?.[0]?.thumbnailUrl} style={{ height: 50 }} />
                            )} */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Side */}
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="add2" for="add2" className="label-font">Listen Count</Label>
                    <AvField id="add2" type="text" name="add2" className="input-font" 
                    value={trackDetails?.playCount === 0 ? '--' : trackDetails?.playCount || '--'} 
                    disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="id-right" for="id-right" className="label-font">Published Date</Label>
                    <AvField id="id-right" type="text" name="id-right" className="input-font" value={trackDetails?.publishedDate} disabled />
                  </AvGroup>

                 
                  <AvGroup className="input-box-width">
                    <Label id="subscriptionStatus" for="subscriptionStatus" className="label-font">Subscription Status</Label>
                    <AvField id="subscriptionStatus" type="text" name="subscriptionStatus" className="input-font" 
                    value={
                      trackDetails?.subscriptionPlan?.id === 24
                        ? 'Paid'
                        : trackDetails?.subscriptionPlan?.id === 1
                        ? 'Free'
                        : '--'
                    }
                    disabled 
                    />
                  </AvGroup>


                  <AvGroup className="input-box-width">
                    <Label id="audioTrack" htmlFor="audioTrack" className="label-font">Track*</Label>
                    <div className="audio-preview">

                      <audio controls preload="auto">
                        {trackDetails?.url && (
                          <source src={trackDetails.url} type="audio/mpeg"/>
                        )}
                        Your browser does not support the audio element.
                      </audio>

                      
                    </div>
                  </AvGroup>
                  
                </div>
              </div>
              <button
                disabled={loading}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{ width: 200, marginTop: 20 }}
              >
                <span className="header-title">{loading ? 'Processing...' : 'Upload Track'}</span>
              </button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  associationEntity: storeState.liveVideoList.entity,
  loading: storeState.sparshAlbumManagement.loading,
  updating: storeState.liveVideoList.updating,
  updateSuccess: storeState.liveVideoList.updateSuccess,
  data: storeState.sparshAlbumManagement.lyricsEntity,
  entity: storeState.sparshAlbumManagement.entity,
});

const mapDispatchToProps = {
  getTrackDetailsByTrackId,
  uploadTrack,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshTrackEdit);
