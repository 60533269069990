import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './subscription-plan-specification.reducer';
import { ISubscriptionPlanSpecification } from 'app/shared/model/subscription-plan-specification.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISubscriptionPlanSpecificationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SubscriptionPlanSpecificationDetail = (props: ISubscriptionPlanSpecificationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { subscriptionPlanSpecificationEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          SubscriptionPlanSpecification [<b>{subscriptionPlanSpecificationEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="specificationName">Specification Name</span>
          </dt>
          <dd>{subscriptionPlanSpecificationEntity.specificationName}</dd>
          <dt>
            <span id="createdOn">Created On</span>
          </dt>
          <dd>
            {subscriptionPlanSpecificationEntity.createdOn ? (
              <TextFormat value={subscriptionPlanSpecificationEntity.createdOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>Subscription Plan</dt>
          <dd>{subscriptionPlanSpecificationEntity.subscriptionPlan ? subscriptionPlanSpecificationEntity.subscriptionPlan.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/subscription-plan-specification" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/subscription-plan-specification/${subscriptionPlanSpecificationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ subscriptionPlanSpecification }: IRootState) => ({
  subscriptionPlanSpecificationEntity: subscriptionPlanSpecification.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanSpecificationDetail);
