import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './globally-free-subscription-plan.reducer';
import { IGloballyFreeSubscriptionPlan } from 'app/shared/model/globally-free-subscription-plan.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IGloballyFreeSubscriptionPlanProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const GloballyFreeSubscriptionPlan = (props: IGloballyFreeSubscriptionPlanProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const { globallyFreeSubscriptionPlanList, match, loading } = props;
  return (
    <div>
      <h2 id="globally-free-subscription-plan-heading">
        Globally Free Subscription Plans
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Globally Free Subscription Plan
        </Link>
      </h2>
      <div className="table-responsive">
        {globallyFreeSubscriptionPlanList && globallyFreeSubscriptionPlanList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Is Free Subscription Plan</th>
                <th>Updated On</th>
                <th>Updated By</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {globallyFreeSubscriptionPlanList.map((globallyFreeSubscriptionPlan, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${globallyFreeSubscriptionPlan.id}`} color="link" size="sm">
                      {globallyFreeSubscriptionPlan.id}
                    </Button>
                  </td>
                  <td>{globallyFreeSubscriptionPlan.isFreeSubscriptionPlan ? 'true' : 'false'}</td>
                  <td>
                    {globallyFreeSubscriptionPlan.updatedOn ? (
                      <TextFormat type="date" value={globallyFreeSubscriptionPlan.updatedOn} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{globallyFreeSubscriptionPlan.updatedBy ? globallyFreeSubscriptionPlan.updatedBy.login : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${globallyFreeSubscriptionPlan.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${globallyFreeSubscriptionPlan.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${globallyFreeSubscriptionPlan.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Globally Free Subscription Plans found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ globallyFreeSubscriptionPlan }: IRootState) => ({
  globallyFreeSubscriptionPlanList: globallyFreeSubscriptionPlan.entities,
  loading: globallyFreeSubscriptionPlan.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GloballyFreeSubscriptionPlan);
