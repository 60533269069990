import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SubscriptionPlanSpecification from './subscription-plan-specification';
import SubscriptionPlanSpecificationDetail from './subscription-plan-specification-detail';
import SubscriptionPlanSpecificationUpdate from './subscription-plan-specification-update';
import SubscriptionPlanSpecificationDeleteDialog from './subscription-plan-specification-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SubscriptionPlanSpecificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SubscriptionPlanSpecificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SubscriptionPlanSpecificationDetail} />
      <ErrorBoundaryRoute path={match.url} component={SubscriptionPlanSpecification} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SubscriptionPlanSpecificationDeleteDialog} />
  </>
);

export default Routes;
