import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './video-view-history.reducer';
import { IVideoViewHistory } from 'app/shared/model/video-view-history.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IVideoViewHistoryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const VideoViewHistory = (props: IVideoViewHistoryProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const { videoViewHistoryList, match, loading } = props;
  return (
    <div className='main-container'>
      <h2 id="video-view-history-heading" style={{ color: 'rgb(114 108 108)' }}>
        Video View Histories
        {/* <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Video View History
        </Link> */}
      </h2>
      <div className="table-responsive">
        {videoViewHistoryList && videoViewHistoryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>Viewed Date</th>
                <th>Video Title</th>
                <th>Video Local Id</th>
                <th>User</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {videoViewHistoryList.map((videoViewHistory, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    {videoViewHistory.viewedDate ? (
                      <TextFormat type="date" value={videoViewHistory.viewedDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{videoViewHistory?.videoMetaData?.videoTitle}</td>
                  <td>
                    {videoViewHistory.videoMetaData ? (
                      // <Link to={`video-meta-data/${videoViewHistory.videoMetaData.id}`}>
                      videoViewHistory.videoMetaData.id
                      // </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{videoViewHistory.user ? videoViewHistory.user.login : ''}</td>
                  <td className="text-right">
                    {/* <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${videoViewHistory.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${videoViewHistory.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${videoViewHistory.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Video View Histories found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ videoViewHistory }: IRootState) => ({
  videoViewHistoryList: videoViewHistory.entities,
  loading: videoViewHistory.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewHistory);
