import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, Storage } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IAdminPlaylist, defaultValue } from 'app/shared/model/admin-playlist-model';
export const ACTION_TYPES = {
  FETCH_PLAYLIST_LIST: 'adminPlaylist/FETCH_PLAYLIST_LIST',
  FETCH_PLAYLIST_VIDEO_LIST: 'adminPlaylist/FETCH_PLAYLIST_VIDEO_LIST',
  FETCH_PLAYLIST_ASSOCIATION_LIST: 'adminPlaylist/FETCH_PLAYLIST_ASSOCIATION_LIST',
  FETCH_EXISTING_VIDEO_IDS: 'adminPlaylist/FETCH_PLAYLIST_VIDEO_LIST_IDS',
  FETCH_SEARCH_LIST: 'adminPlaylist/FETCH_SEARCH_LIST',
  FETCH_PLAYLIST_VIDEO: 'adminPlaylist/FETCH_PLAYLIST_VIDEO',
  FETCH_PLAYLIST: 'adminPlaylist/FETCH_PLAYLIST',
  CREATE_PLAYLIST: 'adminPlaylist/CREATE_PLAYLIST',
  UPDATE_PLAYLIST: 'adminPlaylist/UPDATE_PLAYLIST',
  DELETE_PLAYLIST: 'adminPlaylist/DELETE_PLAYLIST',
  DELETE_PLAYLIST_VIDEO: 'adminPlaylist/DELETE_PLAYLIST_VIDEO',
  DELETE_PLAYLIST_ASSOCIATION: 'adminPlaylist/DELETE_PLAYLIST_ASSOCIATION',
  FETCH_PLAYLIST_TEMPLATE: 'adminPlaylist/FETCH_PLAYLIST_TEMPLATE',
  FETCH_SERACH_VIDEO_LIST: 'adminPlaylist/FETCH_SERACH_VIDEO_LIST',
  UPDATE_PLAYLIST_LOGO: 'adminPlaylist/UPDATE_PLAYLIST_LOGO',
  UPDATE_PLAYLIST_Video_SERIALNO: 'adminPlaylist/UPDATE_PLAYLIST_Video_SERIALNO',
  RESET: 'adminPlaylist/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAdminPlaylist>,

  video: defaultValue,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  template: '',
  existingIds: [],
  isEntityLoading: false,
  loadingFile: null,
};

export type AdminPlaylistState = Readonly<typeof initialState>;

// Reducer

export default (state: AdminPlaylistState = initialState, action): AdminPlaylistState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PLAYLIST_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SEARCH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PLAYLIST_TEMPLATE):
    case REQUEST(ACTION_TYPES.FETCH_PLAYLIST_VIDEO_LIST):

    case REQUEST(ACTION_TYPES.FETCH_PLAYLIST):
    case REQUEST(ACTION_TYPES.FETCH_PLAYLIST_VIDEO):
    case REQUEST(ACTION_TYPES.UPDATE_PLAYLIST_Video_SERIALNO):
    case REQUEST(ACTION_TYPES.FETCH_EXISTING_VIDEO_IDS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        // entities: [],
      };
    case REQUEST(ACTION_TYPES.FETCH_PLAYLIST_ASSOCIATION_LIST):
      return {
        ...state,
        isEntityLoading: true,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        entities: [],
      };

    case REQUEST(ACTION_TYPES.FETCH_SERACH_VIDEO_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.CREATE_PLAYLIST):
    case REQUEST(ACTION_TYPES.UPDATE_PLAYLIST):
    case REQUEST(ACTION_TYPES.DELETE_PLAYLIST_VIDEO):
    case REQUEST(ACTION_TYPES.DELETE_PLAYLIST_ASSOCIATION):
    case REQUEST(ACTION_TYPES.DELETE_PLAYLIST):
      return {
        ...state,
        updating: true,
        errorMessage: null,
        updateSuccess: false,
      };

    case FAILURE(ACTION_TYPES.FETCH_SEARCH_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PLAYLIST_Video_SERIALNO):
    case FAILURE(ACTION_TYPES.FETCH_PLAYLIST_TEMPLATE):
      return {
        ...state,
        loadingFile: false,
      };

    case FAILURE(ACTION_TYPES.FETCH_PLAYLIST):
    case FAILURE(ACTION_TYPES.CREATE_PLAYLIST):
    case FAILURE(ACTION_TYPES.UPDATE_PLAYLIST):
    case FAILURE(ACTION_TYPES.DELETE_PLAYLIST):
    case FAILURE(ACTION_TYPES.DELETE_PLAYLIST_VIDEO):
    case FAILURE(ACTION_TYPES.DELETE_PLAYLIST_ASSOCIATION):
    case FAILURE(ACTION_TYPES.FETCH_PLAYLIST_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PLAYLIST_VIDEO_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PLAYLIST_ASSOCIATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PLAYLIST_VIDEO):
    case FAILURE(ACTION_TYPES.FETCH_EXISTING_VIDEO_IDS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        isEntityLoading: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_SERACH_VIDEO_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST_VIDEO_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST_ASSOCIATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        isEntityLoading: false,

        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SEARCH_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST_TEMPLATE):
      return {
        ...state,
        loading: false,
        template: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PLAYLIST_Video_SERIALNO):
    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST_VIDEO):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PLAYLIST_VIDEO):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXISTING_VIDEO_IDS):
      console.log(action.payload.data, 'success');
      return {
        ...state,
        loading: false,
        existingIds: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PLAYLIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PLAYLIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        // entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PLAYLIST):
    case SUCCESS(ACTION_TYPES.DELETE_PLAYLIST_VIDEO):
    case SUCCESS(ACTION_TYPES.DELETE_PLAYLIST_ASSOCIATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
        video: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const videoPlayListUrl = 'api/system/playlists';
const ebookPlayListUrl = 'api/system/playlist/ebooks';

// Actions

export const getEntities: ICrudGetAllAction<IAdminPlaylist> = (page, size, sort, order, params, type) => {
  const requestUrl = type === 'video' ? videoPlayListUrl : ebookPlayListUrl;
  // const requestUrl = `api/video-meta-data/search/{searchValue}?searchValue=${params}page=${page}&size=${size}`;
  const data = {
    searchValue: params,
    pageNo: page,
    sortColumn: sort,
    sortDirection: order,
    pageSize: size,
  };
  return {
    type: ACTION_TYPES.FETCH_PLAYLIST_LIST,
    payload: axios.post<IAdminPlaylist>(`${requestUrl}`, data),
  };
};

export const getPlayListVideo: ICrudGetAllAction<IAdminPlaylist> = (page, size, sort, params) => {
  const requestUrl = `${'api/system/playlist/contents/video/'}${params}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ' '}`;
  // const requestUrl = `api/video-meta-data/search/{searchValue}?searchValue=${params}page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_PLAYLIST_VIDEO_LIST,
    payload: axios.get<IAdminPlaylist>(`${requestUrl}`),
  };
};
export const getAssociationLists: ICrudGetAllAction<IAdminPlaylist> = (params: any, type: string) => {
  const requestUrl = type === 'video' ? `api/system/playlist/contents` : `api/system/playlist/content/ebook`;
  // const requestUrl = `api/video-meta-data/search/{searchValue}?searchValue=${params}page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_PLAYLIST_ASSOCIATION_LIST,
    payload: axios.post<IAdminPlaylist>(`${requestUrl}`, params),
  };
};

export const getTemplate = () => {
  const requestUrl = `api/templateDownload2`;
  const anchor = document.createElement('a');
  let headers = new Headers();
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
  headers.append('Authorization', `Bearer ${token}`);
  fetch(requestUrl, { headers })
    .then(response => response.blob())
    .then(blobby => {
      if (blobby.type == 'application/octet-stream') {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = 'VideoMetaDataFormat.xlsx';
        anchor.click();
        anchor.remove();
        window.URL.revokeObjectURL(objectUrl);
        toast.success('Successfully downloaded !', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });

  // return {
  //   type: ACTION_TYPES.FETCH_VIDEOMETADATA_TEMPLATE,
  //   payload: axios.get<IVideoMetaData>(requestUrl)
  // };
};

export const searchVideoList: ICrudGetAction<IVideoMetaData> = (params, page, size, sort) => {
  const requestUrl = `api/video-meta-data/search`;
  // Request URL: http://localhost:9000/api/video-meta-data/search/%7BsearchValue%7D?page=0&searchValue=G0q_Pgkqa68&size=10
  return {
    type: ACTION_TYPES.FETCH_SEARCH_LIST,
    // payload: axios.get<IVideoMetaData>(`${apiUrl}/search/gold`),
    payload: axios.post(requestUrl, cleanEntity(params)),
  };
};

export const getEntity: ICrudGetAction<IAdminPlaylist> = (id, type) => {
  const getEntityUrl = type === 'video' ? 'api/system/playlist' : 'api/system/playlist/ebook';
  const requestUrl = `${getEntityUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PLAYLIST,
    payload: axios.get<IAdminPlaylist>(requestUrl),
  };
};

export const getVideoById: ICrudGetAction<IAdminPlaylist> = id => {
  const getEntityUrl = 'api/SystemPlaylistAssociations/admin/findById';
  const requestUrl = `${getEntityUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PLAYLIST_VIDEO,
    payload: axios.get<IAdminPlaylist>(requestUrl),
  };
};
export const getExistingVideoIds: ICrudGetAction<IAdminPlaylist> = id => {
  const getEntityUrl = `api/system/playlist/contents/video/ids/${id}`;
  const requestUrl = `${getEntityUrl}`;
  const result = {
    type: ACTION_TYPES.FETCH_EXISTING_VIDEO_IDS,
    payload: axios.get<IAdminPlaylist>(requestUrl),
  };
  return result;
};
export const getExistingEbookPlaylistContentIds: ICrudGetAction<IAdminPlaylist> = (id, contentType) => {
  const getEntityUrl = `api/system/playlist/content/${contentType}/ids/${id}`;
  const requestUrl = `${getEntityUrl}`;
  const result = {
    type: ACTION_TYPES.FETCH_EXISTING_VIDEO_IDS,
    payload: axios.get<IAdminPlaylist>(requestUrl),
  };
  return result;
};
export const createVideoPlaylistEntity: ICrudPutAction<IAdminPlaylist> = entity => async dispatch => {
  const createUrl = 'api/system/playlist/save';
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PLAYLIST,
    payload: axios.post(createUrl, cleanEntity(entity)),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Created!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getEntities());
  return result.value.data;
};
export const createEbookPlaylistEntity: ICrudPutAction<IAdminPlaylist> = entity => async dispatch => {
  const createUrl = 'api/system/playlist/ebook/save';
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PLAYLIST,
    payload: axios.post(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Created!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getEntities());
  return result.value.data;
};

export const updateVideoPlaylistEntity: ICrudPutAction<IAdminPlaylist> = entity => async dispatch => {
  const createUrl = 'api/system/playlist/update';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYLIST,
    payload: axios.put(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // dispatch(getEntities());
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // dispatch(getEntities());
  }
  return result.value.data;
  // dispatch(getEntities());
  // return result;
};
export const updateEbookPlaylistEntity: ICrudPutAction<IAdminPlaylist> = entity => async dispatch => {
  const createUrl = 'api/system/playlist/ebook/update';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYLIST,
    payload: axios.put(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // dispatch(getEntities());
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // dispatch(getEntities());
  }
  return result.value.data;
  // dispatch(getEntities());
  // return result;
};

export const serialNumberUpdate: ICrudPutAction<IAdminPlaylist> = entity => async dispatch => {
  const createUrl = 'api/system/playlist/content/update';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYLIST_Video_SERIALNO,
    payload: axios.put(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // dispatch(getEntities());
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // dispatch(getEntities());
  }
  return result.value.data;
  // return result;
};

export const updateSubImage: ICrudPutAction<IAdminPlaylist> = (id: any, params: any) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYLIST_LOGO,
    payload: axios.post(`api/system/playlist/upload/thumbnail/${id}`, params),
    meta: {
      successMessage: '',
      errorMessage: '',
    },
  });
  // const data = {
  //   searchValue: null,
  //   pageNo: 1,
  //   pageSize: 20,
  // };
  // dispatch(getEntities(0, 20));
};
export const getLocationList = async (): Promise<any> => {
  const geSpeakerUrl = `api/open/getAllLoations`;
  const data = await axios.get(geSpeakerUrl);
  return data.data;
};

export const deleteEntity: ICrudDeleteAction<IAdminPlaylist> = (id, page, size, sort, order, params, type) => async dispatch => {
  const deleteUrl = type === 'video' ? 'api/system/playlist/delete' : 'api/system/playlist/ebook/delete';
  const requestUrl = `${deleteUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PLAYLIST,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Deleted !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  dispatch(getEntities(page, size, sort, order, params, type));
  return result;
};

export const addVideoToVideoPlaylist: ICrudPutAction<IAdminPlaylist> = (entity, page, size, sort, param) => async dispatch => {
  console.log('hit2');
  const createUrl = 'api/system/playlist/content/save';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYLIST,
    payload: axios.post(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Added !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getPlayListVideo(page, size, sort, param));
  return result;
};
export const addContentToEbookPlaylist: ICrudPutAction<IAdminPlaylist> = (entity, contentType) => async dispatch => {
  console.log('hit2');
  const createUrl = `api/system/playlist/content/${contentType}/save`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYLIST,
    payload: axios.post(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Added !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getPlayListVideo(page, size, sort, param));
  return result;
};
export const deleteVideo: ICrudDeleteAction<IAdminPlaylist> = (
  videoId,
  playListId,
  page,
  size,
  sort,
  order,
  searchText
) => async dispatch => {
  const deleteUrl = 'api/system/playlist/content/delete';
  const requestUrl = `${deleteUrl}/${videoId}/${playListId}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PLAYLIST_VIDEO,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Deleted !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const data = {
    sortColumn: sort,
    sortDirection: order,
    pageNo: page,
    pageSize: size,
    playListId: playListId,
    contentType: 'VIDEO',
    searchValue: searchText ? searchText : null,
  };
  dispatch(getAssociationLists(data, 'video'));
  return result;
};
export const deleteAssociation: ICrudDeleteAction<IAdminPlaylist> = (
  associationId,
  association,
  playListId,
  page,
  size,
  sort,
  sortDirection,
  searchText,
  type
) => async dispatch => {
  const deleteUrl =
    type === 'video' ? `api/${association}/system/playlist/content/delete` : `api/system/playlist/content/${association}/delete`;
  const requestUrl = `${deleteUrl}/${associationId}/${playListId}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PLAYLIST_ASSOCIATION,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Deleted !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const data = {
    sortColumn: sort,
    sortDirection: sortDirection.toUpperCase(),
    pageNo: page,
    pageSize: size,
    playListId: playListId,

    contentType: association.toUpperCase(),
    searchValue: searchText ? searchText : null,
  };
  dispatch(getAssociationLists(data, type));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
