import React, { useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity, reset } from './admin-playlist.reducer';

export interface IPlaylistDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{  type:string,id: string,}> {}

export const AdminPlaylistDeleteDialog = (props: IPlaylistDeleteDialogProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id,props.match.params.type);
  }, []);

  const handleClose = () => {

    props.history.push('/admin-playlist' + props.location.search);
  };



  const confirmDelete = async() => {
    const query = new URLSearchParams(props.location.search);
    const page = +query.get('page')-1;
    const search = query.get('searchValue');
    const sort = query.get('sort');
    const sortSpit = sort.split(',')



    const result = await props.deleteEntity(props.playlistEntity.id, page,20,sortSpit[0],sortSpit[1], search=="null"?null:search,props.match.params.type);
    if (result) {
      handleClose();
    }
  };

  const { playlistEntity,updating } = props;
  return (
    <Modal isOpen >
      <ModalHeader>Confirm delete operation</ModalHeader>
      <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {props.match.params.type === 'video'?'playlist':'series'} ?</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp; Cancel
        </Button>
        <Button disabled={updating} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp; Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ adminPlaylist }: IRootState) => ({
  playlistEntity: adminPlaylist.entity,
  updateSuccess: adminPlaylist.updateSuccess,
  updating:adminPlaylist.updating
});

const mapDispatchToProps = { getEntity, deleteEntity, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylistDeleteDialog);
