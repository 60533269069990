import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IVideoMetaData, defaultValue } from 'app/shared/model/video-meta-data.model';

export const ACTION_TYPES = {
  FETCH_YOUTUB_VIDEO_LIST: 'home/FETCH_YOUTUB_VIDEO_LIST',
  RESET: 'home/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  videoList: [],
};

export type VideoYoutubVideoState = Readonly<typeof initialState>;

// Reducer

export default (state: VideoYoutubVideoState = initialState, action): VideoYoutubVideoState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_YOUTUB_VIDEO_LIST):
      return {
        ...state,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.FETCH_YOUTUB_VIDEO_LIST):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_YOUTUB_VIDEO_LIST):
      return {
        ...state,
        loading: false,
        videoList: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// const apiUrl = 'api/video-meta-data/subscriptionPlan/4';

// Actions

export const youtubVideoList: ICrudPutAction<IVideoMetaData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_YOUTUB_VIDEO_LIST,
    // payload: axios.get(apiUrl),
  });
  return result;
};
export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
