import { Moment } from 'moment';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { IUser } from 'app/shared/model/user.model';
import { IStatuses } from 'app/shared/model/statuses.model';

export interface ISubscriptionHistory {
  id?: number;
  startDate?: string;
  endDate?: string;
  transactionId?: string;
  transactionDate?: string;
  subscriptionPlan?: ISubscriptionPlan;
  user?: IUser;
  statuses?: IStatuses;
}

export const defaultValue: Readonly<ISubscriptionHistory> = {};
