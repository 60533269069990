import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, Row, Table } from 'reactstrap';
import { Tabs, Tab, Box } from '@material-ui/core';
import { NavLink as Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import './admin-playlist.scss';

import Satshrut from '../../../content/images/satshrut3.png';

import { IRootState } from 'app/shared/reducers';
import {
  getEntities,
  searchVideoList,
  // downloadVideoMetaData,
} from './admin-playlist.reducer';

import { SERVER_API_URL } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export interface IAdminPlaylistProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));
enum Tablist {
  video = 'video',
  ebook = 'ebook',
}

const clientId = '795835921840-0uc8gamhqs3r888udn0p1sbuli98vjt6.apps.googleusercontent.com';

export const AdminPlaylist = (props: IAdminPlaylistProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [imageKey, setImageKey] = useState(true);
  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchValue') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchValue')
  );
  const classes = useStyles();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.state?.showTab || Tablist.video);
  const [newSearch, setNewSearch] = useState(searchText);
  const [isNewSearch, setIsNewSearch] = useState(false);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const isPaginationCorrect = (sortColumn): string => {
    switch (activeTab) {
      case 'video':
        if (
          sortColumn === 'id' ||
          sortColumn === 'location' ||
          sortColumn === 'playlistName' ||
          sortColumn === 'noOfEbooks' ||
          sortColumn === 'createdDate' ||
          sortColumn === 'speaker' ||
          sortColumn === 'startDate' ||
          sortColumn === 'endDate' ||
          sortColumn === 'noOfVideos' ||
          sortColumn === 'noOfaudios' ||
          sortColumn === 'noOfPads' ||
          sortColumn === 'noOfPatras' ||
          sortColumn === 'noOfTranscript'
        ) {
          return sortColumn;
        } else {
          return 'id';
        }

      case 'ebook':
        if (
          sortColumn === 'id' ||
          sortColumn === 'location' ||
          sortColumn === 'playlistName' ||
          sortColumn === 'noOfEbooks' ||
          sortColumn === 'recommendedBy' ||
          sortColumn === 'createdDate' ||
          sortColumn === 'author' ||
          sortColumn === 'startDate' ||
          sortColumn === 'endDate' ||
          sortColumn === 'writtenBy' ||
          sortColumn === 'writtenTo'||
          sortColumn === 'noOfPatras' ||
          sortColumn === 'noOfTranscript'
        ) {
          return sortColumn;
        } else {
          return 'id';
        }

      default:
        return 'id';
    }
  };
  useEffect(() => {
    console.log('hit search');
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');

    if (page && sort) {
      const sortSplit = sort.split(',');

      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);
  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p.trim(),
    });
  };

  const getAllEntities = () => {
    setImageKey(false);
    props.getEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      isPaginationCorrect(paginationState.sort),
      paginationState.order,
      searchText || null,
      activeTab
    );
    setTimeout(() => {
      setImageKey(true);
    }, 2000);
  };

  const sortEntities = () => {
    getAllEntities();
    console.log(props.location.search, searchText);
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${isPaginationCorrect(paginationState.sort)},${paginationState.order}`;
    console.log(endURL);
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
    }
  };
  useEffect(() => {
    console.log('hit three dependencies');
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) 
    },${paginationState.order}`;

    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
  }, [paginationState.activePage, paginationState.order, paginationState.sort, searchText, activeTab]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const { adminPlaylists, match, loading, totalItems } = props;

  useEffect(() => {
    const interval = setInterval(() => {
      setImageKey(prevKey => !prevKey);
      clearInterval(interval);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getImageUrl = item => {
    if (item.thumbnail) {
      const cacheBuster = imageKey ? Date.now() : '';
      return `${SERVER_API_URL}/api/system/playlist/thumbnail/${item.id}?t=${cacheBuster}`;
    }
    return Satshrut;
  };

  const handleSearchButton = () => {
    setSearchText(newSearch);
  };

  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };
  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab !== newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };

  return (
    <div className="main-container">
      <div className="page-title-btn-container">
        <h2 id="video-meta-data-heading" style={{ color: 'rgb(114 108 108)' }}>
          Manage Playlist
        </h2>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={handleSearch}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={handleSearchButton}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink
              tag={Link}
              to={`${match.url}/${activeTab}/new?searchValue=${searchText == '' ? null : searchText}&page=${
                paginationState.activePage
              }&sort=${paginationState.sort},${paginationState.order}`}
            >
              <span className="header-title">{activeTab==='video'?'Create Playlist':'Create Series'}</span>
            </NavLink>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        <Tab label="Video" value={Tablist.video} className={`${classes.tab} ${activeTab === Tablist.video ? classes.activeTab : ''}`} />
        <Tab label="E-book" value={Tablist.ebook} className={`${classes.tab} ${activeTab === Tablist.ebook ? classes.activeTab : ''}`} />
      </Tabs>
      <div className="table-responsive">
        {activeTab === 'video' && adminPlaylists && show && (
          <>
            {adminPlaylists.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">Thumbnail</th>
                    <th onClick={sort('playlistName')} className="hand">
                      Playlist Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfVideos')} className="hand">
                      Video Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('location')} className="hand">
                      Location
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('speaker')} className="hand">
                      Speaker
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfaudios')} className="hand">
                      Audio Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfEbooks')} className="hand">
                      E-book Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfPads')} className="hand">
                      Pad Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfPatras')} className="hand">
                      Patra Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfTranscript')} className="hand">
                      Transcript Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('startDate')} className="hand">
                      Start Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('endDate')} className="hand">
                      End Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('createdDate')} className="hand">
                      Created Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminPlaylists?.map((adminPlaylist, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {adminPlaylist && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {adminPlaylist.thumbnail ? (
                              <img src={getImageUrl(adminPlaylist)} style={{ maxWidth: 70, maxHeight: 50 }} key={adminPlaylist.id}></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>
                      <td>{adminPlaylist?.playlistName ? adminPlaylist.playlistName : '--'}</td>
                      <td>{adminPlaylist?.noOfVideos ? adminPlaylist.noOfVideos : '--'}</td>
                      <td>{adminPlaylist?.location ? adminPlaylist.location : '--'}</td>
                      <td>{adminPlaylist?.speaker ? adminPlaylist.speaker : '--'}</td>

                      <td>{adminPlaylist?.noOfaudios ? adminPlaylist.noOfaudios : '--'}</td>
                      <td>{adminPlaylist?.noOfEbooks ? adminPlaylist.noOfEbooks : '--'}</td>
                      <td>{adminPlaylist?.noOfPads ? adminPlaylist.noOfPads : '--'}</td>
                      <td>{adminPlaylist?.noOfPatras ? adminPlaylist.noOfPatras : '--'}</td>
                      <td>{adminPlaylist?.noOfTranscript ? adminPlaylist.noOfTranscript : '--'}</td>
                      <td>{adminPlaylist?.startDate ? adminPlaylist.startDate : '--'}</td>
                      <td>{adminPlaylist?.endDate ? adminPlaylist.endDate : '--'}</td>

                      <td>{adminPlaylist?.createdDate ? adminPlaylist.createdDate : '--'}</td>
                      <td>{adminPlaylist?.status ? adminPlaylist?.status : '--'}</td>
                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${adminPlaylist.id}?searchValue=${searchText == '' ? null : searchText}&page=${
                              paginationState.activePage
                            }&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${adminPlaylist.id}/${adminPlaylist.playlistName}/playlistAssociations`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${adminPlaylist.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${adminPlaylist.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Playlist found</div>
            )}
          </>
        )}
        {activeTab === 'ebook' && adminPlaylists && show && (
          <>
            {adminPlaylists.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                    <th className="hand">Thumbnail</th>
                    <th onClick={sort('playlistName')} className="hand">
                      Series Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('location')} className="hand">
                      location
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('recommendedBy')} className="hand">
                      Recommended By
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('author')} className="hand">
                      Author
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('writtenBy')} className="hand">
                      Written By
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('writtenTo')} className="hand">
                      WrittenTo
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfEbooks')} className="hand">
                      E-book Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th onClick={sort('noOfPatras')} className="hand">
                      Patra Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('noOfTranscript')} className="hand">
                      Transcript Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('startDate')} className="hand">
                      Start Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('endDate')} className="hand">
                      End Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th onClick={sort('createdDate')} className="hand">
                      Created Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminPlaylists?.map((adminPlaylist, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        {adminPlaylist && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {adminPlaylist.thumbnail ? (
                              <img src={adminPlaylist.thumbnail} style={{ maxWidth: 70, maxHeight: 50 }} key={adminPlaylist.id}></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>
                      <td>{adminPlaylist?.playlistName ? adminPlaylist.playlistName : '--'}</td>

                      <td>{adminPlaylist?.location ? adminPlaylist.location : '--'}</td>
                      <td>{adminPlaylist?.recommendedBy ? adminPlaylist.recommendedBy : '--'}</td>
                      <td>{adminPlaylist?.author ? adminPlaylist.author : '--'}</td>

                      <td>{adminPlaylist?.writtenBy ? adminPlaylist.writtenBy : '--'}</td>
                      <td>{adminPlaylist?.writtenTo ? adminPlaylist.writtenTo : '--'}</td>

                      <td>{adminPlaylist?.noOfEbooks ? adminPlaylist.noOfEbooks : '--'}</td>

                      <td>{adminPlaylist?.noOfPatras ? adminPlaylist.noOfPatras : '--'}</td>
                      <td>{adminPlaylist?.noOfTranscript ? adminPlaylist.noOfTranscript : '--'}</td>
                      <td>{adminPlaylist?.startDate ? adminPlaylist.startDate : '--'}</td>
                      <td>{adminPlaylist?.endDate ? adminPlaylist.endDate : '--'}</td>

                      <td>{adminPlaylist?.createdDate ? adminPlaylist.createdDate : '--'}</td>
                      <td>{adminPlaylist?.status ? adminPlaylist?.status : '--'}</td>
                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${adminPlaylist.id}?searchValue=${searchText == '' ? null : searchText}&page=${
                              paginationState.activePage
                            }&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${searchText == '' ? null : searchText}/${paginationState.activePage}/${
                              paginationState.sort
                            }/${paginationState.order}/${adminPlaylist.id}/${adminPlaylist.playlistName}/playlistAssociations`}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#808080',
                              }}
                              data-toggle="tooltip"
                              title="Add"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${adminPlaylist.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${adminPlaylist.id}/delete?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Playlist found</div>
            )}
          </>
        )}
      </div>
      {props.totalItems ? (
        <div className={`asso-table ${adminPlaylists && adminPlaylists.length > 0 && show ? null : 'd-none'}`}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ adminPlaylist }: IRootState) => ({
  adminPlaylists: adminPlaylist.entities,
  loading: adminPlaylist.loading,
  totalItems: adminPlaylist.totalItems,
  template: adminPlaylist.template,
  video: adminPlaylist,
  loadingFile: adminPlaylist.loadingFile,
});

const mapDispatchToProps = {
  getEntities,
  searchVideoList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylist);
