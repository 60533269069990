import { Moment } from 'moment';

export interface IAdminAuditHistory {
  id?: number;
  newJson?: string;
  oldJson?: string;
  type?: string;
  subscriptionHistoryId?: number;
  subscriptionPlanId?: number;
  updatedBy?: number;
  updatedOn?: string;
}

export const defaultValue: Readonly<IAdminAuditHistory> = {};
