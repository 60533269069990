import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import 'draft-js/dist/Draft.css';
import JoditEditor from 'jodit-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Label, Row } from 'reactstrap';
import { getEntity, getLyrics, uploadEntity, uploadThumbnail } from './admin-sparsh-reducer';

export interface IAdminSparshLyricsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      trackId: any;
      id?: string;
      association: string;
      url: string;
      liveVideoId: string;
    }> {}

export const AdminSparshLyrics = (props: IAdminSparshLyricsProps) => {
  const { associationEntity, loading, updating } = props;
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [lyrics, setLyrics] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const handleGoBack = () => {
    props.history.goBack();
  };

  // useEffect(() => {
  //   const fetchLyrics = async () => {
  //     await props.getLyrics(props.match.params.trackId);
  //   };
  //   fetchLyrics();
  // }, [props.match.params.trackId, props.getLyrics]);

  // useEffect(() => {
  //   if (props.data?.data?.lyrics) {
  //     setLyrics(props.data.data.lyrics);
  //   }
  // }, [props.data]);

  // useEffect(() => {
  //   setContent(lyrics);
  // }, [lyrics]);



  useEffect(() => {
    const fetchLyrics = async () => {
        await props.getLyrics(props.match.params.trackId);
    };
    fetchLyrics();
    return () => {
      setLyrics(null);
      setContent('');
    };
  }, [props.match.params.trackId, props.getLyrics]);

  useEffect(() => {
    if (props.data?.data?.lyrics) {
      setLyrics(props.data.data.lyrics);
    } else {
      setLyrics(null);
      setContent('');
    }
  }, [props.data]);

  useEffect(() => {
      // if (lyrics) {
        setContent(lyrics || '');
      // }
  }, [lyrics]);


  const options = [ 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];


  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      height: 500,
      width: 1500,
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
    }),
    []
  );

  function onChangeHandler(lyrics: string): void {
    setLyrics(lyrics);
  }

  const handleFormSubmit = async event => {
    // if (!lyrics) return;
    let result = await props?.uploadEntity(props.match.params.trackId, lyrics);
    if (result) {
      handleGoBack();
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleGoBack}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Edit Track Details </h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm onValidSubmit={handleFormSubmit} model={isNew ? {} : associationEntity}>
              <div className="row">
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="id" for="id" className="label-font">
                      Track Id*
                    </Label>
                    <AvField id="id" type="text" name="id" className="input-font" value={props?.match?.params?.trackId} disabled />
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onBlur={newContent => setContent(newContent)}
                    onChange={newContent => onChangeHandler(newContent)}
                  />
                </div>
              </div>
              <button
                disabled={updating}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{
                  width: 200,
                  marginTop: 20,
                }}
              >
                <span className="header-title">Upload Lyrics</span>
              </button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  associationEntity: storeState.liveVideoList.entity,
  loading: storeState.liveVideoList.loading,
  updating: storeState.liveVideoList.updating,
  updateSuccess: storeState.liveVideoList.updateSuccess,
  data: storeState.sparshAlbumManagement.lyricsEntity,
  entity: storeState.sparshAlbumManagement.entity,
});

const mapDispatchToProps = {
  getStatuses,
  uploadEntity,
  uploadThumbnail,
  getLyrics,
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshLyrics);
