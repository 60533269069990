import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Row, Col, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ICrudGetAction, JhiItemCount, JhiPagination, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Satshrut from '../../../content/images/satshrut3.png';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  getAssociatedPlaylists,
  removePlaylistAssociation,
  reset,
  getAssociatedVideos,
  getAssociatedTracks,
  removeVideoAssociation,
  removeTrackAssociation,
} from './admin-associations-management.reducer';

import './admin-associations-management.scss';
import { SERVER_API_URL } from 'app/config/constants';
import PDFDownload from './pdf-download';
import { makeStyles } from '@material-ui/core/styles';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

import ViewDocument from './ViewDocument';

export interface IAdminAssociationManagementProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ pSearch: string; id: string; association: string; pPage: string; pOrder: string; pSort: string }> {}
const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));

enum Tablist {
  video = 'video',
  playlist = 'playlist',
  track = 'track',
}
export const AdminAssociationManagementDetails = (props: IAdminAssociationManagementProps) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState<Tablist>(Tablist.video);
  const [imageKey, setImageKey] = useState(true);
  const [showAssociationRemove, setShowAssociationRemove] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [associatedWithId, setAssociationId] = useState(null);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [entityKeys, setEntityKeys] = useState<string[]>(null);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [isNewSearch, setIsNewSearch] = useState(false);
  const avoidKeys = [
    'id',
    'thumbnailImageUrl',
    'imageUrl',
    'noOfLikes',
    'noOfViews',
    'refContentId',
    'thumbnailBlobName',
    'imageBlobName',
    'contentUrlBlobName',
    "title",
    "contentStorage",
    "publishDateTime"
  ];

  const keyTitles={
    id:'ID',
    thumbnailImageUrl:'Thumbnail Image Url',
    imageUrl:'Image Url',
    noOfLikes:'No Of Likes',
    noOfViews:'No Of Views',
    language:'Language',
    speaker:'Speaker',
    thumbnailBlobName:'Thumbnail Blob Name',
    imageBlobName:'Image Blob Name',
    contentUrlBlobName:'Content Url Blob Name',
    refContentId:'Ref Content Id',
    satsangDate: 'Satsang Date',
    publishDate: "Publish Date",
    indianDate: "Publish Date (Indian)",
    patraNo: "Patra Number",
    contentUrl: "Content Url",
    contentUrlApple: "Apple Url",
    contentUrlSpotify: "Spotify Url",
    writtenTo: "Written To",
    author: "Author",
    yearOfPatra: "Year of Patra",
    publisher : "Publisher",
    comments: "Comments",
    satsangTopic : "Satsang Topic",
    version : "Version",
    durationTotalMin: "Duration",
    keywords: "Keywords",
    pageCount:"Page Count",
    topic:"Topic",
    
  }

  const { entity, loading, entities, totalItems, isEntityLoading, updating } = props;

  useEffect(() => {
    props.getEntity(props?.match?.params?.id, props?.match?.params?.association);
  }, [props?.match?.params?.id, props?.match?.params?.association]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };
  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab != newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };
  const isVideoPagination = (sortColumn): Boolean => {
    if (
      sortColumn === 'serialNumber' || 
      sortColumn === 'language' || 
      sortColumn === 'videoTitle'|| 
      sortColumn === 'track_id' ||
      sortColumn === 'title' ||
      sortColumn === 'play_count' ||
      sortColumn === 'publishedDate' ||
      sortColumn === 'duration' 
    ) {
      return true;
    }

    return false;
  };
  const getAllEntities = () => {
    switch (activeTab) {
      case 'playlist':
        const playlistData = {
          searchValue: searchText ? searchText : null,
          pageNo: paginationState.activePage - 1,
          pageSize: paginationState.itemsPerPage,
          contentId: props?.match?.params?.id,
          contentType: props?.match?.params?.association.toUpperCase(),
          sortColumn: isVideoPagination(paginationState.sort) ? 'id' : paginationState.sort,
          sortDirection: paginationState.order,
        };

        props.getAssociatedPlaylists(playlistData);
        isNewSearch && setIsNewSearch(false);

        break;
      case 'video':
        const videoData = {
          searchValue: searchText ? searchText : null,
          pageNo: paginationState.activePage - 1,
          pageSize: paginationState.itemsPerPage,
          contentId: props?.match?.params?.id,
          contentType: props?.match?.params?.association.toUpperCase(),
          sortColumn: isVideoPagination(paginationState.sort) ? paginationState.sort : 'serialNumber',
          sortDirection: paginationState.order,
        };
        props.getAssociatedVideos(videoData);
        isNewSearch && setIsNewSearch(false);

        break;

      case 'track':
        const trackData = {
          searchValue: searchText ? searchText : '',
          pageNo: paginationState.activePage - 1,
          pageSize: paginationState.itemsPerPage,
          contentId: props?.match?.params?.id,
          contentType: props?.match?.params?.association.toUpperCase(),
          sortColumn: isVideoPagination(paginationState.sort) ? paginationState.sort : 'title',
          sortDirection: paginationState.order,
        };
        props.getAssociatedTracks(trackData);
        isNewSearch && setIsNewSearch(false);

        break;
    }
  };
  const getImageUrl = item => {
    if (item.thumbnail) {
      const cacheBuster = imageKey ? Date.now() : '';
      return `${SERVER_API_URL}/api/system/playlist/thumbnail/${item.id}?t=${cacheBuster}`;
    }
    return Satshrut;
  };
  const sortEntities = () => {
    getAllEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?page=${paginationState.activePage}&sort=${
      activeTab === 'video'
        ? isVideoPagination(paginationState.sort)
          ? paginationState.sort
          : 'serialNumber'
        : isVideoPagination(paginationState.sort)
        ? 'id'
        : paginationState.sort
    },${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab]);
  useEffect(() => {
    isNewSearch && paginationState.activePage === 1 && sortEntities();
  }, [isNewSearch]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  const handleCloseRemoveModal = () => {
    setShowAssociationRemove(false);
  };

  const confirmRemoveAssociation = async () => {
    let result;
    if (activeTab === 'playlist') {
      result = await props.removePlaylistAssociation(
        entity.id,
        props?.match?.params?.association,
        props?.match?.params?.association.toUpperCase(),
        searchText ? searchText : null,
        associatedWithId,
        paginationState.activePage - 1,
        paginationState.itemsPerPage,
        paginationState.sort,
        paginationState.order
      );
    } else if ((activeTab === 'video')){
      result = await props.removeVideoAssociation(
        entity.id,
        props?.match?.params?.association,
        props?.match?.params?.association.toUpperCase(),
        searchText ? searchText : null,
        associatedWithId,
        paginationState.activePage - 1,
        paginationState.itemsPerPage,
        paginationState.sort,
        paginationState.order
      );
    }
    else{      
      result = await props.removeTrackAssociation(
        entity.id,
        props?.match?.params?.association,
        props?.match?.params?.association.toUpperCase(),
        searchText ? searchText : null,
        associatedWithId,
        paginationState.activePage - 1,
        paginationState.itemsPerPage,
        paginationState.sort,
        paginationState.order
      );
    }
    if (result) {
      setShowAssociationRemove(false);
    }
  };
  const removeAssociation = id => {
    setShowAssociationRemove(true);
    setAssociationId(id);
  };
  const handleGoBack = () => {
    const state = { showTab: props.match.params.association };
    props.reset();
    props.history.push(
      `/admin-association-management?searchValue=${props.match.params.pSearch}&page=${props.match.params.pPage}&sort=${props.match.params.pSort},${props.match.params.pOrder}`,
      state
    );
  };
  const handleGoForward = () => {
    const state = { showTab: props.match.params.association };
    props.reset();
    props.history.push(
      `/admin-association-management/${props.match.params.association}/${entity?.id}/edit?searchValue=${props.match.params.pSearch}&page=${props.match.params.pPage}&sort=${props.match.params.pSort},${props.match.params.pOrder}`,
      state
    );
  };

  const handleSearchButton = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    setIsNewSearch(true);
  };

  const handleSearch = e => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(e.target.value);
      !isNewSearch && handleSearchButton();
    }
  };
  useLayoutEffect(() => {
    if (!entity) {
      return;
    }
    setEntityKeys(Object.keys(entity));
  }, [entity]);

  function formatDuration(seconds) {
    if (!seconds) return '--';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }



  
  



  return entity && entity?.id ? (
    <div className="row">
      <Col md="12">
        {/* <h2>
          VideoMetaData [<b>{videoMetaDataEntity.id}</b>]
        </h2> */}
        <div className="detail-page-heading">
          <div className="detail-back-btn">
            <button onClick={handleGoBack}>
              <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
            </button>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
          <div className="common-btn" style={{ width: '112px', paddingLeft: 18 }}>
            <NavLink
              //  tag={Link}
              //   to={`/admin-association-management/${props.match.params.association}/${entity?.id}/edit`}
              onClick={handleGoForward}
            >
              <span className="header-title">Edit</span>
            </NavLink>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Title
              </p>
              <p className="detail-txt">{entity?.title}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className="detail-lable-txt">
                Id
              </p>
              <p className="detail-txt">{entity?.id}</p>
            </div>
          </div>

          {entityKeys&&entityKeys.length > 0 &&
            entityKeys
              .filter(key => !avoidKeys.includes(key))
              .map(key => {
                return (
                  <div className="col-md-4 bottom-border" key={key}>
                    <div className="d-flex">
                      <p className="detail-lable-txt">{keyTitles[key]} </p>
                      <p className="detail-txt">{entity[key] ? entity[key] : '--'}</p>
                    </div>
                  </div>
                );
              })}

          <div className="col-md-4 ">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Thumbnail
              </p>
              {entity?.thumbnailImageUrl ? (
                <img src={entity.thumbnailImageUrl} style={{ maxWidth: 180, maxHeight: 150 }} key={entity?.id}></img>
              ) : (
                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
              )}
            </div>
          </div>

          <div className="col-md-8 bottom-border">
            {entity?.contentUrl
              ? entity.contentUrl && (
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyItems: 'space-around', alignItems: 'start' }}>
                    <div style={{ maxWidth: 300 }}>
                      <PDFDownload filePath={entity.contentUrl} />
                    </div>
                    <div style={{ width: '100%' }}>
                      <ViewDocument
                        content={{ type: entity.contentUrl.slice(entity.contentUrl.lastIndexOf('.') + 1), url: entity.contentUrl }}
                      />
                    </div>
                  </div>
                )
              : null}
          </div>
        </div>
      </Col>
      <Col>
        <div className="main-container">
          <div className="page-title-btn-container">
            <Row className="justify-content-center">
              <Col md="12">
                <div className="detail-page-heading">
                  <div className="detail-back-btn">
                    <h2 style={{ color: 'rgb(114 108 108)' }}>Associations </h2>
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <div className="float-left">
                <div className="search-container">
                  <input
                    type="search"
                    id="search-input"
                    className="form-control search-input"
                    onChange={e => handleSearch(e)}
                    placeholder="Search by title"
                    aria-label="Search"
                  />
                  <button id="search-button" onClick={() => handleSearchButton()}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
            <Tab
              label="Videos"
              value={Tablist.video}
              className={`${classes.tab} ${activeTab === Tablist.video ? classes.activeTab : ''}`}
            />
            <Tab
              label="Playlist"
              value={Tablist.playlist}
              className={`${classes.tab} ${activeTab === Tablist.playlist ? classes.activeTab : ''}`}
            />
            <Tab
              label="Track"
              value={Tablist.track}
              className={`${classes.tab} ${activeTab === Tablist.track ? classes.activeTab : ''}`}
            />
          </Tabs>
          <div className="table-responsive">
            <div className="table-responsive">
              {entities && activeTab === 'video' && show && !isEntityLoading && (
                <>
                  {' '}
                  {entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                          <th className="hand" onClick={sort('serialNumber')}>
                            Sr No
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('videoTitle')}>
                            Video Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('language')}>
                            Language
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand">
                            Status
                            <span style={{ float: 'right' }}> </span>
                          </th>
                          <th className="hand">
                            Action
                            <span style={{ float: 'right' }}> </span>
                          </th>

                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((videoMetaData, i) => (
                          <tr key={`entity-${i}`}>
                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75 }}>
                              {videoMetaData?.serialNumber ? videoMetaData.serialNumber : '--'}
                            </td>

                            <td>{videoMetaData?.videoTitle ? videoMetaData.videoTitle : '--'}</td>

                            <td>{videoMetaData?.language ? videoMetaData.language : '--'}</td>

                            <td>{videoMetaData?.status ? videoMetaData?.status : '--'}</td>
                            <td>
                              <div className="btn-group flex-btn-group-container">
                                <button onClick={() => removeAssociation(videoMetaData.id)}>
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </button>
                              </div>
                            </td>
                            {/* <td /> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No video Data found</div>
                  )}
                </>
              )}
              {entities && activeTab === 'playlist' && show && !isEntityLoading && (
                <>
                  {entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                          <th className="hand">Thumbnail</th>
                          <th className="hand">
                            Playlist Title
                            {/* <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span> */}
                          </th>
                          <th className="hand">Video Count</th>
                          <th className="hand">Audio</th>

                          <th className="hand">E-book Count</th>
                          <th className="hand">Pad Count</th>
                          <th className="hand">Patra Count</th>

                          <th className="hand">
                            Status
                            <span style={{ float: 'right' }}></span>
                          </th>
                          <th className="hand">
                            Action
                            <span style={{ float: 'right' }}></span>
                          </th>
                          {/* <th /> */}
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((playlistMetaData, i) => (
                          <tr key={`entity-${i}`}>
                            <td>
                              {playlistMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {playlistMetaData.thumbnail ? (
                                    <img
                                      src={getImageUrl(playlistMetaData)}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={playlistMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>

                            <td>{playlistMetaData?.playlistName ? playlistMetaData.playlistName : '--'}</td>
                            <td>{playlistMetaData?.noOfVideos ? playlistMetaData.noOfVideos : '--'}</td>
                            <td>{playlistMetaData?.noOfaudios ? playlistMetaData.noOfaudios : '--'}</td>
                            <td>{playlistMetaData?.noOfEbooks ? playlistMetaData.noOfEbooks : '--'}</td>
                            <td>{playlistMetaData?.noOfPads ? playlistMetaData.noOfPads : '--'}</td>
                            <td>{playlistMetaData?.noOfPatras ? playlistMetaData.noOfPatras : '--'}</td>

                            <td>{playlistMetaData?.status ? playlistMetaData?.status : '--'}</td>
                            <td>
                              <div className="btn-group flex-btn-group-container">
                                <button onClick={() => removeAssociation(playlistMetaData.id)}>
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </button>
                              </div>
                            </td>
                            {/* <td /> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Playlist data available</div>
                  )}
                </>
              )}


              {entities && activeTab === 'track' && show && !isEntityLoading && (
                <>
                  {/* {entities['content']?.length > 0  ? ( */}
                  {entities?.length > 0  ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                            <th className="hand" >
                            Thumbnail
                            {/* thumbnailUrl */}
                            <span style={{ float: 'right' }}>
                              {' '}
                            </span>
                          </th>
                          <th className="hand" 
                          // onClick={sort('track_id')}
                          onClick={sort('id')}
                          >
                            Track ID
                            {/* trackId */}
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>
                          <th>
                            Album Name
                            {/* Album name */}
                          </th>
                          <th className="hand" onClick={sort('title')}>
                            Track Name
                            {/* title */}
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>
                          <th className="hand" 
                          // onClick={sort('play_count')}
                          >
                            Listen Count
                            {/* playCount */}
                            {/* <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span> */}
                          </th>


                          <th className="hand" onClick={sort('publishedDate')}>
                            Published Date
                            {/* publishedDate */}
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" 
                          // onClick={sort('duration')}
                          >
                            Duration
                            {/* duration */}
                            {/* <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span> */}
                          </th>

                          {/* Delete action */}
                          <th className="hand">
                            Action
                            <span style={{ float: 'right' }}></span>
                          </th>
                 
                        </tr>
                      </thead>
                      <tbody>


                        {/* {entities['content']?.map((trackData, i) => ( */}
                        {entities?.map((trackData, i) => (
                          <tr key={`entity-${i}`}>
                              {/* Thumbnail */}
                        <td>
                          {trackData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {trackData.thumbnailUrl ? (
                                <img src={trackData.thumbnailUrl} style={{ maxWidth: 70, maxHeight: 50 }} key={trackData.id}></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        {/* trackId */}
                        <td>{trackData?.trackId ? trackData.trackId : '--'}</td>

                        {/* Allbum name */}
                        <td>{trackData?.audioAlbum?.title ? trackData?.audioAlbum?.title : '--'}</td>

                        {/* title */}
                        <td>{trackData?.title ? trackData.title : '--'}</td>

                        {/* playCount */}
                        <td>{trackData?.playCount === 0 ? '--' : trackData?.playCount || '--'} </td>

                        {/* likeCount */}
                        {/* <td>{trackData?.likeCount ? trackData.likeCount : '--'}</td> */}

                        {/* publishedDate */}
                        <td>{trackData?.publishedDate ? trackData.publishedDate?.toString().substring(0, 10) : '--'}</td>

                        {/* Duration */}
                        <td>{trackData?.duration ? formatDuration(trackData.duration) : '--'}</td>

        
                            <td>
                              <div className="btn-group flex-btn-group-container">
                                <button onClick={() => removeAssociation(trackData.id)}>
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </button>
                              </div>
                            </td>
                            {/* <td /> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Track data available</div>
                  )}
                </>
              )}



            </div>{' '}
            {totalItems ? (
              <div className={`asso-table ${entities && entities.length > 0 && show && !isEntityLoading ? null : 'd-none'}`}>
                <Row className="justify-content-center">
                  <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
                </Row>
                <Row className="justify-content-center">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalItems}
                  />
                </Row>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Col>
      <Modal isOpen={showAssociationRemove} >
        <ModalHeader >Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseRemoveModal}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="jhi-confirm-delete-videoMetaData" disabled={updating} color="danger" onClick={confirmRemoveAssociation}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : null;
};

const mapStateToProps = ({ adminAssociationManagement }: IRootState) => ({
  entity: adminAssociationManagement.entity,
  loading: adminAssociationManagement.loading,
  entities: adminAssociationManagement.entities,
  totalItems: adminAssociationManagement.totalItems,
  isEntityLoading: adminAssociationManagement.isEntityLoad,
  updating: adminAssociationManagement.updating,
});

const mapDispatchToProps = {
  reset,
  getEntity,
  getAssociatedPlaylists,
  removePlaylistAssociation,
  getAssociatedVideos,
  getAssociatedTracks,
  removeVideoAssociation,
  removeTrackAssociation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminAssociationManagementDetails);
