import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILiveVideo, IQuestion, defaultValue } from 'app/shared/model/live-video.model';
import { toast } from 'react-toastify';

export const ACTION_TYPES = {
  FETCH_LIVE_VIDEO_LIST: 'liveVideo/FETCH_LIVE_VIDEO_LIST',
  FETCH_LIVE_VIDEO_TITLE: 'liveVideo/FETCH_LIVE_VIDEO_TITLE',
  // FETCH_SUBSCRIPTIONPLAN: 'subscriptionPlan/FETCH_SUBSCRIPTIONPLAN',
  CREATE_LIVE_VIDEO_LIST: 'liveVideo/CREATE_LIVE_VIDEO_LIST:',
  UPDATE_LIVE_VIDEO_LIST: 'liveVideo/UPDATE_LIVE_VIDEO_LIST',
  GET_LIVE_VIDEO_LIST: 'liveVideo/GET_LIVE_VIDEO_LIST',
  GET_LIVE_VIDEO_QUESTIONS: 'liveVideo/GET_LIVE_VIDEO_QUESTIONS',
  GET_LIVE_VIDEO_COMMENTS: 'liveVideo/GET_LIVE_VIDEO_COMMENTS',
  EDIT_MESSAGE: 'liveVideo/EDIT_MESSAGE',
  DELETE_MESSAGE: 'liveVideo/DELETE_MESSAGE',
  FETCH_YOUTUBELIVEVIDEO: 'liveVideo/FETCH_YOUTUBELIVEVIDEO',
  CREATE_LIVEYOUTUBE_VIDEO: 'liveVideo/CREATE_LIVEYOUTUBE_VIDEO',
  UPDATE_LIVEYOUTUBE_VIDEO: 'liveVideo/UPDATE_LIVEYOUTUBE_VIDEO',
  GET_LIVE_VID_ATTACHMENTS: 'liveVideo/GET_LIVE_VID_ATTACHMENTS',
  DELETE_LIVEVID_ASSOCIATION: 'liveVideo/DELETE_LIVEVID_ASSOCIATION',
  // DELETE_SUBSCRIPTIONPLAN: 'subscriptionPlan/DELETE_SUBSCRIPTIONPLAN',
  RESET: 'liveVideo/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILiveVideo>,
  liveVideo: defaultValue,
  totalItems: 0,
  updateSuccess: false,
  updating: false,
  liveVideoTitle: defaultValue,
  questions: [] as ReadonlyArray<IQuestion>,
  comments: [] as ReadonlyArray<IQuestion>,
  entity: defaultValue,
};

export type LiveVideoState = Readonly<typeof initialState>;

// Reducer

export default (state: LiveVideoState = initialState, action): LiveVideoState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIVE_VIDEO_LIST):
    case REQUEST(ACTION_TYPES.FETCH_LIVE_VIDEO_TITLE):
    case REQUEST(ACTION_TYPES.GET_LIVE_VIDEO_QUESTIONS):
    case REQUEST(ACTION_TYPES.FETCH_YOUTUBELIVEVIDEO):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_LIVE_VIDEO_LIST):
    case REQUEST(ACTION_TYPES.GET_LIVE_VIDEO_LIST):
    case REQUEST(ACTION_TYPES.EDIT_MESSAGE):
    case REQUEST(ACTION_TYPES.DELETE_MESSAGE):
    case REQUEST(ACTION_TYPES.DELETE_LIVEVID_ASSOCIATION):
    case REQUEST(ACTION_TYPES.GET_LIVE_VID_ATTACHMENTS):

    case REQUEST(ACTION_TYPES.UPDATE_LIVE_VIDEO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_LIVE_VIDEO_TITLE):
    case FAILURE(ACTION_TYPES.FETCH_LIVE_VIDEO_LIST):
    case FAILURE(ACTION_TYPES.GET_LIVE_VIDEO_QUESTIONS):
    case FAILURE(ACTION_TYPES.CREATE_LIVE_VIDEO_LIST):
    case FAILURE(ACTION_TYPES.GET_LIVE_VIDEO_LIST):
    case FAILURE(ACTION_TYPES.EDIT_MESSAGE):
    case FAILURE(ACTION_TYPES.DELETE_MESSAGE):
    case FAILURE(ACTION_TYPES.DELETE_LIVEVID_ASSOCIATION):
    case FAILURE(ACTION_TYPES.UPDATE_LIVE_VIDEO_LIST):
    case FAILURE(ACTION_TYPES.FETCH_YOUTUBELIVEVIDEO):
    case FAILURE(ACTION_TYPES.GET_LIVE_VID_ATTACHMENTS):

    case FAILURE(ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.EDIT_MESSAGE):
    case SUCCESS(ACTION_TYPES.DELETE_MESSAGE):
    case SUCCESS(ACTION_TYPES.DELETE_LIVEVID_ASSOCIATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_LIVE_VIDEO_TITLE):
      return {
        ...state,
        loading: false,
        updating: false,

        updateSuccess: true,
        liveVideoTitle: action.payload.data,
        // liveVideo:defaultValue
      };
    case SUCCESS(ACTION_TYPES.CREATE_LIVE_VIDEO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_LIVE_VIDEO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entities: [action.payload.data],
        // liveVideo:defaultValue
      };
    case SUCCESS(ACTION_TYPES.GET_LIVE_VID_ATTACHMENTS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        loading: false,
        entities: action.payload.data,
        // liveVideo:defaultValue
      };
    // case SUCCESS(ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO):
    //   return {
    //     ...state,
    //     updating: false,
    //     updateSuccess: true,
    //     entity: action.payload.data,
    //     // liveVideo:defaultValue
    //   };

    case SUCCESS(ACTION_TYPES.FETCH_YOUTUBELIVEVIDEO):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
        entity: action.payload.data,
        // liveVideo:defaultValue
      };

    case SUCCESS(ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        loading: false,
        entity: defaultValue,
        // liveVideo:defaultValue
      };

    case SUCCESS(ACTION_TYPES.FETCH_LIVE_VIDEO_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.GET_LIVE_VIDEO_QUESTIONS):
      return {
        ...state,
        loading: false,
        questions: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.GET_LIVE_VIDEO_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/youtube-live-videos';

// Actions

export const getLiveVideo = (page, size, sort, order, searchValue, active) => {
  const inactiveUrl = `${apiUrl}/inactive?searchValue=${searchValue}&page=${page}&size=${size}&sort=${sort},${order}`;
  return {
    type: ACTION_TYPES.FETCH_LIVE_VIDEO_LIST,
    payload: axios.get(active ? apiUrl : inactiveUrl),
  };
};
export const editMessage: ICrudPutAction<IQuestion> = (params, editType) => async dispatch => {
  const questionUrl = 'api/live/video/Question/Edit';
  const commentUrl = 'api/live/video/QuestionComment/Edit';

  const result = await dispatch({
    type: ACTION_TYPES.EDIT_MESSAGE,
    payload: axios.put(editType == 'question' ? questionUrl : commentUrl, params),
  });
  if (result.value.status == 200) {
    toast.success('Successfully edited !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success('Something went wrong  !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result.value.status;
};
export const deleteMessage: ICrudPutAction<IQuestion> = (params, editType) => async dispatch => {
  const questionUrl = `api/live/video/Question/Delete`;
  const commentUrl = `api/live/video/Comment/Delete`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MESSAGE,
    payload: axios.post(editType == 'question' ? questionUrl : commentUrl, params),
  });
  if (result.value.status == 200) {
    toast.success('Deleted successfully !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success('Something went wrong  !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result.value.status;
};
export const deleteLiveVidAssociation: ICrudDeleteAction<IQuestion> = id => async dispatch => {
  const deleteUrl = `api/youtube-live-videos/single-attachment/delete/${id}`;

  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LIVEVID_ASSOCIATION,
    payload: axios.delete(deleteUrl),
  });
  if (result.value.status == 200) {
    toast.success('Deleted successfully !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success('Something went wrong  !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return result.value.status;
};
export const deleteLiveVid: ICrudDeleteAction<IQuestion> = id => async dispatch => {
  const deleteUrl = `api/youtube-live-videos/delete/${id}`;

  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LIVEVID_ASSOCIATION,
    payload: axios.delete(deleteUrl),
  });
  if (result.value.status == 200) {
    toast.success('Deleted successfully !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success('Something went wrong  !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return result;
};
export const getLiveVideoTitle = youtubelink => ({
  type: ACTION_TYPES.FETCH_LIVE_VIDEO_TITLE,

  payload: axios.get(`api/video-meta-data-title/{youtubelink}?youtubelink=${youtubelink}`),
});

export const createLiveVideo = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO,
    payload: axios.post(apiUrl, entity),
  });
  // dispatch(getLiveVideo());
  // dispatch(getLiveVideoTitle());
  if (result.value.status == 200) {
    toast.success('Created successfully');
    return result.value.data;
  }
};
export const saveAttachment = entity => async dispatch => {
  const saveUrl = 'api/youtube-live-videos/attachment';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO,
    payload: axios.post(saveUrl, entity),
  });
  // dispatch(getLiveVideo());
  // dispatch(getLiveVideoTitle());
  if (result.value.status == 200) {
    toast.success(result.value.data.message);

    return result.value.data;
  } else {
    toast.error(result.value.data.message);
  }
};
export const updateAttacments = entity => async dispatch => {
  const updateUrl = 'api/youtube-live-videos/single-attachment/update';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO,
    payload: axios.put(updateUrl, entity),
  });
  // dispatch(getLiveVideo());
  // dispatch(getLiveVideoTitle());
  if (result.value.status == 200) {
    toast.success(result.value.data.message);

    return result.value.data;
  } else {
    toast.error(result.value.data.message);
  }
};

export const getAllQuestion = entity => async dispatch => {
  const methodUrl = 'api/live/video/getQuestionAll';
  const result = await dispatch({
    type: ACTION_TYPES.GET_LIVE_VIDEO_QUESTIONS,
    payload: axios.post(methodUrl, entity),
  });
  return result;
};
export const getComments = entity => async dispatch => {
  const methodUrl = 'api/live/video/getAllComments';
  const result = await dispatch({
    type: ACTION_TYPES.GET_LIVE_VIDEO_QUESTIONS,
    payload: axios.post(methodUrl, entity),
  });
  return result;
};

export const updateLiveVideo = (params, page, sort, order, search, size, active) => async dispatch => {
  const requestUrl = `${apiUrl}/changeStatus/${params.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIVE_VIDEO_LIST,
    payload: axios.post(requestUrl, params),
  });
  // dispatch(getLiveVideo());

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getLiveVideo(page, size, sort, order, search, active));
    return result.value.data;
  }
};
export const updateLiveVideoEntity = params => async dispatch => {
  const requestUrl = `api/youtube-live-videos`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIVEYOUTUBE_VIDEO,
    payload: axios.put(requestUrl, params),
  });

  return result;
};

export const getEntity = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_YOUTUBELIVEVIDEO,
    payload: axios.get(requestUrl),
  };
};
export const getLiveVidAssociationList = id => {
  const requestUrl = `api/youtube-live-videos/attachment/${id}`;
  return {
    type: ACTION_TYPES.GET_LIVE_VID_ATTACHMENTS,
    payload: axios.get(requestUrl),
  };
};

export const getLiveVidAssociation = id => {
  const requestUrl = `api/youtube-live-videos/single-attachment/${id}`;
  return {
    type: ACTION_TYPES.FETCH_YOUTUBELIVEVIDEO,
    payload: axios.get(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
