import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ICrudGetAction, getSortState } from 'react-jhipster';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './video-meta-data.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IVideoMetaDataDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const VideoMetaDataDeleteDialog = (props: IVideoMetaDataDeleteDialogProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const handleClose = () => {
    props.history.push('/video-meta-data' + props.location.search);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const confirmDelete = () => {
    props.deleteEntity(
      props.videoMetaDataEntity.id,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort === 'id' ? 'serialNumber' : paginationState.sort},${paginationState.order}`
    );
  };

  const { videoMetaDataEntity,isLoading } = props;
  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
      <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this VideoMetaData?</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp; Cancel
        </Button>
        <Button id="jhi-confirm-delete-videoMetaData" disabled={isLoading} color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp; Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ videoMetaData }: IRootState) => ({
  videoMetaDataEntity: videoMetaData.entity,
  updateSuccess: videoMetaData.updateSuccess,
  isLoading:videoMetaData.updating
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoMetaDataDeleteDialog);
