import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';

import { Button, NavLink, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { getEntity, updateEntity, createEntity, updateSubImage, reset } from './subscription-plan.reducer';
import { deleteEntity } from '../subscription-plan-specification/subscription-plan-specification.reducer';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import FormUpload from './subscription-file-upload';
import { SERVER_API_URL } from 'app/config/constants';

export interface ISubscriptionPlanUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const SubscriptionPlanUpdate = (props: ISubscriptionPlanUpdateProps) => {
  const { subscriptionPlanEntity, statuses, loading, updating } = props;
  const [file, setFile] = useState<any>([]);
  const [fileShow, setFileShow] = useState(null);
  const [isPreview, setPreview] = useState(subscriptionPlanEntity?.subscriptionLogo ? subscriptionPlanEntity?.subscriptionLogo : false);
  const [specificationName, setSpecificationName] = useState('');
  const [arr, setArr] = useState(subscriptionPlanEntity?.specifications ? subscriptionPlanEntity?.specifications : []);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [benifitsDeleted, setBenifitsDeleted] = useState([]);
  // const [subscriptionName, setSubscriptionName] = useState('');

  // const handleChangeSubscriptionName = event => {
  //   const name = event.target.value.toUpperCase();

  //   setSubscriptionName(name);
  // };

  const addSpecificationName = () => {
    // eslint-disable-next-line no-empty
    if (specificationName === '') {
    } else {
      setArr([
        ...arr,
        // ...subscriptionPlanEntity.specifications,
        {
          createdOn: '2022-10-21',
          id: null,
          specificationName: specificationName,
        },
      ]);
      setSpecificationName('');
    }
  };

  const deleteTask = e => {
    const updatedData = arr.filter(element => {
      return element !== e;
    });
    if (e.id) {
      // props.deleteEntity(e.id);
      const deletedData = benifitsDeleted;
      const data = arr.filter(element => {
        return element == e;
      });
      deletedData.push(data[0]);
      setBenifitsDeleted(deletedData);
    }
    setArr(updatedData);
  };

  const handleClose = () => {
    props.history.push('/subscription-plan');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getStatuses();
  }, []);

  useEffect(() => {
    if (!loading) {
      setArr(subscriptionPlanEntity?.specifications ? subscriptionPlanEntity?.specifications : []);
    }
  }, [loading]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = async (event, errors, values) => {
    values.subscriptionLogo = fileShow ? fileShow.split(',')[1] : subscriptionPlanEntity.subscriptionLogo;
    const array = {
      // id:values.id=null,
      price: values.price,
      specifications: values.specifications = arr,
      statuses: {
        id: values.statuses.id,
        statusValue: values.statuses.statusValue,
      },
      subscriptionLevel: values.subscriptionLevel,
      // subscriptionLogo: fileShow ? values.subscriptionLogo : subscriptionPlanEntity.subscriptionLogo,
      // subscriptionLogoContentType: fileShow ? fileShow.split(';')[0] : subscriptionPlanEntity.subscriptionLogoContentType,
      subscriptionName: values.subscriptionName,
    };
    if (errors.length === 0) {
      const entity = {
        ...subscriptionPlanEntity,
        ...array,
      };

      const formData = new FormData();
      formData.append('file', file)

      if (isNew) {
        props.createEntity(entity);
      } else {
        benifitsDeleted?.map((item) => {
          return props.deleteEntity(item.id);
        })
        const result = await props.updateEntity(entity);
        if (result) {
          if (file?.name) {
            props.updateSubImage(result.id, formData)
          }
        }
      }
    }
  };

  const convertBase64 = files => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result);
        setFileShow(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const uploadSingleFile = e => {
    if (e?.target?.files[0]) {
      setPreview(true);
      setFile(e?.target?.files[0]);
      convertBase64(e.target.files[0]);
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="back-arrow" style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
            <NavLink tag={Link} to="/subscription-plan">
              <FontAwesomeIcon icon="arrow-left" style={{ width: 25, height: 25 }} />
            </NavLink>
            <h2 id="satshrutApp.subscriptionPlan.home.createOrEditLabel" style={{ color: 'rgb(114 108 108)' }}>
              {' '}
              {isNew ? 'Create' : 'Edit'} Subscription Plan
            </h2>
          </div>
        </Col>
      </Row>
      <div className="row main-container">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : subscriptionPlanEntity} onSubmit={saveEntity}>
              <div className="row">
                <div className="col-md-6">
                  <AvGroup>
                    <Label id="subscriptionLevelLabel" for="subscription-plan-subscriptionLevel" className="label-font">
                      Subscription Level
                    </Label>
                    <AvField
                      id="subscription-plan-subscriptionLevel"
                      type="string"
                      className="form-control input-font"
                      name="subscriptionLevel"
                      disabled
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label id="subscriptionNameLabel" for="subscription-plan-subscriptionName" className="label-font">
                      Subscription Name
                    </Label>
                    {subscriptionPlanEntity?.subscriptionName === 'FREE' ? (
                      <AvField
                        id="subscription-plan-subscriptionName"
                        className="form-control input-font"
                        type="text"
                        name="subscriptionName"
                        // value={subscriptionName}
                        // onChange={handleChangeSubscriptionName}
                        disabled
                      />
                    ) : (
                      <AvField
                        id="subscription-plan-subscriptionName"
                        className="form-control input-font"
                        type="text"
                        name="subscriptionName"
                      // value={subscriptionName}
                      // onChange={handleChangeSubscriptionName}
                      />
                    )}
                  </AvGroup>
                  <AvGroup>
                    <Label id="subscriptionFeature" for="subscription-plan-feature" className="label-font">
                      Benefit Description
                    </Label>
                    <div className="row">
                      <div className="col-md-10">
                        <AvField
                          type="string"
                          className="form-control input-font"
                          name="specificationName"
                          value={specificationName}
                          onChange={e => setSpecificationName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-2">
                        <Button
                          className="add-btn"
                          style={{
                            width: 100,
                            // marginTop: 0,
                            marginLeft: -10,
                          }}
                          onClick={e => addSpecificationName()}
                        >
                          <span className="header-title"> Add</span>
                        </Button>
                      </div>
                    </div>
                    {/* ))}         */}
                    <table className="container">
                      {arr.map((features, index) => {
                        return (
                          <tr key={index} className="row" style={{ margin: 0, marginTop: 15 }}>
                            <td className="col-md-1">
                              {index + 1}
                              {'.'}
                            </td>
                            <td className="col-md-9">{features.specificationName}</td>
                            <td className="col-md-1">
                              <NavLink onClick={() => deleteTask(features)}>
                                <FontAwesomeIcon
                                  icon="trash"
                                  style={{
                                    height: ' 23px',
                                    width: '18px',
                                    // marginBottom: 2,
                                    color: '#db261d',
                                  }}
                                  data-toggle="tooltip"
                                  title="Delete"
                                />
                              </NavLink>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvGroup>
                    <Label id="priceLabel" for="subscription-plan-price" className="label-font">
                      Price
                    </Label>
                    {/* <AvField id="subscription-plan-price" type="string" className="form-control input-font" name="price" disabled/> */}
                    {subscriptionPlanEntity?.subscriptionName === 'FREE' ? (
                      <AvField id="subscription-plan-price" type="string" className="form-control input-font" name="price" disabled />
                    ) : (
                      <AvField id="subscription-plan-price" type="string" className="form-control input-font" name="price" />
                    )}
                  </AvGroup>
                  <AvGroup>
                    <Label for="subscription-plan-statuses" className="label-font">
                      Status
                    </Label>
                    <AvInput id="subscription-plan-statuses" type="select" className="form-control input-font" name="statuses.id">
                      <option value="" key="0" />
                      {statuses
                        ? statuses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.statusValue}
                          </option>
                        ))
                        : null}
                    </AvInput>
                  </AvGroup>
                  <div className="image-container">
                    <FormUpload inlineLabel={false} value={null} style={{ width: 150, height: 150 }} uploadSingleFile={uploadSingleFile} />
                    {fileShow ? (
                      <FormUpload
                        isPreview={isPreview}
                        inlineLabel={false}
                        value={fileShow}
                        style={{ width: 150, height: 150 }}
                        uploadSingleFile={uploadSingleFile}
                      />
                    ) : (
                      <>
                        {subscriptionPlanEntity?.imagePath && (
                          <img
                            src={`${SERVER_API_URL}/api/getPlanLogo/${subscriptionPlanEntity.id}`}
                            style={{ width: 150, height: 150, marginTop: 20 }}
                          ></img>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <button
                disabled={updating}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{
                  width: 200,
                  marginTop: 20,
                }}
              >
                <span className="header-title">Save</span>
              </button>
            </AvForm>
          )}
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  statuses: storeState.statuses.entities,
  subscriptionPlanEntity: storeState.subscriptionPlan.entity,
  loading: storeState.subscriptionPlan.loading,
  updating: storeState.subscriptionPlan.updating,
  updateSuccess: storeState.subscriptionPlan.updateSuccess,
});

const mapDispatchToProps = {
  getStatuses,
  getEntity,
  updateEntity,
  createEntity,
  deleteEntity,
  updateSubImage,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanUpdate);
