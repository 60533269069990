import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, Storage } from 'react-jhipster';
import { IVideoMetaData, defaultValue } from 'app/shared/model/video-meta-data.model';

export const ACTION_TYPES = {
  UPDATE_PASSWORD: 'account/UPDATE_PASSWORD',
  RESET: 'account/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
};

export type PasswordState = Readonly<typeof initialState>;

// Reducer
export default (state: PasswordState = initialState, action): PasswordState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
const apiUrl = 'api/account';

// export const savePassword = (currentPassword, newPassword) => ({
//   type: ACTION_TYPES.UPDATE_PASSWORD,
//   payload: axios.post(`${apiUrl}/change-password`, { currentPassword, newPassword }),
//   meta: {
//     successMessage: 'Password changed!',
//     errorMessage: 'An error has occurred! The password could not be changed.',
//   },
// });

export const savePassword: ICrudPutAction<IVideoMetaData> = params => async dispatch => {
  console.log('params: ', params);
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PASSWORD,
    payload: axios.post(`${apiUrl}/change-password`, params),
    meta: {
      successMessage: 'Password changed!',
      errorMessage: 'An error has occurred! The password could not be changed.',
    },
  });
  return result.value.status;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
