import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, NavLink, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './subscription-plan.reducer';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISubscriptionPlanDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const SubscriptionPlanDetail = (props: ISubscriptionPlanDetailProps) => {
  const { subscriptionPlanEntity } = props;
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);
// console.log("subscriptionPlanEntity.subscription_logo_content_type",subscriptionPlanEntity.);

  // const array=[{...subscriptionPlanEntity.specifications}]

  return (
    <div className="row">
      <Col md="12">
        <div className="detail-page-heading">
          <div className="detail-back-btn">
            <NavLink tag={Link} to="/subscription-plan">
              <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
            </NavLink>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
          <div>
            <div className="common-btn" style={{ width: '112px', paddingLeft: 18 }}>
              <NavLink tag={Link} to={`/subscription-plan/${subscriptionPlanEntity.id}/edit`}>
                <span className="header-title">Edit</span>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="subscriptionLevel" className="detail-lable-txt">
                Subscription Level
              </p>
              <p className="detail-txt">{subscriptionPlanEntity.subscriptionLevel}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" subscriptionName" className="detail-lable-txt">
                Subscription Name
              </p>
              <p className="detail-txt">{subscriptionPlanEntity.subscriptionName}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" price" className="detail-lable-txt">
                Price
              </p>
              <p className="detail-txt">{subscriptionPlanEntity.price}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" statuses" className="detail-lable-txt">
                Status
              </p>
              <p className="detail-txt">{subscriptionPlanEntity.statuses ? subscriptionPlanEntity.statuses.statusValue : ''}</p>
            </div>
          </div>
        </div>
        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div>
              <p id=" subscriptionFeature" className="detail-lable-txt">
                Benifit
              </p>
            </div>
            {subscriptionPlanEntity?.specifications &&
              subscriptionPlanEntity?.specifications.map((item, index) => {
                return (
                  <p className="detail-txt">
                    {index + 1} {'.'} {item.specificationName}
                  </p>
                  // <Row>
                  //   <Col className="detail-txt">{index + 1}</Col>
                  //   <Col className="detail-txt">{item.specificationName}</Col>
                  // </Row>
                );
              })}
          </div>
          {subscriptionPlanEntity.subscriptionLogo && (
            <div className="col-md-4 bottom-border">
              <div>
                <p className="detail-lable-txt">Subscription Image</p>
                {subscriptionPlanEntity?.subscriptionLogo &&
                          <img
                            src={'data:image/png;base64,' + subscriptionPlanEntity.subscriptionLogo}
                            alt={subscriptionPlanEntity.subscription_logo_content_type}
                            style={{ width: 150, height: 150 }}
                          ></img>
                        }
              </div>
            </div>
          )}
        </div>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ subscriptionPlan }: IRootState) => ({
  subscriptionPlanEntity: subscriptionPlan.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanDetail);