import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IVideoViewHistory, defaultValue } from 'app/shared/model/video-view-history.model';

export const ACTION_TYPES = {
  FETCH_VIDEOVIEWHISTORY_LIST: 'videoViewHistory/FETCH_VIDEOVIEWHISTORY_LIST',
  FETCH_VIDEOVIEWHISTORY: 'videoViewHistory/FETCH_VIDEOVIEWHISTORY',
  CREATE_VIDEOVIEWHISTORY: 'videoViewHistory/CREATE_VIDEOVIEWHISTORY',
  UPDATE_VIDEOVIEWHISTORY: 'videoViewHistory/UPDATE_VIDEOVIEWHISTORY',
  DELETE_VIDEOVIEWHISTORY: 'videoViewHistory/DELETE_VIDEOVIEWHISTORY',
  RESET: 'videoViewHistory/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVideoViewHistory>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type VideoViewHistoryState = Readonly<typeof initialState>;

// Reducer

export default (state: VideoViewHistoryState = initialState, action): VideoViewHistoryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VIDEOVIEWHISTORY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VIDEOVIEWHISTORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VIDEOVIEWHISTORY):
    case REQUEST(ACTION_TYPES.UPDATE_VIDEOVIEWHISTORY):
    case REQUEST(ACTION_TYPES.DELETE_VIDEOVIEWHISTORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_VIDEOVIEWHISTORY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VIDEOVIEWHISTORY):
    case FAILURE(ACTION_TYPES.CREATE_VIDEOVIEWHISTORY):
    case FAILURE(ACTION_TYPES.UPDATE_VIDEOVIEWHISTORY):
    case FAILURE(ACTION_TYPES.DELETE_VIDEOVIEWHISTORY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VIDEOVIEWHISTORY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VIDEOVIEWHISTORY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VIDEOVIEWHISTORY):
    case SUCCESS(ACTION_TYPES.UPDATE_VIDEOVIEWHISTORY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VIDEOVIEWHISTORY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/video-view-histories';

// Actions

export const getEntities: ICrudGetAllAction<IVideoViewHistory> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_VIDEOVIEWHISTORY_LIST,
  payload: axios.get<IVideoViewHistory>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IVideoViewHistory> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VIDEOVIEWHISTORY,
    payload: axios.get<IVideoViewHistory>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVideoViewHistory> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VIDEOVIEWHISTORY,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IVideoViewHistory> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VIDEOVIEWHISTORY,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IVideoViewHistory> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VIDEOVIEWHISTORY,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
