import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { IRootState } from 'app/shared/reducers';
import { getEntity, getLyrics, uploadEntity, uploadThumbnail, getTrackDetailsByTrackId, getSpeakerList, uploadArtist ,removeAssociation} from './admin-sparsh-reducer';
import 'react-toastify/dist/ReactToastify.css';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { Tabs, Tab, Box } from '@material-ui/core';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink, Row, Table, Col ,Label } from 'reactstrap';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, SERVER_API_URL } from 'app/config/constants';
import Satshrut from '../../../content/images/satshrut3.png';










export interface IAdminSparshLyricsProps extends StateProps, DispatchProps, RouteComponentProps<{ trackId: any; id?: string; association: string; url: string; liveVideoId: string; }> {}

const AdminSparshTrackEdit: React.FC<IAdminSparshLyricsProps> = (props) => {
  const { associationEntity,entities, loading, data, entity } = props;
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [lyrics, setLyrics] = useState<string | null>(null);
  const [trackDetails, setTrackDetails] = useState<any | null>(null);
  const [speakerList, setSpeakerList] = useState<{ value: string; label: string }[]>([]);
  const [speaker, setSpeaker] = useState<{ value: string; label: string } | null>(null);


  const useStyles = makeStyles((theme) => ({
    tabIndicator: {
      display: 'none',
    },
    tab: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '16px',
      backgroundColor: '#eee',
      padding: '10px 15px',
      marginRight: '4px',
      borderRadius: '4px 4px 0 0',
      borderBottom: 'none',
      zIndex: 1,
      '&:hover': {
        backgroundColor: '#ddd',
      },
    },
    activeTab: {
      backgroundColor: '#ddd',
      borderTop: `3px solid ${theme.palette.primary.main}`,
    },
    contentContainer: {
      border: '1px solid #ccc',
      borderTop: 'none',
      padding: '20px',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    },
  }));

  const classes = useStyles();




  const handleGoBack = () => props.history.goBack();


  

  const [audioContent, setAudioContent] = useState(null)
  const [eBookContent, setEBookContent] = useState(null)
  const [padContent, setPadContent] = useState(null)
  const [patraContent, setPatraContent] = useState(null)
  const [transcriptContent, setTranscriptContent] = useState(null)



  const fetchTrackDetails = async () => {
    const data = await props.getTrackDetailsByTrackId(props.match.params.trackId);
    setTrackDetails(data?.value?.data);

    setTranscriptContent(data?.value?.data?.transcriptContents);
    setPatraContent(data?.value?.data?.patraContents);
    setPadContent(data?.value?.data?.padContents);
    setEBookContent(data?.value?.data?.ebookContents);
    setAudioContent(data?.value?.data?.audioContents);



  };



  useEffect(() => {
 
    fetchTrackDetails();
  }, [props.match.params.trackId]);

  useEffect(() => {
    if (data?.data?.lyrics) {
      setLyrics(data.data.lyrics);
    }
  }, [data]);

  const convertToMinutes = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  };

  useLayoutEffect(() => {
    const fetchSpeakerList = async () => {
      const data = await getSpeakerList();
      const formattedSpeakers = data.map((speaker: string) => ({
        value: speaker,
        label: speaker,
      }));
      setSpeakerList(formattedSpeakers);
    };
    fetchSpeakerList();
  }, []);

  useEffect(() => {
    setSpeaker(speakerList.find(option => option.label === trackDetails?.artist) || null);
  }, [trackDetails, speakerList]);

  //associations

  



  enum Tablist {
    audio = 'audio',
    ebook = 'ebook',
    pad = 'pad',
    patra = 'patra',
    transcript = 'transcript'
  }

  const [searchText, setSearchText] = useState(null)
  const [isNewSearch, setIsNewSearch] = useState(false)
  const [show,setShow]=useState(false)
  const [videoPlaylistDel, setVideoPlaylistDel] = useState(false)
  const [playlistId, setPlaylistId] = useState(null)
  const [playListAssociationDel, setVideoAssociationDel] = useState(false)
  const [associationId, setAssociationId] = useState(null)


  
  const [activeTab, setActiveTab] = useState(location?.state?.showTab ? location.state.showTab : Tablist.audio);



  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const handleSearchButton = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    setIsNewSearch(true)
  }

  const handleSearch = e => {
    if (e.target.value) {
      setSearchText(e.target.value)
    }
    else {
      setSearchText(e.target.value)
      !isNewSearch && handleSearchButton()
    }
  }

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if(activeTab!=newValue){
      setShow(false)
      setActiveTab(newValue);
    }

  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const getImageUrl = (item) => {
    if (item.thumbnail) {
      const cacheBuster = imageKey ? Date.now() : '';
      return `${SERVER_API_URL}/api/system/playlist/thumbnail/${item.id}?t=${cacheBuster}`;
    }
    return Satshrut;
  };

  
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    const handleCloseVidDelModel = () => {
      setVideoPlaylistDel(false)
    };
    const handleCloseAssocnDelModel = () => {
      setVideoAssociationDel(false)
    };


  const videoPlaylistDelete = (id) => {
    setVideoPlaylistDel(true)
    setPlaylistId(id)
  };
  const videoAssociationDelete = (id) => {
    setVideoAssociationDel(true)
    setAssociationId(id)
  };




   const confirmDeleteAssociation = async() => {
    const result= await props.removeAssociation(
       associationId,
       props.match.params.trackId,
       activeTab.toUpperCase()
    )

    if(result){
     setVideoAssociationDel(false)
     fetchTrackDetails()
    }
   };
  
  
  
  
  


  return (


    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleGoBack}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Edit Track Association</h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : associationEntity}>
              <Row>
                <Col md="6">
                  <AvGroup>
                    <Label for="id" className="label-font">Track No.</Label>
                    <AvField id="id" className="input-font"  type="text" name="id" value={props.match.params.trackId} disabled />
                  </AvGroup>

                  <AvGroup>
                    <Label for="title" className="label-font">Title</Label>
                    <AvField
                      id="title"
                      className="input-font"
                      type="text"
                      name="title"
                      value={trackDetails?.title || ''}
                      disabled
                    />
                  </AvGroup>

                  <AvGroup>
                    <Label for="publishedDate" className="label-font">Published Date</Label>
                    <AvField
                      id="publishedDate"
                      type="date"
                      className="input-font"
                      name="publishedDate"
                      value={trackDetails?.publishedDate || ''}
                      disabled
                    />
                  </AvGroup>

                  <AvGroup>
                    <Label for="speaker" className="label-font">Artist</Label>
                    <AvField
                      id="speaker"
                      name="speaker"
                      type="text"
                      className="input-font"
                      value={speaker?.label || ''}
                      disabled
                    />
                  </AvGroup>

                </Col>

                <Col md="6">
                  <AvGroup>
                    <Label for="duration" className="label-font">Duration</Label>
                    <AvField id="duration" className="input-font" type="text" name="duration" value={trackDetails?.duration ? convertToMinutes(trackDetails.duration) : ''} disabled />
                  </AvGroup>

                  <AvGroup>
                    <Label for="playCount" className="label-font">Listen Count</Label>
                    <AvField id="playCount" className="input-font" type="text" name="playCount" value={trackDetails?.playCount ? trackDetails.playCount : '--'} disabled />
                  </AvGroup>

                  <AvGroup>
                    <Label for="subscriptionStatus" className="label-font">Subscription Status</Label>
                    <AvField
                      id="subscriptionStatus"
                      type="text"
                      className="input-font"
                      name="subscriptionStatus"
                      value={
                        trackDetails?.subscriptionPlan?.id === 24 ? 'Paid' :
                        trackDetails?.subscriptionPlan?.id === 1 ? 'Free' :
                        '--'
                      }
                      disabled
                    />
                  </AvGroup>
                </Col>
              </Row>
            </AvForm>
          )}
        </Col>
      </Row>


      {<Col md="12">
        <div className="main-container">
          <div className="page-title-btn-container">
            <Row className="justify-content-center">
              <Col md="12">
                <div className="detail-page-heading">
                  <div className="detail-back-btn">


                    <h2 style={{ color: 'rgb(114 108 108)' }}>Associations</h2>
                  </div>
                </div>
              </Col>
            </Row>


            {/* <div className="float-left">
              <div className="search-container">
                <input
                  type="search"
                  id="search-input"
                  className="form-control search-input"
                  onChange={e => handleSearch(e)}
                  placeholder="Search by title"
                  aria-label="Search"
                />
                <button id="search-button" onClick={() => handleSearchButton()}>Search</button>
              </div>
            </div> */}


          </div>
          <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
            <Tab label="Audio" value={Tablist.audio} className={`${classes.tab} ${activeTab === Tablist.audio ? classes.activeTab : ''}`} />
            <Tab label="E-book" value={Tablist.ebook} className={`${classes.tab} ${activeTab === Tablist.ebook ? classes.activeTab : ''}`} />
            <Tab label="Pad" value={Tablist.pad} className={`${classes.tab} ${activeTab === Tablist.pad ? classes.activeTab : ''}`} />
            <Tab label="Patra" value={Tablist.patra} className={`${classes.tab} ${activeTab === Tablist.patra ? classes.activeTab : ''}`} />

            <Tab label="Transcript" value={Tablist.transcript} className={`${classes.tab} ${activeTab === Tablist.transcript ? classes.activeTab : ''}`} />

          </Tabs>
          <div className="table-responsive">
            <div className="table-responsive">


              
              {(activeTab === 'audio') && (
                <>
                  { audioContent?.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                         
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                       

                          <th className="hand" 
                        
                          >
                            Audio Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Audio Url
                        
                          </th>
                          <th className="hand">
                            Audio Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>


                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {audioContent?.map((audioMetaData, i) => (
                          <tr key={`entity-${i}`}>
                           
                            <td>
                              {audioMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {audioMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={audioMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={audioMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                          

                            <td>{audioMetaData?.title ? audioMetaData.title : '--'}</td>
                            <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Apple Url : {audioMetaData?.contentUrlApple ? audioMetaData.contentUrlApple : '--'}</p>
                            <p>Spotify Url : {audioMetaData?.contentUrlSpotify ? audioMetaData.contentUrlSpotify : '--'}</p>
                          </div>
                        </td>
                            <td>{audioMetaData?.keywords ? audioMetaData.keywords : '--'}</td>

                            <td>{audioMetaData?.publishDate ? audioMetaData.publishDate : '--'}</td>

       
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink
                                  onClick={() => videoAssociationDelete(audioMetaData?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Audio Data found</div>
                  )}
                </>
              )}


              {(activeTab === 'ebook') && (
                <>
                  {eBookContent?.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                       
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                         
                          <th className="hand">
                            E-book Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            E-book Url
                           
                          </th>
                          <th className="hand">
                            E-book Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                     

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eBookContent?.map((ebookMetaData, i) => (
                          <tr key={`entity-${i}`}>
                          
                            <td>
                              {ebookMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {ebookMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={ebookMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={ebookMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                            

                            <td>{ebookMetaData?.title ? ebookMetaData.title : '--'}</td>
                            <td>{ebookMetaData?.contentUrl ? ebookMetaData.contentUrl : '--'}</td>
                            <td>{ebookMetaData?.keywords ? ebookMetaData.keywords : '--'}</td>

                            <td>{ebookMetaData?.publishDate ? ebookMetaData.publishDate : '--'}</td>

                         
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink

                                  onClick={() => videoAssociationDelete(ebookMetaData?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No E-book Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'pad' ) &&(
                <>
                  { padContent?.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                         
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                          

                          <th className="hand" >
                            Pad Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Pad Url
                           
                          </th>
                          <th className="hand" >
                            Pad Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                        

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {padContent?.map((padMetaData, i) => (
                          <tr key={`entity-${i}`}>
               
                            <td>
                              {padMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {padMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={padMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={padMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                            

                            <td>{padMetaData?.title ? padMetaData.title : '--'}</td>
                            <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Apple Url : {padMetaData?.contentUrlApple ? padMetaData.contentUrlApple : '--'}</p>
                            <p>Spotify Url : {padMetaData?.contentUrlSpotify ? padMetaData.contentUrlSpotify : '--'}</p>
                          </div>
                        </td>
                            <td>{padMetaData?.keywords ? padMetaData.keywords : '--'}</td>

                            <td>{padMetaData?.publishDate ? padMetaData.publishDate : '--'}</td>

                          
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink

                                  onClick={() => videoAssociationDelete(padMetaData?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Pad Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'patra') && (
                <>
                  { patraContent?.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                      
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                    

                          <th className="hand">
                            Patra Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Patra Url
                          
                          </th>
                          <th className="hand" >
                            Patra Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                        

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patraContent?.map((patraMetaData, i) => (
                          <tr key={`entity-${i}`}>
                       
                            <td>
                              {patraMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {patraMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={patraMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={patraMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                           

                            <td>{patraMetaData?.title ? patraMetaData.title : '--'}</td>
                            <td>{patraMetaData?.contentUrl ? patraMetaData.contentUrl : '--'}</td>
                            <td>{patraMetaData?.keywords ? patraMetaData.keywords : '--'}</td>

                            <td>{patraMetaData?.publishDate ? patraMetaData.publishDate : '--'}</td>

                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink

                                  onClick={() => videoAssociationDelete(patraMetaData?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No patra Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'transcript') && (
                <>
                  {transcriptContent?.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                   
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                         

                          <th className="hand" >
                            Transcript Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Transcript Url
                       
                          </th>
                          <th className="hand" >
                            Transcript Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                          <th className="hand" >
                            Satsang Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              
                            </span>
                          </th>

                        

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transcriptContent?.map((transcriptMetaData, i) => (
                          <tr key={`entity-${i}`}>
                       
                            <td>
                              {transcriptMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {transcriptMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={transcriptMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={transcriptMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                        

                            <td>{transcriptMetaData?.title ? transcriptMetaData.title : '--'}</td>
                            <td>{transcriptMetaData?.contentUrl ? transcriptMetaData.contentUrl : '--'}</td>
                            <td>{transcriptMetaData?.keywords ? transcriptMetaData.keywords : '--'}</td>

                            <td>{transcriptMetaData?.publishDate ? transcriptMetaData.publishDate : '--'}</td>

                        
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink
                                  onClick={() => videoAssociationDelete(transcriptMetaData?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Transcript Data found</div>
                  )}
                </>
              )}
            </div>{' '}

{/* 
            {props.totalItems ? (
              <div className={(show&& entities?.length > 0) ? '' : 'd-none'}>
                <Row className="justify-content-center">
                  <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
                </Row>
                <Row className="justify-content-center">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={props.totalItems}
                  />
                </Row>
              </div>
            ) : (
              ''
            )} */}
          </div>


          <Modal isOpen={videoPlaylistDel} toggle={handleCloseVidDelModel}>
            <ModalHeader toggle={handleCloseVidDelModel}>Confirm delete operation</ModalHeader>
            <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseVidDelModel}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Cancel
              </Button>
              <Button  id="jhi-confirm-delete-videoMetaData" color="danger" 
              // onClick={confirmDeletePlaylist}
              >
                <FontAwesomeIcon icon="trash" />
                &nbsp; Delete
              </Button>
            </ModalFooter>
          </Modal>


          <Modal isOpen={playListAssociationDel} toggle={handleCloseAssocnDelModel}>
            <ModalHeader toggle={handleCloseAssocnDelModel}>Confirm delete operation</ModalHeader>
            <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseAssocnDelModel}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Cancel
              </Button>
              <Button id="jhi-confirm-delete-videoMetaData" color="danger" 
              onClick={confirmDeleteAssociation}
              >
                <FontAwesomeIcon icon="trash" />
                &nbsp; Delete
              </Button>
            </ModalFooter>
          </Modal>


        </div>
      </Col>}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  associationEntity: storeState.liveVideoList.entity,
  loading: storeState.liveVideoList.loading,
  data: storeState.sparshAlbumManagement.lyricsEntity,
  entity: storeState.sparshAlbumManagement.entity,
});

const mapDispatchToProps = {
  getStatuses,
  uploadEntity,
  uploadThumbnail,
  getLyrics,
  getEntity,
  getTrackDetailsByTrackId,
  uploadArtist,
  removeAssociation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshTrackEdit);
