import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { NavLink, Row, Table, Col } from 'reactstrap';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntities } from '../admin-associations-Management/admin-associations-management.reducer';
import { addContentToEbookPlaylist,getExistingEbookPlaylistContentIds } from './admin-playlist.reducer';


import Satshrut from '../../../content/images/satshrut3.png';


export interface IAdminPlaylistEbookItemUpdateProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string; contentType: string,id:string, type:string,pSearch:string,title:string,pPage:string,pOrder:string,pSort:string }> {}

const AdminPlaylistEbookItemUpdate = (props: IAdminPlaylistEbookItemUpdateProps) => {
  const location = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE), location.search)
  );
  const [activeTab, setActiveTab] = useState(props.match.params.contentType);
  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchValue') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchValue')
  );
  const [newSearch, setNewSearch] = useState(searchText);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [selectedContentsByPage, setselectedContentsByPage] = useState<number[]>([]);
  const [existingContentsByPage, setexistingContentsByPage] = useState<number[]>([]);
  const [removedFromExisting, setRemovedFromExisting] = useState<number[]>([]);
  useEffect(() => {
    props.getExistingEbookPlaylistContentIds(props.match.params.id, props.match.params.contentType);
  }, [])
  useEffect(() => {
    setexistingContentsByPage(props.existingContentIds);
  }, [props.existingContentIds]);
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'desc' ? 'asc' : 'desc',
      sort: p,
    });
  };

  const isPaginationCorrect = (sortColumn): string => {
    switch (activeTab) {
      case 'ebook':
        if (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'patra':
        if (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }
      case 'transcript':
        if (sortColumn === 'title' || sortColumn === 'satsang_date' || sortColumn === 'keywords') {
          return sortColumn;
        }
        {
          return 'title';
        }

      default:
        return 'title';
    }
  };

  const getAllEntities = () => {
    props.getEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort)},${paginationState.order}`,
      activeTab,
      searchText || null
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) 
    },${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
    }
  };

  useEffect(() => {
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort)
    },${paginationState.order}`;

    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab, searchText]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSearchButton = () => {
    setSearchText(newSearch);
  };

  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };

  const { entities, match, loading, totalItems, isEntityLoading } = props;

  function handleSelectionChange(selection) {
    console.log('selection: ', selection);
  }


  const isVideoSelected = videoId => {
    return selectedContentsByPage.includes(videoId) || existingContentsByPage.includes(videoId);
  };

  const removeFromExisting = videoId => {
    if (existingContentsByPage.includes(videoId)) {
      const updatedExistingVideoId = existingContentsByPage.filter(id => id !== videoId)
      setexistingContentsByPage(updatedExistingVideoId)
      setRemovedFromExisting(prev => { return [...prev, videoId] })

    } else if (selectedContentsByPage.includes(videoId)) {
      const updateSelectedByPages = selectedContentsByPage.filter(id => id !== videoId)
      setselectedContentsByPage(updateSelectedByPages)
    } else {
      return;
    }

  }
  const capitalizeFirstLetter = (str) => {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  };

  const handleCheckboxChange = event => {
    const videoId = parseInt(event.target.dataset.videoId);
    const checked = event.target.checked;

    !isVideoSelected(videoId) ? setselectedContentsByPage(prevSelectedVideos => {
      let updatedSelectedVideos = [...prevSelectedVideos];

      if (checked) {
        if (!updatedSelectedVideos.includes(videoId)) {
          updatedSelectedVideos.push(videoId);
        }
      } else {
        updatedSelectedVideos = updatedSelectedVideos.filter(id => id !== videoId);
      }

      return updatedSelectedVideos;
    }) : removeFromExisting(videoId)
  };
  const handleSave = () => {
    const data = { contentIds: selectedContentsByPage, ebookSystemPlaylist: { id: parseInt(props?.match?.params?.id) }, unselectContentIds: removedFromExisting };
    props.addContentToEbookPlaylist(
      data,
     props.match.params.contentType
    );
   
  };
  const handleClose = () => {
    const state = {showTab:match.params.contentType}
    props.history.push(`/admin-playlist/${match.params.type}/${match.params.pSearch}/${match.params.pPage}/${match.params.pSort}/${match.params.pOrder}/${match.params.id}/${match.params?.title}/playlistAssociations`,state);
  };
  useEffect(() => {
    if (props.isPlaylistUpdatingSuccess) {
      handleClose();
    }
  }, [props.isPlaylistUpdatingSuccess])

  return (
    <div className="main-container">
      <div className="page-title-btn-container">
      <Row className="justify-content-center">
          <Col md="12">
            <div className="detail-page-heading">
              <div className="detail-back-btn">
                <button 
                onClick={handleClose}
              
                >
                  <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
                </button>
                <h2 style={{ color: 'rgb(114 108 108)' }}>Add {capitalizeFirstLetter(match.params.contentType)}</h2>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink
              onClick={handleSave}
            >
              <span className="header-title">Save</span>
            </NavLink>
          </div>
        </div>
      </div>

      {!props.isPlaylistLoading&&<div className="table-responsive">

        {activeTab === 'ebook' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                  <th className="hand">
                    Select
                    <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                  </th>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      E-book Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">E-book Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      E-book Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('publish_date')}>
                      Publish Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                   
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((ebookMetaData, i) => (
                    <tr key={`entity-${i}`}>
                            <td>
                      {' '}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                        <input
                          type="checkbox"
                          className="videoCheckbox"
                          data-video-id={ebookMetaData.id}
                          checked={isVideoSelected(ebookMetaData.id)}
                          onChange={handleCheckboxChange}
                          style={{ width: '20px', height: '20px' }}
                        />

                      </div>
                    </td>
                      <td>
                        {ebookMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {ebookMetaData.thumbnailImageUrl ? (
                              <img
                                src={ebookMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={ebookMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{ebookMetaData?.title ? ebookMetaData.title : '--'}</td>
                      <td>{ebookMetaData?.contentUrl ? ebookMetaData.contentUrl : '--'}</td>
                      <td>{ebookMetaData?.keywords ? ebookMetaData.keywords : '--'}</td>

                      <td>{ebookMetaData?.publishDate ? ebookMetaData.publishDate : '--'}</td>

             
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No E-book Data found</div>
            )}
          </>
        )}
 
        {activeTab === 'patra' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                  <th className="hand">
                    Select
                    <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                  </th>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      Patra Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">Patra Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      Patra Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('publish_date')}>
                      Publish Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

            
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((patraMetaData, i) => (
                    <tr key={`entity-${i}`}>
                            <td>
                      {' '}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                        <input
                          type="checkbox"
                          className="videoCheckbox"
                          data-video-id={patraMetaData.id}
                          checked={isVideoSelected(patraMetaData.id)}
                          onChange={handleCheckboxChange}
                          style={{ width: '20px', height: '20px' }}
                        />

                      </div>
                    </td>
                      <td>
                        {patraMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {patraMetaData.thumbnailImageUrl ? (
                              <img
                                src={patraMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={patraMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{patraMetaData?.title ? patraMetaData.title : '--'}</td>
                      <td>{patraMetaData?.contentUrl ? patraMetaData.contentUrl : '--'}</td>
                      <td>{patraMetaData?.keywords ? patraMetaData.keywords : '--'}</td>

                      <td>{patraMetaData?.publishDate ? patraMetaData.publishDate : '--'}</td>

              
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Patra Data found</div>
            )}
          </>
        )}
        {activeTab === 'transcript' && show && !isEntityLoading && (
          <>
            {entities && entities.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="userTableId">
                <thead>
                  <tr>
                  <th className="hand">
                    Select
                    <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                  </th>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>

                    <th className="hand" onClick={sort('title')}>
                      Transcript Title
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">Transcript Url</th>
                    <th className="hand" onClick={sort('keywords')}>
                      Transcript Keywords
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('satsang_date')}>
                      Satsang Date
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

         
                  </tr>
                </thead>
                <tbody>
                  {entities?.map((transcriptMetaData, i) => (
                    <tr key={`entity-${i}`}>
                            <td>
                      {' '}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                        <input
                          type="checkbox"
                          className="videoCheckbox"
                          data-video-id={transcriptMetaData.id}
                          checked={isVideoSelected(transcriptMetaData.id)}
                          onChange={handleCheckboxChange}
                          style={{ width: '20px', height: '20px' }}
                        />

                      </div>
                    </td>
                      <td>
                        {transcriptMetaData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {transcriptMetaData.thumbnailImageUrl ? (
                              <img
                                src={transcriptMetaData.thumbnailImageUrl}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={transcriptMetaData.id}
                              ></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      <td>{transcriptMetaData?.title ? transcriptMetaData.title : '--'}</td>
                      <td>{transcriptMetaData?.contentUrl ? transcriptMetaData.contentUrl : '--'}</td>
                      <td>{transcriptMetaData?.keywords ? transcriptMetaData.keywords : '--'}</td>

                      <td>{transcriptMetaData?.satsangDate ? transcriptMetaData.satsangDate : '--'}</td>

      
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Transcript Data found</div>
            )}
          </>
        )}{' '}
        {props.totalItems ? (
          <div className={` asso-table ${entities && entities.length > 0 && show && !isEntityLoading ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>}
    </div>
  );
};

const mapStateToProps = ({ adminAssociationManagement,adminPlaylist }: IRootState) => ({
  entities: adminAssociationManagement.entities,
  loading: adminAssociationManagement.loading,
  totalItems: adminAssociationManagement.totalItems,
  // template: adminPlaylistAssociationManagement.template,
  isEntityLoading: adminAssociationManagement.loading,
  loadingFile: adminAssociationManagement.loadingFile,
  isPlaylistLoading:adminPlaylist.loading,
  isPlaylistUpdating: adminPlaylist.updating,
  isPlaylistUpdatingSuccess:adminPlaylist.updateSuccess,
  existingContentIds: adminPlaylist.existingIds,
});

const mapDispatchToProps = {
  getEntities,
  addContentToEbookPlaylist,
  getExistingEbookPlaylistContentIds
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylistEbookItemUpdate);
