import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './file-history.reducer';
import { IFileHistory } from 'app/shared/model/file-history.model';
import { APP_TIMESTAMP_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IFileHistoryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const FileHistoryDetail = (props: IFileHistoryDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    //return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`
  }

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }


  const { fileHistoryEntity } = props;

  return (
    <Row>
      <Col md="8">
        <h2>
          FileHistory [<b>{fileHistoryEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="jhiKey">Jhi Key</span>
          </dt>
          <dd>{fileHistoryEntity.jhiKey}</dd>
          <dt>
            <span id="fileName">File Name</span>
          </dt>
          <dd>{fileHistoryEntity.fileName}</dd>
          <dt>
            <span id="size">Size(kb)</span>
          </dt>
          <dd>{formatBytes(fileHistoryEntity.size)}</dd>
          <dt>
            <span id="numberOfRecords">Number Of Records</span>
          </dt>
          <dd>{fileHistoryEntity.numberOfRecords}</dd>
          <dt>
            <span id="failedCount">Failed Count</span>
          </dt>
          <dd>{fileHistoryEntity.failedCount}</dd>
          <dt>
            <span id="succeedCount">Succeed Count</span>
          </dt>
          <dd>{fileHistoryEntity.succeedCount}</dd>
          <dt>
            <span id="timeTaken">Time Taken(second)</span>
          </dt>
          <dd>{millisToMinutesAndSeconds(fileHistoryEntity.timeTaken)}</dd>
          <dt>
            <span id="failedMessageJson">Failed Message Json</span>
          </dt>
          {
            ((fileHistoryEntity.failedMessageJson)?.replace("[", '')?.replace("]", '')?.split(','))?.map((item) => (
              <dd>{item}</dd>
            ))
          }
          <dt>
            <span id="succeedMessageJson">Succeed Message Json</span>
          </dt>
          {
            ((fileHistoryEntity.succeedMessageJson)?.replace("[", '')?.replace("]", '')?.split(','))?.map((item) => (
              <dd>{item}</dd>
            ))
          }
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{fileHistoryEntity.status}</dd>
          <dt>
            <span id="uploadedOn">Uploaded On</span>
          </dt>
          <dd>
            {fileHistoryEntity.uploadedOn ? (
              <TextFormat value={fileHistoryEntity.uploadedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="executionStartTime">Execution Start Time</span>
          </dt>
          <dd>
            {fileHistoryEntity.executionEndTime ? (
              <TextFormat type="date" value={fileHistoryEntity.executionEndTime} format={APP_TIMESTAMP_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="executionEndTime">Execution End Time</span>
          </dt>
          <dd>
            {fileHistoryEntity.executionEndTime ? (
              <TextFormat type="date" value={fileHistoryEntity.executionEndTime} format={APP_TIMESTAMP_FORMAT} />
            ) : null}
          </dd>
          <dt>Uploaded By</dt>
          <dd>{fileHistoryEntity.uploadedBy ? fileHistoryEntity.uploadedBy.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/file-history" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        {/* <Button tag={Link} to={`/file-history/${fileHistoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button> */}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ fileHistory }: IRootState) => ({
  fileHistoryEntity: fileHistory.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileHistoryDetail);
