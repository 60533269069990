import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './file-history.reducer';
import { IFileHistory } from 'app/shared/model/file-history.model';
import { APP_TIMESTAMP_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IFileHistoryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const FileHistory = (props: IFileHistoryProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    //return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`
  }

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }


  const { fileHistoryList, match, loading } = props;
  return (
    <div>
      <h2 id="file-history-heading">
        File Histories
        {/* <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new File History
        </Link> */}
      </h2>
      <div className="table-responsive">
        {fileHistoryList && fileHistoryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th>ID</th>
                <th>Jhi Key</th> */}
                <th>File Name</th>
                <th>Size(kb)</th>
                <th>Number Of Records</th>
                <th>Failed Count</th>
                <th>Succeed Count</th>
                <th>Time Taken(second)</th>
                {/* <th>Failed Message Json</th>
                <th>Succeed Message Json</th>
                <th>Status</th> */}
                <th>Uploaded On</th>
                <th>Execution Start Time</th>
                <th>Execution End Time</th>
                <th>Uploaded By</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {fileHistoryList.map((fileHistory, i) => (
                <tr key={`entity-${i}`}>
                  {/* <td>
                    <Button tag={Link} to={`${match.url}/${fileHistory.id}`} color="link" size="sm">
                      {fileHistory.id}
                    </Button>
                  </td>
                  <td>{fileHistory.jhiKey}</td> */}
                  <td>{fileHistory.fileName}</td>
                  <td>{formatBytes(fileHistory.size)}</td>
                  <td>{fileHistory.numberOfRecords}</td>
                  <td>{fileHistory.failedCount}</td>
                  <td>{fileHistory.succeedCount}</td>
                  <td>{millisToMinutesAndSeconds(fileHistory.timeTaken)}</td>
                  {/* <td>{fileHistory.failedMessageJson}</td>
                  <td>{fileHistory.succeedMessageJson}</td>
                  <td>{fileHistory.status}</td> */}
                  <td>
                    {fileHistory.uploadedOn ? (
                      <TextFormat type="date" value={fileHistory.uploadedOn} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {fileHistory.executionStartTime ? (
                      <TextFormat type="date" value={fileHistory.executionStartTime} format={APP_TIMESTAMP_FORMAT} />
                    ) : null}
                  </td> <td>
                    {fileHistory.executionEndTime ? (
                      <TextFormat type="date" value={fileHistory.executionEndTime} format={APP_TIMESTAMP_FORMAT} />
                    ) : null}
                  </td>
                  {/* <td>{fileHistory.executionStartTime}</td>
                  <td>{fileHistory.executionEndTime}</td> */}
                  <td>{fileHistory.uploadedBy ? fileHistory.uploadedBy.login : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${fileHistory.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      {/* <Button tag={Link} to={`${match.url}/${fileHistory.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button> */}
                      <Button tag={Link} to={`${match.url}/${fileHistory.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No File Histories found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ fileHistory }: IRootState) => ({
  fileHistoryList: fileHistory.entities,
  loading: fileHistory.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileHistory);
