import { Moment } from 'moment';
import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { IUser } from 'app/shared/model/user.model';

export interface IVideoViewHistory {
  id?: number;
  viewedDate?: string;
  currentTicker?: string;
  videoMetaData?: IVideoMetaData;
  user?: IUser;
}

export const defaultValue: Readonly<IVideoViewHistory> = {};
