import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { YOUTTUBE_AUDIO_URL } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE_SPARSH } from 'app/shared/util/pagination.constants';
import { AvForm, AvGroup } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, getSortState } from 'react-jhipster';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps, useLocation } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, NavLink, Row, Table } from 'reactstrap';
import Satshrut from '../../../content/images/satshrut_default_thumbnail.jpg';
import './admin-sparsh-management.scss';
import { getAllAlbumDropDowns, getEntities, getTracks, updateStatusForAlbum, uploadAlbumId ,deleteEntityAlbum } from './admin-sparsh-reducer';
export interface IAdminSparshManagementProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));
enum Tablist {
  album = 'album',
}
const AdminSparshManagement = (props: IAdminSparshManagementProps) => {
  const location = useLocation();
  const classes = useStyles();
  const [paginationState, setPaginationState] = useState(overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE_SPARSH), location.search));
  const [activeTab, setActiveTab] = useState<Tablist>(Tablist.album);
  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchKey') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchKey')
  );
  const [newSearch, setNewSearch] = useState(searchText);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedAlbumIds, setSelectedAlbumIds] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const [status, setStatus] = useState([]);
  const [albumId, setAlbumId] = useState([]);
  const [showModalForStatus, setShowModalForStatus] = useState(false);
  const openModalForStatus = () => setShowModalForStatus(true);
  const closeModalForStatus = () => setShowModalForStatus(false);
  const handleChangeforStatus = (checked, id, status) => {
    setIsActive(status);
    setStatus(checked);
    openModalForStatus();
    setAlbumId(id);
  };
  const { entities, match, loading, totalItems, isEntityLoading, data, totalElements ,allAlbumDropDowns } = props;
  const toggleModalView = () => {
    if (!modal) setSelectedAlbumIds(null);
    setModal(!modal);
  };
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'desc' ? 'asc' : 'desc',
      sort: p,
    });
  };
  const handleFormSubmit = async event => {
    const albumIds = selectedAlbumIds?.map(option => option?.value);
    if (!selectedAlbumIds) return;
    let result = await props?.uploadAlbumId(albumIds);
    if (result) {
      setModal(!modal);
      sortEntities();
    }
  };
  const isPaginationCorrect = (sortColumn): Boolean => {
    switch (activeTab) {
      case 'album':
        return sortColumn === 'id' || sortColumn === 'title' || sortColumn === 'total_tracks' || sortColumn === 'total_active_tracks'|| sortColumn === 'is_non_youtube';
      default:
        return false;
    }
  };
  const fetchAlbumDropdowns = () => {
    props.getAllAlbumDropDowns();
  };
  const getAllEntities = () => {
    props.getEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'id'},${paginationState.order}`,
      activeTab,
      searchText || null
    );
  };
  const sortEntities = () => {
    getAllEntities();
    fetchAlbumDropdowns();
    const endURL = `?searchKey=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'id'
    },${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
    }
  };
  useEffect(() => {
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 500);
    setCurrentTimeout(timeout);
    const endURL = `?searchKey=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'id'
    },${paginationState.order}`;
    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab, searchText]);
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  const handleSearchButton = () => {
    setSearchText(newSearch);
  };
  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };
  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab !== newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };
  const albumIdDropdowns = allAlbumDropDowns['content']
  ?.filter(item => !item?.isNonYoutube) 
  .map(item => ({
    label: item?.title,
    value: item?.albumId,
  }));
  const handleSelectedAblumIds = value => {
    setSelectedAlbumIds(value);
  };
  const uploadAlbumIds = async (event, values) => {
    values = formValues;
    var result;
    result = await props.uploadAlbumId(selectedAlbumIds);
    if (result) {
      closeModal();
    }
  };
  const onAccept = async (event, values) => {
    values = formValues;
    var result;
    result = await props.updateStatusForAlbum(albumId, !status);
    if (result) {
      closeModalForStatus();
      sortEntities();
    }
  };

  const [deletePopShow, setDeletePopShow] = useState(false)
  const [trackId, setTrackId] = useState(null)


  function deleteBtn(id: any) {
    setDeletePopShow(true)
    setTrackId(id)
  }

  const handleClose = () => {
    setDeletePopShow(!deletePopShow)
  };

  const confirmDelete = async () => {
    const result = await props.deleteEntityAlbum(trackId);
    setDeletePopShow(false)
    setTrackId(null)
    if (result?.value.status == 200) {
        getAllEntities()
    }
  };

  return (
    <div className="main-container">


      <Modal isOpen={deletePopShow} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this Album?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button disabled={loading} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>

      
      <Modal                
        isOpen={modal}
        toggle={toggleModalView}
        className="custom-modal-centered"
        style={{ maxWidth: '50%', width: '50%', maxHeight: '80%', height: '80%' }}
      >
        <ModalHeader toggle={toggleModalView}>Edit Albums</ModalHeader>
        <ModalBody>
          <AvForm id="form" onValidSubmit={handleFormSubmit}>
            <AvGroup className="input-box-width">
              <Label id="keywordsLabel" for="video-meta-data-keywords" className="label-font">
                Albums
              </Label>
              <CreatableSelect
                className="input-font"
                id="video-meta-data-keywords"
                name="keywords"
                options={albumIdDropdowns}
                isMulti
                onChange={value => handleSelectedAblumIds(value)}
                placeholder={'Select or type a new Album ID (max 4 Ids)'}
                isOptionDisabled={() => selectedAlbumIds?.length >= 4}
              />
            </AvGroup>
            <button
              id="save-entity"
              type="submit"
              className={'common-btn'}
              style={{
                width: 120,
                marginTop: 20,
              }}
            >
              <span className="header-title">{props?.loadingSync ? 'Processing' : 'Sync'}</span>
            </button>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalView}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModalForStatus} toggle={closeModalForStatus}>
        <ModalHeader toggle={closeModalForStatus}>Confirm Status change</ModalHeader>
        <ModalBody>Are you sure you want to change this status to {isActive}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModalForStatus}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="confirm-renewal" color="success" onClick={onAccept}>
            <FontAwesomeIcon icon="check" />
            &nbsp; Accept
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-title-btn-container">
        <h2 id="video-meta-data-heading" style={{ color: 'rgb(114 108 108)' }}>
          Manage Sparsh Album
        </h2>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink
              tag={Link}
              to={`${match.url}/${activeTab}/${paginationState.activePage}/${paginationState.sort}/${paginationState.order}/15/newAlbum`}
            >
              <span className="header-title">Add New Album</span>
            </NavLink>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink onClick={toggleModalView}>
              <span className="header-title">Album Sync</span>
            </NavLink>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        <Tab label="Album" value={Tablist.album} className={`${classes.tab} ${activeTab === Tablist.album ? classes.activeTab : ''}`} />
      </Tabs>
      <div className="table-responsive">
        {activeTab === 'album' && show && !isEntityLoading && (
          <>
            {entities && entities['content']?.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="id">
                <thead>
                  <tr>
                    <th className="hand" onClick={sort('id')}>
                      Sr. No
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand">
                      Thumbnail
                      <span style={{ float: 'right' }}> </span>
                    </th>
                    <th className="hand" onClick={sort('title')}>
                      Album Name
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand">
                      Album URL
                      <span style={{ float: 'right' }}> </span>
                    </th>
                    <th className="hand" onClick={sort('is_non_youtube')}>
                      Album Source
                      <span style={{ float: 'right' }}> 
                      {' '}
                      <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand" onClick={sort('total_tracks')}>
                      Total Tracks
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand" onClick={sort('total_active_tracks')}>
                      Total Active Tracks
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th>Album Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entities['content']?.map((sparshAlbumMetaData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>{sparshAlbumMetaData?.id  ? (i+1)  : '--'}</td>
                      <td>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                          {sparshAlbumMetaData?.thumbnailUrl ? (
                            <img
                              src={sparshAlbumMetaData?.thumbnailUrl}
                              style={{ maxWidth: 200, maxHeight: 100 }}
                              key={sparshAlbumMetaData?.id}
                              alt="Thumbnail"
                            />
                          ) : (
                            <img
                            src={Satshrut}
                            style={{ maxWidth: 200, maxHeight: 100 }}
                            key={sparshAlbumMetaData.id}
                            alt="Thumbnail"
                          />
                          )}
                        </div>
                      </td>
                      <td>{sparshAlbumMetaData?.title ? sparshAlbumMetaData.title : '--'}</td>
                      <td>
                        <p className="textwrapafter4line">
                          {sparshAlbumMetaData?.isNonYoutube ? '--' : sparshAlbumMetaData?.albumId ? `${YOUTTUBE_AUDIO_URL}${sparshAlbumMetaData.albumId}` : '--'}
                        </p>
                      </td>
                      <td>
                        <p className="textwrapafter4line">
                          {!sparshAlbumMetaData?.isNonYoutube ? `Youtube Music` : 'User-Created'}
                        </p>
                      </td>
                      <td>{sparshAlbumMetaData?.totalTracks ? sparshAlbumMetaData.totalTracks : '--'}</td>
                      <td>{sparshAlbumMetaData?.totalActiveTracks ? sparshAlbumMetaData.totalActiveTracks : '--'}</td>
                      <td>
                        <Switch
                          checked={sparshAlbumMetaData?.activated}
                          onChange={checked => handleChangeforStatus(sparshAlbumMetaData?.activated, sparshAlbumMetaData?.id , sparshAlbumMetaData?.activated ? 'Inactive' : 'Active' )}
                        />
                        {sparshAlbumMetaData?.activated ? 'Active' : 'Inactive'}
                      </td>
                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${activeTab}/${paginationState.activePage}/${paginationState.sort}/${paginationState.order}/${sparshAlbumMetaData?.id}/details`}
                          >
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                marginRight: 2,
                                color: '#3a9b72'
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>


                          <NavLink
                            onClick={() => deleteBtn(sparshAlbumMetaData?.id)}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>

               
  
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Album found</div>
            )}
          </>
        )}{' '}
        {props?.totalItems ? (
          <div className={` asso-table ${entities && entities['content']?.length > 0 && show && !isEntityLoading ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState?.activePage} total={totalItems} itemsPerPage={paginationState?.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState?.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState?.itemsPerPage}
                totalItems={props?.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ sparshAlbumManagement }: IRootState) => ({
  data: sparshAlbumManagement,
  entities: sparshAlbumManagement.entities,
  loading: sparshAlbumManagement.loading,
  totalItems: sparshAlbumManagement.entities.totalElements,
  totalElements: sparshAlbumManagement.totalElements,
  template: sparshAlbumManagement.template,
  isEntityLoading: sparshAlbumManagement.loading,
  loadingFile: sparshAlbumManagement.loadingFile,
  loadingSync: sparshAlbumManagement.loadingSync,
  allAlbumDropDowns : sparshAlbumManagement.allAlbumDropDowns, 
});
const mapDispatchToProps = {
  getEntities,
  getTracks,
  uploadAlbumId,
  updateStatusForAlbum,
  getAllAlbumDropDowns,
  deleteEntityAlbum,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshManagement);
