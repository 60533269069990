import './footer.scss';

import React from 'react';

import { Col, Row } from 'reactstrap';

const Footer = props => (
  <div className="footer page-content">
    <Row>
      <Col md="12">
        <center>
          <p>Copyright @ 2022
            <br />
            Shrimad Rajchandra Aatma Tatva Research Centre | Asiatic Charitable Trust  <br />
            Shramik Naari Sangh | Shree Rajanuyayi Charitable Trust
          </p>
        </center>
      </Col>
    </Row>
  </div>
);

export default Footer;
