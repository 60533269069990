import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, NavLink } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Satshrut from '../../../content/images/satshrut3.png';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './admin-playlist.reducer';
import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import './admin-playlist.scss';
import { SERVER_API_URL } from 'app/config/constants';

export interface IPlaylistDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string,type:string }> {}

export const AdminPlaylistDetails = (props: IPlaylistDetailProps) => {
  const { adminPlaylistEntity } = props;
 
 
  const handleClose = () => {
    const state = { showTab: props.match.params.type };
    props.history.push('/admin-playlist' + props.location.search,state);
  };
  useEffect(() => {
    props.getEntity(props?.match?.params?.id,props?.match?.params?.type);
  }, [props?.match?.params?.id]);

  return adminPlaylistEntity && adminPlaylistEntity?.id ? (
    <div className="row">
      <Col md="12">
        {/* <h2>
          VideoMetaData [<b>{videoMetaDataEntity.id}</b>]
        </h2> */}
        <div className="detail-page-heading">
          <div className="detail-back-btn">
            <NavLink 
            // tag={Link} to={'/admin-playlist' + props.location.search}
            onClick={handleClose}
            >
              <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
            </NavLink>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
          <div className="common-btn" style={{ width: '112px', paddingLeft: 18 }}>
            <NavLink tag={Link} to={`/admin-playlist/${props?.match?.params?.type}/${adminPlaylistEntity?.id}/edit` + props.location.search}>
              <span className="header-title">Edit</span>
            </NavLink>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
              {props.match.params.type === 'video'?'Playlist Title':'Series Title'}
              </p>
              <p className="detail-txt">{adminPlaylistEntity?.playlistName}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className="detail-lable-txt">
                Playlist Id
              </p>
              <p className="detail-txt">{adminPlaylistEntity?.id}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="statuses" className="detail-lable-txt">
                Status
              </p>
            <p className="detail-txt">{adminPlaylistEntity.status ? adminPlaylistEntity?.status : '--'}</p>
             
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Description
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.description ? adminPlaylistEntity?.description : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                {props.match.params.type==='video'?"Speaker":"Author"}
              </p>
             {props.match.params.type==='ebook'&& <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.author ? adminPlaylistEntity?.author : '--'}
                
              </p>}
              {props.match.params.type==='video'&&<p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.speaker ? adminPlaylistEntity?.speaker : '--'}
                
              </p>}
              
      
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Location
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.location ? adminPlaylistEntity?.location : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Start Date
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.startDate ? adminPlaylistEntity?.startDate : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                End Date
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.endDate ? adminPlaylistEntity?.endDate : '--'}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
              {props.match.params.type === 'video'?'Playlist Thumbnail':'Series Thumbnail'}
              </p>
              {adminPlaylistEntity?.thumbnail ? (
                <img
                  src={props.match.params.type==="ebook"?adminPlaylistEntity?.thumbnail:`${SERVER_API_URL}/api/system/playlist/thumbnail/${adminPlaylistEntity.id}`}
                  style={{ maxWidth: 180, maxHeight: 150 }}
                  key={adminPlaylistEntity?.id}
                ></img>
              ) : (
                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
              )}
              {/* <img
                src={`${SERVER_API_URL}/api/systemPlayList/admin/thumbnail/${adminPlaylistEntity.id}`}
                style={{ width: 150, height: 150, marginTop: 20 }}
              ></img> */}
            </div>
          </div>
          {props.match.params.type==="ebook"&&<>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
               Written By
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.writtenBy ? adminPlaylistEntity?.writtenBy : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Written To
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.writtenTo ? adminPlaylistEntity?.writtenTo : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="description" className="detail-lable-txt">
                Recommended By
              </p>
              <p className="detail-txt" style={{ paddingLeft: 10 }}>
                {adminPlaylistEntity?.recommendedBy ? adminPlaylistEntity?.recommendedBy : '--'}
              </p>
            </div>
          </div>
          </>}
        
        </div>
      </Col>
    </div>
  ) : null;
};

const mapStateToProps = ({ adminPlaylist }: IRootState) => ({
  adminPlaylistEntity: adminPlaylist.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylistDetails);
