import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './globally-free-subscription-plan.reducer';
import { IGloballyFreeSubscriptionPlan } from 'app/shared/model/globally-free-subscription-plan.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IGloballyFreeSubscriptionPlanDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const GloballyFreeSubscriptionPlanDetail = (props: IGloballyFreeSubscriptionPlanDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { globallyFreeSubscriptionPlanEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          GloballyFreeSubscriptionPlan [<b>{globallyFreeSubscriptionPlanEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="isFreeSubscriptionPlan">Is Free Subscription Plan</span>
          </dt>
          <dd>{globallyFreeSubscriptionPlanEntity.isFreeSubscriptionPlan ? 'true' : 'false'}</dd>
          <dt>
            <span id="updatedOn">Updated On</span>
          </dt>
          <dd>
            {globallyFreeSubscriptionPlanEntity.updatedOn ? (
              <TextFormat value={globallyFreeSubscriptionPlanEntity.updatedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>Updated By</dt>
          <dd>{globallyFreeSubscriptionPlanEntity.updatedBy ? globallyFreeSubscriptionPlanEntity.updatedBy.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/globally-free-subscription-plan" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/globally-free-subscription-plan/${globallyFreeSubscriptionPlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ globallyFreeSubscriptionPlan }: IRootState) => ({
  globallyFreeSubscriptionPlanEntity: globallyFreeSubscriptionPlan.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GloballyFreeSubscriptionPlanDetail);
