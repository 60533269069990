import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, NavLink, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getSortState, ICrudGetAllAction, JhiPagination, JhiItemCount, TextFormat } from 'react-jhipster';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { getLiveVideo, updateLiveVideo,deleteLiveVid } from './live-video.reducer';
import { ILiveVideo } from 'app/shared/model/live-video.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_DAY } from 'app/config/constants';
import './live-video.scss';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { setTimeout } from 'timers';
import { faCommentDots, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import { deleteAssociation } from '../admin-playlist/admin-playlist.reducer';
export interface ILiveVideoProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));

enum Tablist {
  active = 'active',
  inactive = 'inactive',
}
export const LiveVideo = (props: ILiveVideoProps) => {
  const [reload, setReload] = useState(false);
  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchValue') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchValue')
  );
  const classes = useStyles();
  const [newSearch, setNewSearch] = useState(searchText);
  const [showVideoRemove, setShowVideoRemove] = useState(false);
  const [videoIdToDlt, setVideoIdToDlt] = useState(null);
  const [activeTab, setActiveTab] = useState<Tablist>(Tablist.active);
  const [show, setShow] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const getAllIntities=()=>{
    props.getLiveVideo(
      pagination.activePage - 1,
      pagination.itemsPerPage,
      pagination.sort,
      pagination.order,
      searchText,
      activeTab == 'active' ? true : false
    );
  }
  useEffect(() => {
    getAllIntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  }, [pagination.activePage, pagination.sort, pagination.order, searchText, activeTab]);

  const { liveVideoList1, match, loading, totalItems,updating } = props;

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPagination({
        ...pagination,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const handlePagination = currentPage =>
    setPagination({
      ...pagination,
      activePage: currentPage,
    });
  const sort = p => () => {
    setPagination({
      ...pagination,
      order: pagination.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const toggleActive = video => {
    const params = {
      ...video,
      status: video.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    };
    props.updateLiveVideo(
      params,
      pagination.activePage - 1,
      pagination.sort,
      pagination.order,
      searchText,
      pagination.itemsPerPage,
      activeTab == 'active' ? true : false
    );
  };
  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };
  const confirmRemoveVideo = async () => {
    const result = await props.deleteLiveVid(videoIdToDlt)
    if(result.value.status==200) {
      getAllIntities();
      setShowVideoRemove(false);
      setVideoIdToDlt(null);
     

    }
  };
  const handleCloseRemoveModal = () => {
    setShowVideoRemove(false);
  };
  const removeVideo = id => {
    setShowVideoRemove(true);
    setVideoIdToDlt(id);
  };
  const handleSearchButton = () => {
    setSearchText(newSearch);
  };
  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab != newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };

  return (
    <div className="main-container-list">
      {/* <h2 id="live-video-heading" style={{ color: 'rgb(114 108 108)' }}>
        Live Video
        <div className="float-right common-btn left-margin" style={{ margin: 15 }}>
       
          <NavLink tag={Link} to={`${match.url}/new`}>
            <span className="header-title">Create New Video</span>
          </NavLink>
        </div>
        
        
      </h2> */}
      <div style={{ padding: '5px' }} className="page-title-btn-container">
        <h2 id="live-video-heading" style={{ color: 'rgb(114 108 108)' }}>
          Live Video
        </h2>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={handleSearch}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={handleSearchButton}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink
              tag={Link}
              to={`${match.url}/new?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
            >
              <span className="header-title">Create New Video</span>
            </NavLink>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        <Tab label="Active" value={Tablist.active} className={`${classes.tab} ${activeTab === Tablist.active ? classes.activeTab : ''}`} />
        <Tab
          label="Inactive"
          value={Tablist.inactive}
          className={`${classes.tab} ${activeTab === Tablist.inactive ? classes.activeTab : ''}`}
        />
      </Tabs>
      <div className="table-responsive">
        {activeTab === 'active' &&
          show &&
          (liveVideoList1.entities.length > 0 ? (
            <Table style={{ marginTop: 10 }} responsive>
              <thead>
                {/* <th>Index</th> */}
                <th>Video Url</th>
                <th onClick={sort('title')}>
                  Video Title
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('created_on')}>
                  Date
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                {liveVideoList1.entities.length > 0 ? (
                  liveVideoList1.entities.map((lVideoList, i) => (
                    <tr key={`entity-${i}`}>
                      {/* <td>{lVideoList.id}</td> */}
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{lVideoList.liveUrl ? lVideoList.liveUrl : '--'}</p>
                        {/* {lVideoList.liveUrl} */}
                      </td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{lVideoList.title ? lVideoList.title : '--'}</p>
                        {/* {lVideoList.title} */}
                      </td>
                      <td>{lVideoList.createdOn}</td>
                      {/* <td className="date-font">
                    {lVideoList.createdOn ? <TextFormat type="date" value={lVideoList.createdOn} format={APP_LOCAL_DATETIME_DAY} /> : null}
                  </td> */}
                      <td>{lVideoList.status === 'ACTIVE' ? 'Active' : 'Inactive'} </td>
                      {/* <td>{lVideoList.status}</td> */}
                      <td className="text-left">
                        <div className="btn-group flex-btn-group-container">
                
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${lVideoList.id}/edit?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${lVideoList.id}/questions?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon={faCommentDots}
                              style={{
                                height: ' 32px',
                                width: '24px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Questions"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${lVideoList.id}/uploadAttachments?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              style={{
                                height: ' 32px',
                                width: '24px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Add Attachments"
                            />
                          </NavLink>
                          
                          <NavLink tag={Link} to={`${match.url}/${lVideoList.id}/details`}>
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>
                          {/* <NavLink

                          onClick={()=>removeVideo(lVideoList.id)}
                          // tag={Link} to={`${match.url}/${lVideoList.id}/edit?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: 'red',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink> */}
                          {lVideoList.status === 'ACTIVE' ? (
                            <div className="common-btn" style={{}}>
                              <NavLink onClick={() => toggleActive(lVideoList)}>
                                <span className="header-title">{lVideoList.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}</span>
                              </NavLink>
                            </div>
                          ) : (
                            <div className="common-btn">
                              <NavLink onClick={() => toggleActive(lVideoList)}>
                                <span className="header-title">{lVideoList.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}</span>
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p>No Data Found</p>
                )}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No Active Video found</div>
          ))}
        {activeTab === 'inactive' &&
          show &&
          (liveVideoList1.entities.length > 0 ? (
            <Table responsive>
              <thead>
                {/* <th>Index</th> */}
                <th>Video Url</th>
                <th onClick={sort('title')}>
                  Video Title
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('created_on')}>
                  Date
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                {liveVideoList1.entities.length > 0 ? (
                  liveVideoList1.entities.map((lVideoList, i) => (
                    <tr key={`entity-${i}`}>
                      {/* <td>{lVideoList.id}</td> */}
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{lVideoList.liveUrl ? lVideoList.liveUrl : '--'}</p>
                        {/* {lVideoList.liveUrl} */}
                      </td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{lVideoList.title ? lVideoList.title : '--'}</p>
                        {/* {lVideoList.title} */}
                      </td>
                      <td>{lVideoList.createdOn}</td>
                      {/* <td className="date-font">
                    {lVideoList.createdOn ? <TextFormat type="date" value={lVideoList.createdOn} format={APP_LOCAL_DATETIME_DAY} /> : null}
                  </td> */}
                      <td>{lVideoList.status === 'ACTIVE' ? 'Active' : 'Inactive'} </td>
                      {/* <td>{lVideoList.status}</td> */}
                      <td className="text-left">
                        <div className="btn-group flex-btn-group-container">
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${lVideoList.id}/edit?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink
                            tag={Link}
                            to={`${match.url}/${lVideoList.id}/questions?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon={faCommentDots}
                              style={{
                                height: ' 32px',
                                width: '24px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Questions"
                            />
                          </NavLink>
                          <NavLink

                          onClick={()=>removeVideo(lVideoList.id)}
                          // tag={Link} to={`${match.url}/${lVideoList.id}/edit?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: 'red',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>
                          {lVideoList.status === 'ACTIVE' ? (
                            <div className="common-btn" style={{}}>
                              <NavLink onClick={() => toggleActive(lVideoList)}>
                                <span className="header-title">{lVideoList.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}</span>
                              </NavLink>
                            </div>
                          ) : (
                            <div className="common-btn">
                              <NavLink onClick={() => toggleActive(lVideoList)}>
                                <span className="header-title">{lVideoList.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}</span>
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p><p>No Data Found</p></p>
                )}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No Inactive Video found</div>
          ))}
      </div>
      {activeTab === 'inactive' && props.totalItems ? (
        <div className={liveVideoList1 && liveVideoList1.entities.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            {/* <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled /> */}
            <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={pagination.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={pagination.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
      <Modal isOpen={showVideoRemove} toggle={handleCloseRemoveModal}>
        <ModalHeader toggle={handleCloseRemoveModal}>Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseRemoveModal}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="jhi-confirm-delete-videoMetaData" disabled={updating} color="danger" onClick={confirmRemoveVideo}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ liveVideoList }: IRootState) => ({
  liveVideoList1: liveVideoList,
  loading: liveVideoList.loading,
  totalItems: liveVideoList.totalItems,
  updating: liveVideoList.updating
});

const mapDispatchToProps = {
  getLiveVideo,
  updateLiveVideo,
  deleteLiveVid
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideo);
