import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISubscriptionPlan, defaultValue } from 'app/shared/model/subscription-plan.model';

export const ACTION_TYPES = {
  FETCH_SUBSCRIPTIONPLAN_LIST: 'subscriptionPlan/FETCH_SUBSCRIPTIONPLAN_LIST',
  FETCH_SUBSCRIPTIONPLAN: 'subscriptionPlan/FETCH_SUBSCRIPTIONPLAN',
  CREATE_SUBSCRIPTIONPLAN: 'subscriptionPlan/CREATE_SUBSCRIPTIONPLAN',
  UPDATE_SUBSCRIPTIONPLAN: 'subscriptionPlan/UPDATE_SUBSCRIPTIONPLAN',
  DELETE_SUBSCRIPTIONPLAN: 'subscriptionPlan/DELETE_SUBSCRIPTIONPLAN',
  UPDATE_SUBSCRIPTIONPLAN_LOGO: 'subscriptionPlan/UPDATE_SUBSCRIPTIONPLAN_LOGO',

  RESET: 'subscriptionPlan/RESET',
};


const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISubscriptionPlan>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type SubscriptionPlanState = Readonly<typeof initialState>;

// Reducer

export default (state: SubscriptionPlanState = initialState, action): SubscriptionPlanState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN_LOGO):
    case REQUEST(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUBSCRIPTIONPLAN):
    case REQUEST(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN):
    case REQUEST(ACTION_TYPES.DELETE_SUBSCRIPTIONPLAN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN_LOGO):
    case FAILURE(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN):
    case FAILURE(ACTION_TYPES.CREATE_SUBSCRIPTIONPLAN):
    case FAILURE(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN):
    case FAILURE(ACTION_TYPES.DELETE_SUBSCRIPTIONPLAN):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN_LOGO):
      return {
        ...state,
        loading: false,
        // entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUBSCRIPTIONPLAN):
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUBSCRIPTIONPLAN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// const apiUrl = '';
const apiUrl = 'api/subscription-plans';

// Actions

export const getEntities: ICrudGetAllAction<ISubscriptionPlan> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST,
  payload: axios.get<ISubscriptionPlan>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<ISubscriptionPlan> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN,
    payload: axios.get<ISubscriptionPlan>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ISubscriptionPlan> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SUBSCRIPTIONPLAN,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISubscriptionPlan> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  if (result.value.status == 200) {
    return result.value.data;
  }
};


export const updateSubImage: ICrudPutAction<ISubscriptionPlan> = (id: any, params: any) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSCRIPTIONPLAN_LOGO,
    payload: axios.post(`/api/upload/planLogo/${id}`, params),
    meta: {
      successMessage: '',
      errorMessage: '',
    },
  });

};

export const deleteEntity: ICrudDeleteAction<ISubscriptionPlan> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUBSCRIPTIONPLAN,
    payload: axios.delete(requestUrl),
    meta: {
      successMessage: '',
      errorMessage: '',
    },
  });
  // dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
