import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MyFavourite from './my-favourite';
import MyFavouriteDetail from './my-favourite-detail';
import MyFavouriteUpdate from './my-favourite-update';
import MyFavouriteDeleteDialog from './my-favourite-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MyFavouriteUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MyFavouriteUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MyFavouriteDetail} />
      <ErrorBoundaryRoute path={match.url} component={MyFavourite} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MyFavouriteDeleteDialog} />
  </>
);

export default Routes;
