import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const FormUpload = (props) => {
  const [isError, setIsError] = useState(false);
  const [preview, setPreview] = useState(false);

  const {
    value,
    name,
    label,
    register,
    errorMsg,
    isPreview,
    uploadSingleFile
  } = props;

  useEffect(() => {
    setPreview(isPreview);
  }, [isPreview]);

  useEffect(() => {
    setIsError(errorMsg);
  }, [errorMsg]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB > 100) {
      toast.warn('file size can be of maximum of 100 mb')
    } else {
      setIsError(false);
      uploadSingleFile(event);
    }
  };

  return (
    <div className="form-upload-container">
      <div className="field-container">
        <div className="input-field-container sub-image ">
          {!isPreview && (
            <input 
              type="file" 
              accept=".mp3" 
              name={name} 
              {...register} 
              onChange={handleFileUpload} 
            />
          )}
          {(!isPreview && value === null) && (
            <div className={`${isError ? 'customized-fileupload-error' : 'customized-fileupload'}`} style={{ width: 312 }}>
              <span className="img-upload-icon">{label}</span>
              <span className="field-container">{'Add a MP3 file here.'}</span>
            </div>
          )}
          {(isPreview && value != null) && (
            <div>
              <audio controls src={value} style={{ height: 50 }} />
            </div>
          )}
          {isError && <p className="error">{isError}</p>}
        </div>
      </div>
    </div>
  );
};

FormUpload.defaultProps = {
  icon: '',
  isrequired: false,
  inlineLabel: false
};

export default FormUpload;
