import React, { useEffect } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';

import { GoogleLogin, useGoogleLogout, useGoogleLogin } from 'react-google-login';
import googleLogo from '../../../content/images/google.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gapi } from 'gapi-script';
export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: Function;
  handleClose: Function;
}
const clientId = "130831750859-lbibg8vm8qg1dld5f78ml036inedcgim.apps.googleusercontent.com"
function LoginModal(props) {

  const handleSubmit = (event, errors, { username, password, rememberMe }) => {
    const { handleLogin } = props;
    handleLogin(username, password, rememberMe);
  };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  }, [])

  const logout = (response) => {
  };

  const { loginError, handleClose } = props;


  const onSuccess = (res) => {
    const { googleLogin } = props;
    let login = res.profileObj.email
    let activated = true
    let createdBy = 'google'
    signOut()
    googleLogin(res.profileObj.email, res.profileObj.givenName, res.profileObj.familyName, createdBy, login, activated)
  };

  const onFailure = (res) => {
  };

  const onLogoutSuccess = (res) => {
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true
  });

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
  });

  return (
    <div className='row'>
      <div className='col-md-6 login-page'>
        <div style={{ 'textAlign': 'center', alignItems: 'center' }}>
          <img className='logo-responsive' src="content/images/shree_prem_acharyaji.png" alt="Logo" />
        </div>
      </div>
      {/* <div className='col-md-6' style={{backgroundColor:'#ffd54f',}}> */}
      <div className='col-md-6'>
        <div id="login-page" style={{ 'justifyContent': 'center', 'alignItems': 'center', 'display': 'flex', padding: 10 }} >
          <AvForm onSubmit={handleSubmit} className='login-form'>
            <ModalBody>
              <div style={{ textAlign: 'center' }} >
                {/* <img src="content/images/logo.png" alt="Logo" style={{ 'height': 150 }} /> */}
                <img src="content/images/satshrut3.png" alt="Logo" style={{ 'height': 150 }} />
                <h4 className='login-welcome-txt'>Welcome to Satshrut</h4>
              </div>
              <Row>
                <Col md="12">
                  {/* {loginError ? (
                      <Alert color="danger">
                        <strong>Failed to sign in!</strong> Please check your credentials and try again.
                      </Alert>
                    ) : null} */}
                </Col>
                <Col md="12">
                  <AvGroup className='input-box-width'>
                    <Label id="username" className='label-font'>
                      User Name
                    </Label>
                    <AvField
                      name="username"
                      className='input-font'
                      placeholder="User Name"
                      required
                      errorMessage="Username cannot be empty!"
                      autoFocus
                    />
                  </AvGroup>

                  <AvGroup className='input-box-width'>
                    <Label id="password" className='label-font'>
                      Password
                    </Label>
                    <AvField
                      name="password"
                      type="password"
                      className='input-font'
                      placeholder="Your password"
                      required
                      errorMessage="Password cannot be empty!"
                    />
                  </AvGroup>
                  <AvGroup check inline>
                    <Label className="form-check-label input-font">
                      <AvInput type="checkbox" name="rememberMe" /> Remember me
                    </Label>
                  </AvGroup>
                </Col>
              </Row>
              <AvGroup check inline>
                <button
                  type="submit"
                  className="common-btn"
                  style={{
                    width: 470,
                    marginTop: 20,
                  }} >
                  <span className='header-title'>Sign In</span>
                </button>
              </AvGroup>
            </ModalBody>
          </AvForm>
        </div >
      </div>
    </div >
  );
}

export default LoginModal;
