import React from 'react';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo.png" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand
    tag={Link} to={props.isAuthenticated ? "/video-meta-data" : ''} 
    className="brand-logo">
    <div {...props} className="brand-icon">
      {props?.headerIcon === 'login' ? '' :
        <img src="content/images/logo.png" alt="Logo" />
      }
    </div>

    {/* <span className="brand-title">Satshrut</span> */}
    {/* <span className="navbar-version">{appConfig.VERSION}</span> */}
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>Home</span>
    </NavLink>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>Home</span>
    </NavLink>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>Home</span>
    </NavLink>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>Home</span>
    </NavLink>
  </NavItem>
);

export const Search = props => (
  <div style={{ 'width': '55%' }}>
    <input
      name="search"
      placeholder="Search..."
      style={{
        marginLeft: 5,
        borderRadius: '8px',
        'height': '42px',
        'padding': '10px',
        width: '100%'
      }}
    />
  </div>
);