import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IFileHistory {
  id?: number;
  jhiKey?: string;
  fileName?: string;
  size?: number;
  numberOfRecords?: number;
  failedCount?: number;
  succeedCount?: number;
  timeTaken?: number;
  failedMessageJson?: string;
  succeedMessageJson?: string;
  status?: string;
  uploadedOn?: string;
  executionStartTime?: number;
  executionEndTime?: number;
  uploadedBy?: IUser;
}

export const defaultValue: Readonly<IFileHistory> = {};
