import { IStatuses } from 'app/shared/model/statuses.model';

export interface ISubscriptionPlan {
  id?: number;
  subscriptionLevel?: number;
  subscriptionName?: string;
  price?: number;
  statuses?: IStatuses;
  specificationName?: [];
  subscriptionLogo?: string;
  subscription_logo_content_type?: string;
  updatedDate?: string;
  createdDate?: string;
}

export const defaultValue: Readonly<ISubscriptionPlan> = {};
