import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';

import { Button, NavLink, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IStatuses } from 'app/shared/model/statuses.model';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { createLiveVideo, getLiveVideoTitle,updateLiveVideoEntity, reset, getLiveVideo, updateLiveVideo,getEntity } from './live-video.reducer';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { APP_DATE_FORMAT, APP_LOCAL_DATETIME_DAY } from 'app/config/constants';

import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
export interface IliveVideoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const liveVideoUpdate = (props: IliveVideoUpdateProps) => {
  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const { liveVideoEntity, liveVideoTitle, statuses, loading, updating, store } = props;
  const [videoUrl, setVideoUrl] = useState(null);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [editVideoTitle, setEditVideoTitle] = useState('')
  const [editStatus, setEditSatus] = useState(null)
  const handleChangeVideoUrl = event => {
    setVideoUrl(event.target.value);
  };

  const handleClose = () => {
    props.reset();
    props.history.push('/live-video'+props.location.search);

  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.getStatuses();
  }, []);


  const saveLiveVideo = async(event, errors, values) => {
    if (values?.title) {
      const array = {
        createdOn: new Date(),
        id: values.id,
        thumbnails: liveVideoTitle.url,
      };
      if (errors.length === 0) {
        const entity = {
          ...values,
          ...array,
        };

        if (isNew) {
          const result = await props.createLiveVideo(entity);
          if (result) {
            
            handleClose()
          }
        } else {
          const editArray = {
            ...values,
            id: liveVideoEntity.id,
            user: liveVideoEntity?.user,
            createdOn: liveVideoEntity?.createdOn,
            thumbnails: liveVideoEntity?.createdOn,
          }
          const result = await props.updateLiveVideoEntity(editArray);
          // if (result) {
           
          // }
          handleClose()
        }
      }


    }
  };

  const status = [{
    id: 1,
    statusValue: 'ACTIVE',
  },
  {
    id: 2,
    statusValue: 'INACTIVE',
  },
  ];

  const videoTitleFun = (e) => {
    setEditVideoTitle(e.target.value)
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="back-arrow" style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
            <NavLink 
            // tag={Link} to={`/live-video`+props.location.search}
            onClick={handleClose}
            >
              <FontAwesomeIcon icon="arrow-left" style={{ width: 25, height: 25 }} />
            </NavLink>
            <h2 id="satshrutApp.liveVideo.home.createOrEditLabel" style={{ color: 'rgb(114 108 108)' }}>
              {' '}
              {isNew ? 'Create' : 'Edit'} Live Video
            </h2>
          </div>
        </Col>
      </Row>
   {!props.loading &&   <Row className="justify-content-center main-container">
        <Col md="12">
          {isNew ?
            <AvForm model={isNew ? Object.keys(liveVideoTitle).length>0?liveVideoTitle: {}:{}} onSubmit={saveLiveVideo}>
              <div className="row">
                <div className="col-md-6">
                  <AvGroup>
                    <Label id="livevideoUrlLabel" for="live-video-url-level" className="label-font">
                      Video Url
                    </Label>
                    <AvField
                      id="liveUrl"
                      type="url"
                      className="form-control input-font"
                      name="liveUrl"
                      pattern="https://.*"
                      validate={{
                        required: { value: true, errorMessage: 'Enter Valid Url.' },
                      }}
                      onChange={handleChangeVideoUrl}
                      disabled={liveVideoTitle?.liveUrl?true:false}
                    />
                  </AvGroup>

                  {liveVideoTitle&&Object.keys(liveVideoTitle).length > 0 ? (
                    <>
                      <AvGroup>
                        <Label for="live-video-statuses" className="label-font">
                          Status
                        </Label>
                        <AvInput
                          id="live-video-statuses"
                          type="select"
                          className="form-control input-font"
                          name="status"
                          validate={{
                            required: { value: true, errorMessage: 'Status is required.' },
                          }}
                        >
                          <option value="" key="0" />
                          {status
                            ? status.map(otherEntity => (
                              <option value={otherEntity.statusValue} key={otherEntity.statusValue}>
                                {otherEntity.statusValue}
                              </option>
                            ))
                            : null}
                        </AvInput>
                      </AvGroup>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-6">
                  {liveVideoTitle&&Object.keys(liveVideoTitle).length > 0 ? (
                    <>
                      {' '}
                      <AvGroup>
                        <Label id="LiveVideoTitleLabel" for="live-video-title" className="label-font">
                          Video Title
                        </Label>
                        <AvField
                          id="live-video-title"
                          className="form-control input-font"
                          type="text"
                          name="title"
                          value={liveVideoTitle.title}
                        // validate={{
                        //   required: { value: true, errorMessage: 'Video Title is required.' },
                        // }}
                        // disabled
                        />
                      </AvGroup>
                    </>
                  ) : (
                    <button
                      // disabled={updating}
                      id="save-entity"
                      type="submit"
                      className="common-btn"
                      style={{
                        width: 200,
                        marginTop: 40,
                        marginBottom: 12,
                      }}
                      onClick={() => props.getLiveVideoTitle(videoUrl)}
                    >
                      <span className="header-title">Fetch Data</span>
                    </button>
                  )}
                </div>
              </div>
              {Object.keys(liveVideoTitle).length > 0 ? (
                <button
                  disabled={updating}
                  id="save-entity"
                  type="submit"
                  className="common-btn"
                  style={{
                    width: 200,
                    marginTop: 20,
                  }}
                >
                  <span className="header-title">Save</span>
                </button>
              ) : (
                <button
                  disabled={updating}
                  id="save-entity"
                  type="submit"
                  className="common-btn"
                  hidden
                  style={{
                    width: 200,
                    marginTop: 20,
                  }}
                >
                  <span className="header-title">Save</span>
                </button>
              )}
            </AvForm>
            :
            liveVideoEntity&&Object.keys(liveVideoEntity).length > 0  &&
            <AvForm model={liveVideoEntity} onSubmit={saveLiveVideo}>
             { console.log("first")}
              <div className="row">
                <div className="col-md-6">
                  <AvGroup>
                    <Label id="livevideoUrlLabel" for="live-video-url-level" className="label-font">
                      Video Url
                    </Label>
                    <AvField
                      id="live-video-url-level"
                      type="url"
                      className="form-control input-font"
                      name="liveUrl"
                      // value={liveVideoEntity[0]?.liveUrl}
                      disabled
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label for="live-video-statuses" className="label-font">
                      Status
                    </Label>
                    <AvInput
                      id="live-video-statuses"
                      type="select"
                      className="form-control input-font"
                      name="status"
                      required
                      validate={{
                        required: { value: true, errorMessage: 'Status is required.' },
                      }}
                    >
                      <option value="" key="0" />
                      {status
                        ? status.map(otherEntity => (
                          <option value={otherEntity.statusValue} key={otherEntity.statusValue}>
                            {otherEntity.statusValue}
                          </option>
                        ))
                        : null}
                    </AvInput>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <>
                    <AvGroup>
                      <Label id="LiveVideoTitleLabel" for="live-video-title" className="label-font">
                        Video Title
                      </Label>
                      <AvField
                        id="live-video-title"
                        className="form-control input-font"
                        type="text"
                        name="title"
                      />
                    </AvGroup>
                  </>
                </div>
              </div>

              <button
                disabled={updating}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{
                  width: 200,
                  marginTop: 20,
                }}
              >
                <span className="header-title">Save</span>
              </button>
            </AvForm>
          }
        </Col>
      </Row>}
    </div >
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  store: storeState,
  statuses: storeState.statuses.entities,
  liveVideoEntity: storeState.liveVideoList.entity,
  liveVideoTitle: storeState.liveVideoList.liveVideoTitle,
  loading: storeState.liveVideoList.loading,
  updating: storeState.liveVideoList.updating,
  updateSuccess: storeState.liveVideoList.updateSuccess,
});

const mapDispatchToProps = {
  getStatuses,
  createLiveVideo,
  getLiveVideoTitle,
  getLiveVideo,
  updateLiveVideo,
  updateLiveVideoEntity,
  reset,
  getEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(liveVideoUpdate);
