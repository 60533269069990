import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink, Row, Table, Col } from 'reactstrap';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import {
  deleteVideo,
  reset,
  getPlayListVideo,
  deleteAssociation,
  getAssociationLists,
  searchVideoList,
  serialNumberUpdate,
} from './admin-playlist.reducer';
import Satshrut from '../../../content/images/satshrut3.png';
import { makeStyles } from '@material-ui/core/styles';
export interface IAdminPlaylistAssociationsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      type: string;
      url: string;
      pSearch: string;
      id: string;
      title: string;
      pPage: string;
      pSort: string;
      pOrder: string;
    }> {}
const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));

enum Tablist {
  video = 'video',
  audio = 'audio',
  ebook = 'ebook',
  pad = 'pad',
  patra = 'patra',
  transcript = 'transcript',
}
export const AdminPlaylistAssociations = (props: IAdminPlaylistAssociationsProps) => {
  const { entities, match, loading, totalItems, updating, isEntityLoading } = props;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const location = useLocation();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(location?.state?.showTab ? location.state.showTab : match.params.type==='video'? Tablist.video:Tablist.ebook);
  const [playListVideoDel, setPlayListVideoDel] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [isSrNoEdit, setIsSrNoEdit] = useState<boolean>();
  const [activeIndex, setActiveIndex] = useState(null);
  const [newSerialNo, setNewSerialNo] = useState<number>();
  const [playListAssociationDel, setPlayListAssociationDel] = useState(false);
  const [associationId, setAssociationId] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [isNewSearch, setIsNewSearch] = useState(false);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p.trim(),
    });
  };

  const isPaginationCorrect = (sortColumn): string => {
    switch (activeTab) {
      case 'video':
        if (sortColumn !== 'language' || sortColumn !== 'playListSerialNumber' || sortColumn !== 'videoTitle') {
          return sortColumn;
        } else {
          return 'playListSerialNumber';
        }

      case 'audio':
        if (sortColumn === 'title' || sortColumn === 'publishDate' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'ebook':
        if (sortColumn === 'title' || sortColumn === 'publishDate' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'pad':
        if (sortColumn === 'title' || sortColumn === 'publishDate' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'patra':
        if (sortColumn === 'title' || sortColumn === 'publishDate' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'transcript':
        if (sortColumn === 'title' || sortColumn === 'satsangDate' || sortColumn === 'keywords') {
          return sortColumn;
        } else {
          return 'title';
        }
      default:
        return 'id';
    }
  };

  const getAllEntities = () => {
    
    const data = {
      sortColumn: isPaginationCorrect(paginationState.sort),

      sortDirection: paginationState.order.toUpperCase(),
      pageNo: paginationState.activePage - 1,
      pageSize: paginationState.itemsPerPage,
      playListId: props?.match?.params?.id,
      contentType: activeTab.toUpperCase(),
      searchValue: searchText ? searchText : null,

    };
    props.getAssociationLists(data,match.params.type);
    setIsNewSearch(false);

    // }
  };

  const sortEntities = () => {
    getAllEntities();

    const endURL = `?page=${paginationState.activePage}&sort=${isPaginationCorrect(paginationState.sort)},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?page=${paginationState.activePage}&sort=${isPaginationCorrect(paginationState.sort)},${paginationState.order}`;

    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab]);
  useEffect(() => {
    isNewSearch && paginationState.activePage === 1 && sortEntities();
  }, [isNewSearch]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });


  const isPreview = false;

  const handleCloseVidDelModel = () => {
    setPlayListVideoDel(false);
  };
  const handleCloseAssocnDelModel = () => {
    setPlayListAssociationDel(false);
  };

  const confirmDeleteVideo = async () => {
    const result = await props.deleteVideo(
      videoId,
      props?.match?.params?.id,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort)}`,
      paginationState.order,
      searchText,
   
    );
    if (result) {
      setPlayListVideoDel(false);
    }
  };
  const confirmDeleteAssociation = async () => {
    const result = await props.deleteAssociation(
      associationId,
      activeTab,
      props?.match?.params?.id,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort)}`,
      paginationState.order,
      searchText,
      props.match.params.type
    );
    if (result) {
      setPlayListAssociationDel(false);
    }
  };
  const playListVideoDelete = id => {
    setPlayListVideoDel(true);
    setVideoId(id);
  };
  const playListAssociationDelete = id => {
    setPlayListAssociationDel(true);
    setAssociationId(id);
  };
  const editSrNo = (index, videoMetaData) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setNewSerialNo(videoMetaData.playListSerialNumber ? videoMetaData.playListSerialNumber : 0);
      setActiveIndex(index);
    }
  };
  const handleSerialNoChange = e => {
    const value = e.target.value;
    setNewSerialNo(value);
  };

  const srNoUpdate = async item => {
    const params = {
      associationId: item.assId,
      playListSerialNumber: newSerialNo,
    };
    const result = await props.serialNumberUpdate(params);
    if (result) {
      setActiveIndex(null);
      sortEntities();
    }
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab != newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };
  const handleSearch = e => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(e.target.value);
      !isNewSearch && handleSearchButton();
    }
  };

  const handleSearchButton = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    setIsNewSearch(true);
  };
  const handleGoBack = () => {
    props.reset();
    const state = {showTab:props.match.params.type}
    props.history.push(
      `/admin-playlist` +
        `?searchValue=${props.match.params.pSearch}&page=${props.match.params.pPage}&sort=${props.match.params.pSort},${props.match.params.pOrder}`,state
    );
  };

  return (
    <div className="main-container">
      <div className="page-title-btn-container">
        <Row className="justify-content-center">
          <Col md="12">
            <div className="detail-page-heading">
              <div className="detail-back-btn">
                <NavLink
                  // tag={Link}
                  // to={}
                  onClick={handleGoBack}
                >
                  <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
                </NavLink>
                <h2 style={{ color: 'rgb(114 108 108)' }}>{props?.match?.params?.title} {props.match.params.type === 'video'?'Playlist':'Series'} </h2>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>
                Search
              </button>
            </div>
          </div>
          {match.params.type === 'video' && activeTab == 'video' && (
            <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
              <NavLink tag={Link} to={match.params.type === 'video' && `${match.url}/videoPlaylist/add`}>
                <span className="header-title">Update Playlist</span>
              </NavLink>
            </div>
          )}
          {match.params.type === 'ebook' && (
            <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
              <NavLink tag={Link} to={`${match.url}/ebookPlaylist/${activeTab}/add`}>
                <span className="header-title">Update Series</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        { match.params.type==='video' &&<Tab label="Video" value={Tablist.video} className={`${classes.tab} ${activeTab === Tablist.video ? classes.activeTab : ''}`} />}
       { match.params.type==='video' && <Tab label="Audio" value={Tablist.audio} className={`${classes.tab} ${activeTab === Tablist.audio ? classes.activeTab : ''}`} />}
        <Tab label="E-book" value={Tablist.ebook} className={`${classes.tab} ${activeTab === Tablist.ebook ? classes.activeTab : ''}`} />
      { match.params.type==='video' &&  <Tab label="Pad" value={Tablist.pad} className={`${classes.tab} ${activeTab === Tablist.pad ? classes.activeTab : ''}`} />}
        <Tab label="Patra" value={Tablist.patra} className={`${classes.tab} ${activeTab === Tablist.patra ? classes.activeTab : ''}`} />

        <Tab
          label="Transcript"
          value={Tablist.transcript}
          className={`${classes.tab} ${activeTab === Tablist.transcript ? classes.activeTab : ''}`}
        />
      </Tabs>
      <div className="table-responsive">
        <div className="table-responsive">
          { match.params.type==='video' && activeTab === 'video' && entities && show && !isEntityLoading && (
            <>
              {' '}
              {entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand" onClick={sort('playListSerialNumber')}>
                        Sr No
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('videoTitle')}>
                        Video Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('language')}>
                        Language
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">
                        Status
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((videoMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75 }}>
                          {activeIndex == i ? (
                            <input
                              style={{ maxWidth: 70, textAlign: 'center' }}
                              min={0}
                              type="number"
                              id={`${videoMetaData.id}`}
                              value={newSerialNo}
                              onChange={handleSerialNoChange}
                              disabled={activeIndex !== i}
                            />
                          ) : videoMetaData.playListSerialNumber ? (
                            videoMetaData.playListSerialNumber
                          ) : (
                            '--'
                          )}
                        </td>

                        <td>{videoMetaData?.videoTitle ? videoMetaData.videoTitle : '--'}</td>

                        <td>
                          {videoMetaData?.language
                            ? videoMetaData.language.charAt(0).toUpperCase() + videoMetaData.language.slice(1)
                            : '--'}
                        </td>

                        <td>{videoMetaData.status ? videoMetaData.status : '--'}</td>
                        {/* <td /> */}
                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink onClick={() => editSrNo(i, videoMetaData)}>
                              <FontAwesomeIcon
                                icon="pencil-alt"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginRight: 2,
                                  color: '#275cb0',
                                }}
                                data-toggle="tooltip"
                                title="Edit"
                              />
                            </NavLink>
                            <NavLink onClick={() => playListVideoDelete(videoMetaData.id)}>
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </NavLink>
                            {activeIndex === i && videoMetaData.playListSerialNumber !== newSerialNo && (
                              <NavLink onClick={() => srNoUpdate(videoMetaData)}>
                                <FontAwesomeIcon
                                  icon="save"
                                  style={{
                                    height: '25px',
                                    width: '25px',
                                    color: '#3a9b72',
                                  }}
                                  data-toggle="tooltip"
                                  title="save"
                                />
                              </NavLink>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Video Data found</div>
              )}
            </>
          )}
          { match.params.type==='video' &&activeTab === 'audio' && entities && show && !isEntityLoading && (
            <>
              {entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Thumbnail
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      <th className="hand" onClick={sort('title')}>
                        Audio Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">Audio Url</th>
                      <th className="hand" onClick={sort('keywords')}>
                        Audio Keywords
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('publishDate')}>
                        Publish Date
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((audioMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          {audioMetaData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {audioMetaData.thumbnailImageUrl ? (
                                <img
                                  src={audioMetaData.thumbnailImageUrl}
                                  style={{ maxWidth: 70, maxHeight: 50 }}
                                  key={audioMetaData.id}
                                ></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        <td>{audioMetaData?.title ? audioMetaData.title : '--'}</td>
                        <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Apple Url : {audioMetaData?.contentUrlApple ? audioMetaData.contentUrlApple : '--'}</p>
                            <p>Spotify Url : {audioMetaData?.contentUrlSpotify ? audioMetaData.contentUrlSpotify : '--'}</p>
                          </div>
                        </td>

                        <td>{audioMetaData?.keywords ? audioMetaData.keywords : '--'}</td>

                        <td>{audioMetaData?.publishDate ? audioMetaData.publishDate : '--'}</td>

                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink onClick={() => playListAssociationDelete(audioMetaData.contentId)}>
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Audio Data found</div>
              )}
            </>
          )}
          {activeTab === 'ebook' && entities && show && !isEntityLoading && (
            <>
              {entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Thumbnail
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      <th className="hand" onClick={sort('title')}>
                        E-book Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">E-book Url</th>
                      <th className="hand" onClick={sort('keywords')}>
                        E-book Keywords
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('publishDate')}>
                        Publish Date
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((ebookMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          {ebookMetaData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {ebookMetaData.thumbnailImageUrl ? (
                                <img
                                  src={ebookMetaData.thumbnailImageUrl}
                                  style={{ maxWidth: 70, maxHeight: 50 }}
                                  key={ebookMetaData.id}
                                ></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        <td>{ebookMetaData?.title ? ebookMetaData.title : '--'}</td>
                        <td>{ebookMetaData?.contentUrl ? ebookMetaData.contentUrl : '--'}</td>
                        <td>{ebookMetaData?.keywords ? ebookMetaData.keywords : '--'}</td>

                        <td>{ebookMetaData?.publishDate ? ebookMetaData.publishDate : '--'}</td>

                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink onClick={() => playListAssociationDelete(ebookMetaData.contentId)}>
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No E-book Data found</div>
              )}
            </>
          )}
          { match.params.type==='video' &&activeTab === 'pad' && entities && show && !isEntityLoading && (
            <>
              {entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Thumbnail
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      <th className="hand" onClick={sort('title')}>
                        Pad Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">Pad Url</th>
                      <th className="hand" onClick={sort('keywords')}>
                        Pad Keywords
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('publishDate')}>
                        Publish Date
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((padMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          {padMetaData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {padMetaData.thumbnailImageUrl ? (
                                <img src={padMetaData.thumbnailImageUrl} style={{ maxWidth: 70, maxHeight: 50 }} key={padMetaData.id}></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        <td>{padMetaData?.title ? padMetaData.title : '--'}</td>
                        <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Apple Url : {padMetaData?.contentUrlApple ? padMetaData.contentUrlApple : '--'}</p>
                            <p>Spotify Url : {padMetaData?.contentUrlSpotify ? padMetaData.contentUrlSpotify : '--'}</p>
                          </div>
                        </td>
                        <td>{padMetaData?.keywords ? padMetaData.keywords : '--'}</td>

                        <td>{padMetaData?.publishDate ? padMetaData.publishDate : '--'}</td>

                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink onClick={() => playListAssociationDelete(padMetaData.contentId)}>
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Pad Data found</div>
              )}
            </>
          )}
          {activeTab === 'patra' && entities && show && !isEntityLoading && (
            <>
              {entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Thumbnail
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      <th className="hand" onClick={sort('title')}>
                        Patra Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">Patra Url</th>
                      <th className="hand" onClick={sort('keywords')}>
                        Patra Keywords
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('publishDate')}>
                        Publish Date
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((patraMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          {patraMetaData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {patraMetaData.thumbnailImageUrl ? (
                                <img
                                  src={patraMetaData.thumbnailImageUrl}
                                  style={{ maxWidth: 70, maxHeight: 50 }}
                                  key={patraMetaData.id}
                                ></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        <td>{patraMetaData?.title ? patraMetaData.title : '--'}</td>
                        <td>{patraMetaData?.contentUrl ? patraMetaData.contentUrl : '--'}</td>
                        <td>{patraMetaData?.keywords ? patraMetaData.keywords : '--'}</td>

                        <td>{patraMetaData?.publishDate ? patraMetaData.publishDate : '--'}</td>

                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink onClick={() => playListAssociationDelete(patraMetaData.contentId)}>
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Pad Data found</div>
              )}
            </>
          )}
          {activeTab === 'transcript' && entities && show && !isEntityLoading && (
            <>
              {entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Thumbnail
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      <th className="hand" onClick={sort('title')}>
                        Transcript Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">Transcript Url</th>
                      <th className="hand" onClick={sort('keywords')}>
                        Transcript Keywords
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('satsangDate')}>
                        Satsang Date
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((transcriptMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          {transcriptMetaData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {transcriptMetaData.thumbnailImageUrl ? (
                                <img
                                  src={transcriptMetaData.thumbnailImageUrl}
                                  style={{ maxWidth: 70, maxHeight: 50 }}
                                  key={transcriptMetaData.id}
                                ></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        <td>{transcriptMetaData?.title ? transcriptMetaData.title : '--'}</td>
                        <td>{transcriptMetaData?.contentUrl ? transcriptMetaData.contentUrl : '--'}</td>
                        <td>{transcriptMetaData?.keywords ? transcriptMetaData.keywords : '--'}</td>

                        <td>{transcriptMetaData?.satsangDate ? transcriptMetaData.satsangDate : '--'}</td>

                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink onClick={() => playListAssociationDelete(transcriptMetaData.contentId)}>
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Transcript Data found</div>
              )}
            </>
          )}
        </div>{' '}
        {props.totalItems ? (
          <div className={`asso-table ${entities && entities.length > 0 && show && !isEntityLoading ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal isOpen={playListVideoDel} >
        <ModalHeader >Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseVidDelModel}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="jhi-confirm-delete-videoMetaData" disabled={updating} color="danger" onClick={confirmDeleteVideo}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={playListAssociationDel}>
        <ModalHeader >Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseAssocnDelModel}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="jhi-confirm-delete-videoMetaData" color="danger" disabled={updating} onClick={confirmDeleteAssociation}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ adminPlaylist, adminAssociationManagement }: IRootState) => ({
  entities: adminPlaylist.entities,
  loading: adminPlaylist.loading,
  totalItems: adminPlaylist.totalItems,
  template: adminPlaylist.template,
  video: adminPlaylist,
  loadingFile: adminPlaylist.loadingFile,
  updating: adminPlaylist.updating,
  isEntityLoading: adminPlaylist.isEntityLoading,
});

const mapDispatchToProps = {
  getPlayListVideo,
  searchVideoList,
  deleteVideo,
  reset,
  serialNumberUpdate,
  getAssociationLists,
  deleteAssociation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylistAssociations);
