import React, { useEffect, useState } from 'react';
import Camera from '../../../content/images/Camera.jpg';
import './admin-playlist.scss';

const FormUpload = (props) => {
    const [isError,setIsError]=useState(false)
    const [preview,setPreview]=useState(false)

    const {
        value,
        name,
        label,
        register,
        errorMsg,
        inlineLabel,
        isrequired,
        isPreview,
        labelTypography,
        uploadSingleFile
    } = props;

    useEffect(() => {
        setPreview(isPreview)
    }, [isPreview])
    useEffect(() => {
        setIsError(errorMsg)
    }, [errorMsg])
    const onFileChange = () => {

    }

    const _onEditImg = () => {

    }

    const _onDeleteImg = () => {

    }

    return (
        <div className="form-upload-container">
            <div className="field-container">
                <div className="input-field-container sub-image ">
                    {!isPreview && <input type="file" accept='.png, .jpg, .jpeg' name={name} {...register} onChange={uploadSingleFile} />}
                    {(!isPreview && value === null) && (
                        <div className={`${isError?`customized-fileupload-error`:`customized-fileupload`}`} style={{ 'width': 312 }} >
                            <span className="img-upload-icon"><img src={Camera} alt="" /></span>
                            {label}
                        </div>
                    )}

                    {(isPreview && value != null) && (
                        <div  >

                            <span ><img src={value} alt="" style={{  'height': 150 }} /></span>
                            {/* <span className="updateImg" onClick={_onEditImg}>Edit</span> */}
                            {/* <span className="deleteImg" onClick={_onDeleteImg}>Delete</span> */}
                        </div>
                    )}

                    {/* {(isPreview) && (
                        <div className="customized-fileupload" style={{ paddingTop: 15, paddingLeft: 20 }} >
                            <span><img src={value} alt="" style={{ width: 50, height: 30 }} /></span>
                        </div>
                    )} */}

                    {errorMsg && <p className="error">{errorMsg}</p>}
                </div>
            </div>
        </div>

    );
};

FormUpload.defaultProps = {
    icon: '',
    isrequired: false,
    inlineLabel: false
};

export default FormUpload;