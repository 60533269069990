import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import AdminAssociationsManagement from './admin-associations-management';
import AdminAssociationManagementUpdate from './admin-associations-management-update';
import  AdminAssociationAttachment  from './admin-association-attachment';
import  AdminAssociationManagementDeleteDialogue  from './admin-association-management-delete';
import  AdminAssociationManagementDetails  from './admin-association-management-details';

const Routes = ({ match }) => (
  <>
    <Switch>
      {/* <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AdminPlaylistUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AdminPlaylistUpdate} />     
      <ErrorBoundaryRoute exact path={`${match.url}/:id/:title/videoList/add`} component={AdminPlaylistVideoListUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/:title/playlistAssociations/videoList/add`} component={AdminPlaylistVideoListUpdate} />

      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AdminPlaylistDetails} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/:title/videoList`} component={AdminPlaylistVideoList} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/:title/playlistAssociations`} component={AdminPlaylistAssociations} />
       */}
       <ErrorBoundaryRoute exact path={`${match.url}/:pSearch/:pPage/:pSort/:pOrder/:association/:id/details`} component={AdminAssociationManagementDetails} />
      <ErrorBoundaryRoute exact path={`${match.url}/:association/:id/edit`} component={AdminAssociationManagementUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:association/new`} component={AdminAssociationManagementUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:pSearch/:pPage/:pSort/:pOrder/:association/:associationid/add`} component={AdminAssociationAttachment} />
      <ErrorBoundaryRoute path={match.url} component={AdminAssociationsManagement} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:association/:id/delete`} component={AdminAssociationManagementDeleteDialogue} />
  </>
);

export default Routes;
