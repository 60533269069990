import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './subscription-history.reducer';
import { ISubscriptionHistory } from 'app/shared/model/subscription-history.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISubscriptionHistoryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const SubscriptionHistory = (props: ISubscriptionHistoryProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const { subscriptionHistoryList, match, loading } = props;
  return (
    <div>
      <h2 id="subscription-history-heading">
        Subscription Histories
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Subscription History
        </Link>
      </h2>
      <div className="table-responsive">
        {subscriptionHistoryList && subscriptionHistoryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Transaction Id</th>
                <th>Transaction Date</th>
                <th>Subscription Plan</th>
                <th>User</th>
                <th>Statuses</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {subscriptionHistoryList.map((subscriptionHistory, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    {subscriptionHistory.startDate ? (
                      <TextFormat type="date" value={subscriptionHistory.startDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {subscriptionHistory.endDate ? (
                      <TextFormat type="date" value={subscriptionHistory.endDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{subscriptionHistory.transactionId}</td>
                  <td>
                    {subscriptionHistory.transactionDate ? (
                      <TextFormat type="date" value={subscriptionHistory.transactionDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {subscriptionHistory.subscriptionPlan ? (
                      <Link to={`subscription-plan/${subscriptionHistory.subscriptionPlan.id}`}>
                        {subscriptionHistory.subscriptionPlan.subscriptionName}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{subscriptionHistory.user ? subscriptionHistory.user.login : ''}</td>
                  <td>
                    {subscriptionHistory.statuses ? (
                      <Link to={`statuses/${subscriptionHistory.statuses.id}`}>{subscriptionHistory.statuses.statusValue}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${subscriptionHistory.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${subscriptionHistory.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${subscriptionHistory.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Subscription Histories found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ subscriptionHistory }: IRootState) => ({
  subscriptionHistoryList: subscriptionHistory.entities,
  loading: subscriptionHistory.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionHistory);
