import React, { useState, useEffect, useRef ,memo} from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ReactAudioPlayer from 'react-audio-player';
import { ReactReader } from 'react-reader';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ePub from 'epubjs';
import { useLocation } from 'react-router-dom';

interface Content {
  type: string;
  url?: string;
  file?: File;
  setDuration?: React.Dispatch<React.SetStateAction<number>>;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
}

interface DisplayComponentProps {
  content: Content;
}

function ViewDocument({ content }: DisplayComponentProps) {
  const [showContent, setShowContent] = useState(false);
  const [ePubBook, setEPubBook] = useState<ArrayBuffer | string | null>(null);
  const [pdfBook, setPdfBook] = useState<string>('');

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const renditionRef = useRef(null);
  const tocRef = useRef(null);

  const location =useLocation()
  async function getEPUBPageCount(selectedFile) {
    const book = ePub(selectedFile);

    try {
      await book.ready;

      const pageCount = book.navigation.landmarks.length;
      content.setPageCount(pageCount);
    } catch (error) {
      console.log('Error loading EPUB book:', error);
    }
  }
  const locationChanged = epubCfi => {
    console.log(epubCfi);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    content.file && content.setPageCount(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  const handleUnwantedClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (content && content.type === 'epub') {
      if (content.url) {
        setEPubBook(content.url);
      } else if (content.file) {
        const reader = new FileReader();
        getEPUBPageCount(content.file);
        reader.onload = async event => {
          const arrayBuffer = event.target?.result as ArrayBuffer;
          setEPubBook(arrayBuffer);
        };
        reader.readAsArrayBuffer(content.file);
      }
    } else if (content && content.type === 'pdf') {
      if (content.url) {
        setPdfBook(content.url);
      } else if (content.file) {
        const reader = new FileReader();
        reader.readAsDataURL(content.file);

        reader.onloadend = e => {
          setPdfBook(e.target.result as string);
        };
      }
    }
     
    const pathnameStringArray=location.pathname.split('/')
    const pathName = pathnameStringArray[pathnameStringArray.length-1]
    pathName=='edit'||pathName=='new'&&setShowContent(true);
  }, [content]);

  const handleViewClick = () => {
    setShowContent(prev => !prev);
  };
  const handleMp3Load = e => {
    if(content.url)return
    const minutes = Math.floor(e.target.duration / 60);
    content.setDuration(minutes);
  };
  const renderContent = () => {
    if (content.type === 'pdf') {
      return (
        <div style={{ width: '100%', height: '500px', overflowY: 'scroll',zIndex:999 }} onClick={handleUnwantedClick}>
          {pdfBook && (
            <>
              <div className="pdf-controls">
                <button type="button" onClick={goToPrevPage}>
                  Prev
                </button>
                <button type="button" onClick={goToNextPage}>
                  Next
                </button>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </div>
              <div style={{ maxWidth: '100%', overflow: 'auto' }}>
                <Document file={content.file ? URL.createObjectURL(content.file) : content.url} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    renderTextLayer={false}
                    renderInteractiveForms={false}
                    renderAnnotationLayer={false}
                    pageNumber={pageNumber}
                    width={600}
                  />
                </Document>
              </div>
            </>
          )}
        </div>
      );
    } else if (content.type === 'epub') {
      return (
        <div
          onClick={handleUnwantedClick}
          id="viewer"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '500px',
          }}
        >
          {ePubBook && (
            <div
              style={{
                width: '100%', // Adjust the width as needed
                height: '100%', // Adjust the height as needed
                overflow: 'hidden', // Hide any overflow
              }}
            >
              <ReactReader
                url={content.file ? ePubBook : content.url}
                // location={'epubcfi(/6/6[cover]!/6)'}

                locationChanged={locationChanged}
              />
            </div>
          )}
        </div>
      );
    } else if (content.type === 'mp3') {
      return (
        <ReactAudioPlayer src={content.file ? URL.createObjectURL(content.file) : content.url} controls onLoadedMetadata={handleMp3Load} />
      );
    } else {
      return <p>No content available.</p>;
    }
  };

  return (
    <div className="view-content">
      <div className="view-content-container">
        <div className="view-content-label">View</div>
        <button type="button" onClick={handleViewClick}>
          <FontAwesomeIcon
            icon={showContent ? faEyeSlash : faEye}
            style={{ height: '34px', width: '34px', color: '#3a9b72' }}
            data-toggle="tooltip"
            title={showContent ? 'Close' : 'View'}
          />
        </button>
      </div>

      {showContent && <div id="viewer">{content ? renderContent() : <p>No content available.</p>}</div>}
    </div>
  );
}

export default memo(ViewDocument);
