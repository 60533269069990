import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import 'draft-js/dist/Draft.css';
import JoditEditor from 'jodit-react';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Label, Row } from 'reactstrap';
import { getEntity, getLyrics, uploadEntity, uploadThumbnail, getTrackDetailsByTrackId, getSpeakerList, uploadArtist } from './admin-sparsh-reducer';
import { YOUTTUBE_AUDIO_URL } from 'app/config/constants';
import CreatableSelect from 'react-select/creatable';

export interface IAdminSparshLyricsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      trackId: any;
      id?: string;
      association: string;
      url: string;
      liveVideoId: string;
    }> {}

export const AdminSparshTrackEdit = (props: IAdminSparshLyricsProps) => {
  const { associationEntity, loading, updating, entity } = props;
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [lyrics, setLyrics] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState('');
  // const [albumDetails, setAlbumDetails] = useState(null);
  const [trackDetails, setTrackDetails] = useState(null);
  const [speakerList, setSpeakerList] = useState([]);
  const [speaker, setSpeaker] = useState(null);

  const handleGoBack = () => {
    props.history.goBack();
  };

  useEffect(() => {
    const trackDetails = async () => {
      const data = await props.getTrackDetailsByTrackId(props.match.params.trackId);
      setTrackDetails(data?.value?.data);
    };
    trackDetails();
  }, [props.match.params.trackId]);

  useEffect(() => {
    if (props.data?.data?.lyrics) {
      setLyrics(props.data.data.lyrics);
    }
  }, [props.data]);

  const handleFormSubmit = async (event , values) => {
    var title = null
    var publishedDate = null
    if(trackDetails?.audioAlbum?.isNonYoutube){
      title = values?.title,
      publishedDate = values?.publishedDate
    }else{
    if (!speaker) return;
    }

    let result = await props?.uploadArtist(props.match.params.trackId, speaker?.value ,title ,publishedDate);
    if (result) {
      handleGoBack();
    }
  };

  const convertToMinutes = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  };

  // useEffect(() => {
  //   const speakerFunction = () => {
  //     if (entity) {
  //       setSpeaker(speakerList.filter(speaker => speaker.label == associationEntity.speaker)[0]);
  //     } else setSpeaker(null);
  //   };
  //   speakerFunction();

  // }, [speakerList]);

  useLayoutEffect(() => {
    const getAvailableSpeakerList = async () => {
      const data = await getSpeakerList();

      const formattedSpeakers = data.map((speaker, index) => {
        return { value: speaker, label: speaker };
      });

      setSpeakerList(formattedSpeakers);
    };

    getAvailableSpeakerList();
  }, []);

  // const selectedOption = speakerList.find(option => option.label === trackDetails?.artist);

  const handleChangeSpeaker = (field, value) => {
    switch (field) {
      case 'options':
        setSpeaker(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const speakerFunction = () => {
      setSpeaker(speakerList.find(option => option.label == trackDetails?.artist));
    };
    speakerFunction();
  }, [trackDetails]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleGoBack}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Edit Track Details </h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm onValidSubmit={handleFormSubmit} model={isNew ? {} : associationEntity}>
              <div className="row">
                {/* Left Side */}
                
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="id" for="id" className="label-font">
                      Track No.
                    </Label>
                    <AvField id="id" type="text" name="id" className="input-font" value={props.match.params.trackId} disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="title" for="title" className="label-font">
                      Title
                    </Label>
                    <AvField
                      id="title"
                      type="text"
                      name="title"
                      className="input-font"
                      value={trackDetails?.title}
                      disabled={!trackDetails?.audioAlbum?.isNonYoutube}
                      validate={{
                        required: { value: true, errorMessage: 'Title is required' },
                        minLength: { value: 3, errorMessage: 'Title must be at least 3 characters' },
                      }}
                    />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="publishedDate" for="publishedDate" className="label-font">
                      Published Date
                    </Label>
                    <AvField
                      id="publishedDate"
                      type="date"
                      name="publishedDate"
                      className="input-font"
                      value={trackDetails?.publishedDate}
                      disabled={!trackDetails?.audioAlbum?.isNonYoutube}
                      validate={{
                        required: { value: true, errorMessage: 'Published Date is required.' },
                      }}
                      // max={new Date().toISOString().split('T')[0]}
                    />
                  </AvGroup>


                 
                 <AvGroup className="input-box-width">
                    <Label id="speaker" for="speaker" className="label-font">
                      Artist*
                    </Label>
                    {/* <AvField id="add2" type="text" name="add2" className="input-font" value={trackDetails?.playCount} disabled /> */}
                    <CreatableSelect
                      id="speaker"
                      name="speaker"
                      options={speakerList}
                      value={speaker}
                      className="input-font"
                      onChange={value => handleChangeSpeaker('options', value)}
                      // isMulti
                    />
                  </AvGroup>


                </div>

                {/* Right Side */}
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="add2" for="add2" className="label-font">
                      Duration
                    </Label>
                    <AvField
                      id="add2"
                      type="text"
                      name="add2"
                      className="input-font"
                      value={trackDetails?.duration ? convertToMinutes(trackDetails.duration) : ''}
                      disabled
                    />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="add2" for="add2" className="label-font">
                      Listen Count
                    </Label>
                    <AvField 
                      id="add2" 
                      type="text" 
                      name="add2" 
                      className="input-font" 
                      value={trackDetails?.playCount === 0 ? '--' : trackDetails?.playCount || '--'} 
                      disabled 
                    />
                  </AvGroup>

                 


                  <AvGroup className="input-box-width">
                    <Label id="subscriptionStatus" for="subscriptionStatus" className="label-font">
                      Subscription Status
                    </Label>
                    <AvField 
                      id="subscriptionStatus" 
                      type="text" 
                      name="subscriptionStatus" 
                      className="input-font" 

                      value={
                        trackDetails?.subscriptionPlan?.id === 24
                          ? 'Paid'
                          : trackDetails?.subscriptionPlan?.id === 1
                          ? 'Free'
                          : '--'
                      }
                                            
                      disabled 
                    />
                  </AvGroup>
                </div>
              </div>
              <button
                disabled={updating}
                id="save-entity"
                type="submit"
                className="common-btn"
                style={{
                  width: 200,
                  marginTop: 20,
                }}
              >
                <span className="header-title">Save</span>
              </button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  associationEntity: storeState.liveVideoList.entity,
  loading: storeState.liveVideoList.loading,
  updating: storeState.liveVideoList.updating,
  updateSuccess: storeState.liveVideoList.updateSuccess,
  data: storeState.sparshAlbumManagement.lyricsEntity,
  entity: storeState.sparshAlbumManagement.entity,
});

const mapDispatchToProps = {
  getStatuses,
  uploadEntity,
  uploadThumbnail,
  getLyrics,
  getEntity,
  getTrackDetailsByTrackId,
  uploadArtist,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshTrackEdit);
