import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { IUser } from 'app/shared/model/user.model';

export interface IMyFavourite {
  id?: number;
  videoMetaData?: IVideoMetaData;
  user?: IUser;
}

export const defaultValue: Readonly<IMyFavourite> = {};
