import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISubscriptionPlanSpecification, defaultValue } from 'app/shared/model/subscription-plan-specification.model';

export const ACTION_TYPES = {
  FETCH_SUBSCRIPTIONPLANSPECIFICATION_LIST: 'subscriptionPlanSpecification/FETCH_SUBSCRIPTIONPLANSPECIFICATION_LIST',
  FETCH_SUBSCRIPTIONPLANSPECIFICATION: 'subscriptionPlanSpecification/FETCH_SUBSCRIPTIONPLANSPECIFICATION',
  CREATE_SUBSCRIPTIONPLANSPECIFICATION: 'subscriptionPlanSpecification/CREATE_SUBSCRIPTIONPLANSPECIFICATION',
  UPDATE_SUBSCRIPTIONPLANSPECIFICATION: 'subscriptionPlanSpecification/UPDATE_SUBSCRIPTIONPLANSPECIFICATION',
  DELETE_SUBSCRIPTIONPLANSPECIFICATION: 'subscriptionPlanSpecification/DELETE_SUBSCRIPTIONPLANSPECIFICATION',
  RESET: 'subscriptionPlanSpecification/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISubscriptionPlanSpecification>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type SubscriptionPlanSpecificationState = Readonly<typeof initialState>;

// Reducer

export default (state: SubscriptionPlanSpecificationState = initialState, action): SubscriptionPlanSpecificationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUBSCRIPTIONPLANSPECIFICATION):
    case REQUEST(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLANSPECIFICATION):
    case REQUEST(ACTION_TYPES.DELETE_SUBSCRIPTIONPLANSPECIFICATION):
      return {
        ...state,
        errorMessage: null,
        // updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION):
    case FAILURE(ACTION_TYPES.CREATE_SUBSCRIPTIONPLANSPECIFICATION):
    case FAILURE(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLANSPECIFICATION):
    case FAILURE(ACTION_TYPES.DELETE_SUBSCRIPTIONPLANSPECIFICATION):
      return {
        ...state,
        loading: false,
        updating: false,
        // updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUBSCRIPTIONPLANSPECIFICATION):
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSCRIPTIONPLANSPECIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUBSCRIPTIONPLANSPECIFICATION):
      return {
        ...state,
        updating: false,
        // updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/subscription-plan-specifications';

// Actions

export const getEntities: ICrudGetAllAction<ISubscriptionPlanSpecification> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION_LIST,
  payload: axios.get<ISubscriptionPlanSpecification>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<ISubscriptionPlanSpecification> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLANSPECIFICATION,
    payload: axios.get<ISubscriptionPlanSpecification>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ISubscriptionPlanSpecification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SUBSCRIPTIONPLANSPECIFICATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISubscriptionPlanSpecification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSCRIPTIONPLANSPECIFICATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISubscriptionPlanSpecification> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUBSCRIPTIONPLANSPECIFICATION,
    payload: axios.delete(requestUrl),
    meta: {
      successMessage: '',
      errorMessage: '',
    },
  });
  // dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
